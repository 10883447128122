import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { DiversityRatioService } from "../api/DiversityRatioService";
import { IntegrationService } from "../api/IntegrationService";
import { DiversityRatioOverview } from "../components/DiversityRatioOverview/DiversityRatioOverview";
import { GetDiversityRatioResponse } from "../types/diversityRatio";
import { IntegrationResponse } from "../types/integration";
import {
  generateDiversityBreakdown,
  generateDiversityFilters,
  getDiversityRatioGroupColor,
} from "../utils/DiversityRatio.utils";

export const DiversityRatioPage = () => {
  const diversityRatioService = new DiversityRatioService();
  const integrationService = new IntegrationService();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const {
    data: diversityRatioData,
    isLoading,
    error,
  } = useQuery<GetDiversityRatioResponse, AxiosError>(
    ["diversityRatio"],
    () => diversityRatioService.getDiversityRatio(),
    { cacheTime: 60 * 60 * 1000 } // increase cache time to 60 minutes
  );

  const { error: integrationError } = useQuery<IntegrationResponse, AxiosError>(
    ["integration"],
    () => integrationService.getIntegration()
  );

  const diversityFilters =
    !error && diversityRatioData
      ? generateDiversityFilters(diversityRatioData)
      : undefined;

  const diversityBreakdown =
    !error && diversityRatioData
      ? generateDiversityBreakdown(diversityRatioData.filters[selectedTabIndex])
      : undefined;

  const diversityBreakdownGroupColors =
    !error && diversityRatioData
      ? getDiversityRatioGroupColor(
          diversityRatioData.filters[selectedTabIndex]
        )
      : undefined;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DiversityRatioOverview
          isLoading={isLoading}
          error={!!error}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          diversityFilters={diversityFilters}
          noIntegration={integrationError?.response?.status === 404}
          diversityBreakdown={diversityBreakdown}
          diversityBreakdownGroupColors={diversityBreakdownGroupColors}
        />
      </Grid>
    </Grid>
  );
};
