import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CardActionArea } from "@mui/material";
import classNames from "classnames";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Card } from "../../../components/ui/Card/Card";
import { IllustrationProps } from "../../../types/illustration";
import { Typography } from "../Typography/Typography";
import "./DashboardWidget.scss";

interface DashboardWidgetProps {
  icon: ({ className, iconOnly, size }: IllustrationProps) => JSX.Element;
  title: string;
  children: ReactNode;
  href?: string;
  preview?: boolean;
  className?: string;
}

export const DashboardWidget = ({
  icon,
  title,
  children,
  href,
  preview = false,
  className,
}: DashboardWidgetProps) => {
  const classes = classNames("DashboardWidget", className, {
    "DashboardWidget--preview": preview,
  });

  const clickable = !preview && href;

  return (
    <Card className={classes} containerClassName="DashboardWidget--container">
      <CardActionArea
        className="DashboardWidget__header"
        component={Link}
        to={href || "#"}
        disabled={!clickable}
        disableRipple
      >
        {icon({})}
        <Typography tagVariant="h2" desktop="h3" color="coal">
          {title}
        </Typography>
        <div className="DashboardWidget__header__icon">
          {clickable && <KeyboardArrowRightIcon color="secondary" />}
        </div>
      </CardActionArea>
      {children}
      {preview && (
        <div className="DashboardWidget__content__previewOverlay"></div>
      )}
    </Card>
  );
};
