import { Paper as MUIPaper } from "@mui/material";
import classNames from "classnames";
import { ReactNode } from "react";
import "./Paper.scss";

export interface IPaperProps {
  className?: string;
  children: ReactNode;
  showError?: boolean;
}

export const Paper = ({
  className,
  children,
  showError = false,
}: IPaperProps) => {
  const classes = classNames("Paper", className, {
    "Paper--error": showError,
  });
  return <MUIPaper className={classes}>{children}</MUIPaper>;
};
