import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Dispatch, FormEvent, SetStateAction } from "react";
import { StepContainer } from "./StepContainer";
import "./SignUpSteps.scss";

interface AcceptConditionStepType {
  agreeToTerms: boolean;
  handleSignUp: (e: FormEvent<HTMLFormElement>) => void;
  setAgreeToTerms: (value: SetStateAction<boolean>) => void;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
}

export const AcceptConditionStep = ({
  agreeToTerms,
  handleSignUp,
  setAgreeToTerms,
  setStep,
  step,
}: AcceptConditionStepType) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeToTerms(event.target.checked);
  };

  return (
    <StepContainer
      step={step}
      submitFunction={(e: FormEvent<HTMLFormElement>) => handleSignUp(e)}
      setStep={setStep}
    >
      <Typography variant="h1" sx={{ pb: 3 }}>
        Terms and conditions
      </Typography>
      <Typography
        variant="body1"
        sx={{ pb: 3 }}
        style={{ display: "inline-block", fontSize: "1rem" }}
      >
        Just one more step!{" "}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            value={true}
            checked={agreeToTerms}
            onChange={handleChange}
            required
          />
        }
        style={{ marginBottom: "32px" }}
        label={
          <p>
            I agree to the{" "}
            <a
              href="https://alda.co/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              Terms&Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://alda.co/data-processing"
              target="_blank"
              rel="noreferrer"
            >
              Data Processing
            </a>{" "}
            Agreement
          </p>
        }
      />
    </StepContainer>
  );
};
