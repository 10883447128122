import {
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import classnames from "classnames";
import { ReactElement, useState } from "react";
import { coalColor } from "../../../styles/theme";
import { ScaleValue, TextColor } from "../../../types/scale";
import { IconTypes } from "../icons";
import { Icon } from "../icons/Icon";

export interface ScaleTooltipProps<T> {
  title?: string;
  values: T[];
  children: ReactElement;
  isHoveringOverTick: boolean;
  hide?: boolean;
  small?: boolean;
  placement?: TooltipProps["placement"];
  onClick?: (value: T) => void;
}

// We don't have access to the MUI auto generated classes in the scss files
// and we cant overwrite them so we must style here
const MuiToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&:before": {
      border: `1px solid ${theme.palette.common.borderGrey}`,
      height: "6px",
      width: "9px",
      borderRadius: "3px 0",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.borderGrey,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: "20px",
    padding: 0,
    marginBottom: "1rem !important", // hack as tooltipTopPlacement is not working - bug in MUI
  },
}));

export const ScaleTooltip = <T extends ScaleValue>({
  title,
  values,
  isHoveringOverTick,
  hide = false,
  small,
  placement = "top",
  onClick,
  children,
}: ScaleTooltipProps<T>) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    if (!isHovering) setIsHovering(true);
  };

  const handleMouseLeave = () => {
    if (isHovering) setIsHovering(false);
  };
  const textColor =
    values[0].colorText === TextColor.Light ? "#fff" : coalColor;

  const tooltipData = () => (
    <div
      className="ScaleTooltipData"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClick && onClick(values[0])}
    >
      <Typography sx={{ fontWeight: 700 }} color={textColor}>
        {values[0].icon && (
          <Icon
            type={values[0].icon}
            color={textColor}
            size={14}
            className="ScaleTooltipData__icon"
          />
        )}
        {!!onClick && (
          <Icon
            type={IconTypes.Edit}
            color={textColor}
            size={14}
            className="ScaleTooltipData__edit"
          />
        )}
        {values[0].label}
      </Typography>
      <Typography variant="h6" sx={{ textAlign: "center" }} color={textColor}>
        {values[0].scoreLabel || values[0].score}
      </Typography>
    </div>
  );

  const tooltipDataWithMultipleLabels = () => {
    const classes = classnames("ScaleTooltipData", "ScaleTooltipData--group", {
      "ScaleTooltipData--small": small,
    });

    if (small) {
      return (
        <div
          className={classes}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div>
            <div className="ScaleTooltipData__content">
              {values.map((value, i) => (
                <Typography
                  key={value.label}
                  sx={{ fontWeight: 700 }}
                  color={textColor}
                >
                  {value.label}
                  {i !== values.length - 1 ? "," : ""}
                </Typography>
              ))}
            </div>

            <Typography color="secondary" variant="h6">
              {values[0].scoreLabel || values[0].score}
            </Typography>
          </div>
        </div>
      );
    }

    return (
      <div
        className={classes}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => onClick && onClick(values[0])}
      >
        <Typography className="ScaleTooltipData__title">{title}</Typography>
        {values.map((value) => (
          <div className="ScaleTooltipData__content" key={value.label}>
            <Typography sx={{ fontWeight: 700 }} color={textColor}>
              {value.label}
            </Typography>
            <Typography
              color="secondary"
              variant="h6"
              sx={{ marginLeft: "0.5rem" }}
            >
              {value.scoreLabel || value.score}
            </Typography>
          </div>
        ))}
      </div>
    );
  };

  const classes = classnames("ScaleTooltip", {
    "ScaleTooltip--front": isHoveringOverTick || isHovering,
    "ScaleTooltip--single": values.length === 1,
    "ScaleTooltip--hide": hide,
    "ScaleTooltip--editable": !!onClick,
  });

  return (
    <MuiToolTip
      open
      placement={placement}
      title={
        values.length > 1 ? tooltipDataWithMultipleLabels() : tooltipData()
      }
      className={classes}
      PopperProps={{
        modifiers: [
          {
            name: "flip",
            enabled: false,
          },
        ],
      }}
      color={values[0].color}
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          background: values[0].color,
          borderColor: values[0].color,
        },
        [`& .${tooltipClasses.tooltipArrow}`]: {
          backgroundColor: `1px solid ${values[0].color}`,
        },
        [`& .${tooltipClasses.arrow}`]: {
          "&:before": {
            border: `1px solid ${values[0].color}`,
            backgroundColor: values[0].color,
          },
        },
      }}
    >
      {children}
    </MuiToolTip>
  );
};
