import { Grid } from "@mui/material";
import classnames from "classnames";
import { ChangeEvent, useState } from "react";
import { useLanguages } from "../../../hooks/useLanguages";
import { LanguageDirection } from "../../../providers/LanguageProvider";
import { secondaryColor } from "../../../styles/theme";
import { IQuestionOption } from "../../../types/question";
import { Modal } from "../Modal/Modal";
import { Typography } from "../Typography/Typography";
import { IconTypes } from "../icons";
import { Icon } from "../icons/Icon";
import { Checkbox } from "./Checkbox";
import { Radio } from "./Radio";
import "./InputGroup.scss";

export interface IInputGroupProps {
  options: IQuestionOption[];
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  answerIds: string[] | undefined;
  startLabel?: string;
  endLabel?: string;
  showScale?: boolean;
  type?: "checkbox" | "radio";
}

export const InputGroup = ({
  options,
  onChange,
  answerIds,
  startLabel,
  endLabel,
  showScale = false,
  type,
}: IInputGroupProps) => {
  const [showClarification, setShowClarification] = useState<string>();
  const { getLanguageDirection } = useLanguages();
  const languageDirection = getLanguageDirection();

  const groupClasses = classnames("InputGroup__group", {
    "InputGroup__group--row": showScale,
  });

  const optionClasses = classnames("InputGroup__group__option", {
    "InputGroup__group__option--row": showScale,
  });

  const getLabel = (option: IQuestionOption) => {
    if (showScale) {
      return `${option.order + 1}`;
    }

    return option.text;
  };

  const modalClasses = classnames("InputGroup__clarification", {
    "InputGroup__clarification--rtl":
      languageDirection === LanguageDirection.RTL,
  });

  return (
    <div className="InputGroup">
      <div className={groupClasses}>
        {options.map((option) => {
          return (
            <Grid
              container
              columnSpacing={showScale ? 0 : 0.5}
              className={optionClasses}
              key={option.referenceId}
            >
              {!showScale && <Grid item xs={1} sm={0.5}></Grid>}
              <Grid item xs={showScale ? 12 : 10} sm={showScale ? 12 : 11}>
                {type === "checkbox" ? (
                  <Checkbox
                    key={option.referenceId}
                    label={getLabel(option)}
                    value={option.referenceId}
                    checked={answerIds?.includes(option.referenceId) || false}
                    onChange={onChange}
                  />
                ) : (
                  <Radio
                    key={option.referenceId}
                    label={getLabel(option)}
                    value={option.referenceId}
                    checked={answerIds?.includes(option.referenceId) || false}
                    onChange={onChange}
                  />
                )}
              </Grid>
              {!showScale && (
                <Grid
                  item
                  xs={1}
                  sm={0.5}
                  className="InputGroup__group__option__clarification"
                >
                  {!!option.clarification && (
                    <button
                      className="InputGroup__group__option__clarification__button"
                      onClick={() => setShowClarification(option.clarification)}
                    >
                      <Icon type={IconTypes.Info} />
                    </button>
                  )}
                </Grid>
              )}
            </Grid>
          );
        })}
      </div>
      <div className="InputGroup__labels">
        <Typography desktop="caption">{startLabel}</Typography>
        <Typography desktop="caption">{endLabel}</Typography>
      </div>
      {!!showClarification && (
        <Modal
          containerClassName={modalClasses}
          open={!!showClarification}
          onClose={() => setShowClarification(undefined)}
          light
        >
          <div>
            <Icon
              className="Question__icon"
              type={IconTypes.Info}
              size={30}
              color={secondaryColor}
            />
          </div>
          <Typography tagVariant="p" desktop="caption" weight="regular">
            {showClarification}
          </Typography>
        </Modal>
      )}
    </div>
  );
};
