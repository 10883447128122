import { coalColor } from "../../../styles/theme";
import { IconProps } from "./";

export const DownloadIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={(event) => onClick?.(event)}
      stroke={coalColor}
    >
      <path
        d="M16.5 9.26351C14.5312 11.2095 12.2812 15.75 12.2812 15.75M12.2812 15.75C12.2812 15.75 10.0313 11.2095 7.5 8.93919M12.2812 15.75C12.1875 12.3986 12 5.0473 11.4375 3.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.02346 15.0003C3.02346 15.0003 1.50022 21 4.5 20.9996C6.70793 20.9994 14.4012 20.9997 18.3089 20.9999C19.7442 21 21.0998 20.2157 21.326 18.7983C21.4898 17.7722 21.4987 16.4561 21.0235 15.0003"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
};
