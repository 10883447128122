import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PersonStandingIcon } from "../icons/PersonStanding";
import { PeopleChartValue } from "./PeopleChart";
import "./PeopleChart.scss";

interface PeopleChartLegendProps {
  groups?: PeopleChartValue[];
  className?: string;
  small?: boolean;
}
export const PeopleChartLegend = ({
  groups,
  className,
  small = false,
}: PeopleChartLegendProps) => {
  const groupsLength = groups?.length || 0;
  const isLargeScreen = useMediaQuery("(min-width:900px)");

  const legendRow = (width = 25, height = 30) => (
    <div className={`${className}__row`}>
      {groups?.map((group, i) => {
        return (
          <div className={`${className}__row__item`} key={group.label}>
            <PersonStandingIcon
              color={group.color}
              width={width}
              height={height}
            />
            <Typography>{group.label}</Typography>
          </div>
        );
      })}
    </div>
  );

  const legendColumn = () => (
    <div className={`${className}__column`}>
      <List sx={{ p: 0 }}>
        {groups?.map((group, i) => {
          return (
            <div className={`${className}__column__item`} key={group.label}>
              <ListItem sx={{ pt: 0.5, pb: 0.5 }}>
                <ListItemIcon>
                  <PersonStandingIcon
                    color={group.color}
                    width={25}
                    height={30}
                  />
                </ListItemIcon>
                <ListItemText primary={group.label} />
                <Typography color="secondary">{group.ratio}%</Typography>
              </ListItem>
              {i < groupsLength - 1 && <Divider light />}
            </div>
          );
        })}
      </List>
    </div>
  );
  return (
    <>
      {!small && (isLargeScreen ? legendColumn() : legendRow())}
      {small && legendRow(21, 24)}
    </>
  );
};
