import useTranslation from "../../hooks/useTranslation";
import { Typography } from "../ui/Typography/Typography";
import "./SurveySummary.scss";

export const SurveySummary = () => {
  const { t } = useTranslation("launchSurveyPage");

  return (
    <div className="SurveySummary">
      <Typography
        desktop="h2"
        mobile="h2"
        tagVariant="h2"
        weight="bold"
        className="SurveySummary__title"
      >
        {t("surveySummary.title")}
      </Typography>
      <Typography desktop="body1" mobile="body1" tagVariant="h2">
        {t("surveySummary.text")}
      </Typography>
    </div>
  );
};
