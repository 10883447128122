import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { IntegrationService } from "../../api/IntegrationService";
import useTranslation from "../../hooks/useTranslation";
import { AddH3IntegrationRequest } from "../../types/integration";
import { Button } from "../ui/Button/Button";
import { Input } from "../ui/Input/Input";
import { Modal } from "../ui/Modal/Modal";
import { Typography } from "../ui/Typography/Typography";
import { IntegrationSetupProps } from "./KjarniSetup";
import "./IntegrationSetup.scss";

export const H3Setup = ({
  title,
  open,
  onClose,
  refetchIntegration,
}: IntegrationSetupProps) => {
  const { t } = useTranslation("settingsPage");
  const [hostnameError, _validateHostname] = useState(false);
  const validateHostname = () => {
    try {
      const url = new URL(hostname);

      // the following checks are not complete for FQDN, but should be enough.
      // Did not bother checking TLDs... the backend will handle invalid TLDs.
      const [base, ending] = url.hostname.split(".");
      if (base.length > 0 && ending.length > 0) {
        _validateHostname(false);
      } else {
        _validateHostname(true);
      }
    } catch (e) {
      _validateHostname(true);
    }
  };
  const [hostname, setHostname] = useState("");
  const [domain, setDomain] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const integrationService = new IntegrationService();

  const addH3IntegrationMutation = useMutation({
    mutationFn: (data: AddH3IntegrationRequest) =>
      integrationService.addH3Integration(data),
  });

  const handleSubmit = async () => {
    const data = {
      company: domain,
      username: username,
      password: password,
      hostname: hostname,
    };
    await addH3IntegrationMutation.mutateAsync(data, {
      onSuccess: () => {
        setSubmitSuccess(true);
        setSubmitError(false);
        refetchIntegration();
      },
      onError: () => {
        setSubmitSuccess(false);
        setSubmitError(true);
      },
    });
  };
  return (
    <Modal
      className="IntegrationSetup"
      open={open}
      onClose={onClose}
      title={
        <Typography tagVariant="h3" desktop="h3">
          {submitSuccess ? t("integration.setup.h3.success.title") : title}
        </Typography>
      }
    >
      {!submitSuccess && (
        <form className="IntegrationSetup__form">
          <Input
            error={hostnameError}
            helperText={
              hostnameError
                ? t("integration.setup.h3.hostnameerror")
                : undefined
            }
            id="H3-hostname"
            value={hostname}
            label={t("integration.setup.h3.hostname")}
            InputProps={{ type: "url" }}
            onChange={(e) => setHostname(e.target.value)}
            onBlur={(_e) => validateHostname()}
            required
          />
          <Input
            type="text"
            id="H3-domain"
            value={domain}
            label={t("integration.setup.h3.domain")}
            onChange={(e) => setDomain(e.target.value)}
            required
          />
          <Input
            type="text"
            id="H3-username"
            value={username}
            label={t("integration.setup.username")}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <Input
            type={showPassword ? "text" : "password"}
            id="H3-password"
            data-testid="H3-password"
            value={password}
            label={t("integration.setup.password")}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {submitError && (
            <Typography tagVariant="p" desktop="caption">
              {t("integration.setup.error")}
            </Typography>
          )}
          <Button
            disabled={
              !(!hostnameError && hostname && domain && username && password)
            }
            color="info"
            variant="contained"
            onClick={handleSubmit}
          >
            {t("buttonAction.submit")}
          </Button>
        </form>
      )}
      {submitSuccess && (
        <div className="IntegrationSetup__form">
          <div>
            <Typography tagVariant="p" desktop="body2" weight="regular">
              {t("integration.setup.h3.success.info")}
            </Typography>
          </div>

          <Button onClick={onClose} variant="contained" color="success">
            {t("buttonAction.close")}
          </Button>
        </div>
      )}
    </Modal>
  );
};
