import { AxiosInstance } from "axios";
import { GetPayEquityResponse } from "../types/payEquity";
import { getApiClient } from "../utils/api.utils";

export class PayEquityService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  getPayEquity = async () => {
    const response = await this.client.get<GetPayEquityResponse>(
      "/pay-equity/"
    );
    return response.data;
  };
}
