import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import classnames from "classnames";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { darkStoneBlue } from "../../styles/theme";
import { todoLinkMappings } from "../../utils/ActionPlan.utils";
import { todoDataResponse } from "../ActionPlanTimeline/ActionPlan.data";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import { CheckIcon } from "../ui/icons/CheckIcon";
import "./ActionPlanTodo.scss";

export const ActionPlanTodo = () => {
  const { t } = useTranslation("dashboardPage");
  //TODO: get data from backend
  const [todoData, setTodoData] = useState(todoDataResponse);
  const [selectedOption, setSelectedOption] = useState<number>();

  const handleOpenInfo = (index: number) => {
    selectedOption === index
      ? setSelectedOption(undefined)
      : setSelectedOption(index);
  };

  const handleChange = (index: number) => {
    const newTodoData = [...todoData];
    newTodoData[index].isChecked = !newTodoData[index].isChecked;
    setTodoData(newTodoData);
  };

  return (
    <div className="ActionPlanTodo">
      <FormGroup className="ActionPlanTodo__list">
        {todoData.map((todo, i) => {
          const itemClasses = classnames("ActionPlanTodo__list__item", {
            "ActionPlanTodo__list__item--completed": todo.isChecked,
          });
          return (
            <div className={itemClasses} key={`todo-${i}`}>
              <FormControlLabel
                value={todo.label}
                control={
                  <Checkbox
                    icon={
                      <div className="ActionPlanTodo__list__item__icon"></div>
                    }
                    checkedIcon={
                      <div className="ActionPlanTodo__list__item__icon">
                        <CheckIcon color={darkStoneBlue} />
                      </div>
                    }
                  />
                }
                label={
                  <Typography desktop="body2" weight="bold">
                    {todo.label}
                  </Typography>
                }
                onChange={() => handleChange(i)}
              />
              {todo.description && (
                <Button
                  className="ActionPlanTodo__list__item__infoButton"
                  icon
                  small
                  onClick={() => handleOpenInfo(i)}
                  disableRipple
                >
                  {selectedOption === i ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </Button>
              )}
              {selectedOption === i && (
                <div className="ActionPlanTodo__list__item__description">
                  <Typography desktop="caption">{todo.description}</Typography>
                  {todo.action && (
                    <Button
                      className="ActionPlanTodo__list__item__description__action"
                      variant="contained"
                      color="info"
                      small
                      href={todoLinkMappings[todo.action] || ""}
                    >
                      {t("actionPlan.todo.actions.goTo")}
                    </Button>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </FormGroup>
    </div>
  );
};
