import { Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Input } from "../ui/Input/Input";
import { StepContainer } from "./StepContainer";

interface CompanyNameStepType {
  companyName: string;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
  setCompanyName: Dispatch<SetStateAction<string>>;
}

export const CompanyNameStep = ({
  companyName,
  setStep,
  step,
  setCompanyName,
}: CompanyNameStepType) => {
  return (
    <StepContainer step={step} submitFunction={() => setStep(step + 1)}>
      <div>
        <Typography variant="h1" sx={{ pb: 2 }}>
          Welcome <span className="SignUp__form__wave">👋</span>
        </Typography>
        <Typography variant="body1" sx={{ pb: 3 }}>
          Thank you for signing up! <br />
          We would love to know a little bit more about your company.
        </Typography>
        <Input
          className="SignUp__form__input"
          type="text"
          id="companyName"
          autoComplete="off"
          onChange={(e: { target: { value: SetStateAction<string> } }) =>
            setCompanyName(e.target.value)
          }
          value={companyName}
          required
          label="Company name"
          htmlFor="companyName"
        />
      </div>
    </StepContainer>
  );
};
