import useTranslation from "../../hooks/useTranslation";
import { RecruitmentIllustration } from "../../styles/illustrations/RecruitmentIllustration";
import { PipelineResponse } from "../../types/pipeline";
import { EmptyState } from "../EmptyState/EmptyState";
import { pipelineData as dummyPipelineData } from "../PipelineChart/PipelineChart.data";
import { PipelineOverview } from "../PipelineOverview/PipelineOverview";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";

interface PipelineWidgetProps {
  pipelineData?: PipelineResponse;
  pipelineDataLoading?: boolean;
  pipelineDataError?: boolean;
  noIntegration?: boolean;
  noJobLevel?: boolean;
  preview?: boolean;
}

export const PipelineWidget = ({
  pipelineData,
  pipelineDataLoading,
  pipelineDataError,
  noIntegration,
  noJobLevel,
  preview = false,
}: PipelineWidgetProps) => {
  const { t: tDashboard } = useTranslation("dashboardPage");
  const { t: tPipeline } = useTranslation("pipelinePage");

  return (
    <DashboardWidget
      title={tDashboard("pipelineWidget.title")}
      icon={RecruitmentIllustration}
      href="/pipeline"
      preview={preview}
    >
      {noIntegration && (
        <EmptyState
          title={tPipeline("noData.title")}
          href="/settings"
          actionInfo={tPipeline("noData.action")}
          isWidget
        />
      )}
      {noJobLevel && (
        <EmptyState
          title={tPipeline("setup.info")}
          href="/pipeline"
          actionInfo={tPipeline("actions.setup")}
          isWidget
        />
      )}
      {!noIntegration && !noJobLevel && (
        <PipelineOverview
          isWidget
          pipelineData={preview ? dummyPipelineData : pipelineData}
          isLoading={pipelineDataLoading}
          error={pipelineDataError && !preview}
        />
      )}
    </DashboardWidget>
  );
};
