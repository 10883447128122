import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { PipelineService } from "../../api/PipelineService";
import { JobTitlesResponse, LevelsResponse } from "../../types/pipeline";
import { PipelineJobLevelMapping } from "../PipelineJobLevelMapping/PipelineJobLevelMapping";
import { PipelineLevelSetup } from "../PipelineLevelSetup/PipelineLevelSetup";
import "./PipelineSetup.scss";

export interface PipelineSetupProps {
  onClose: () => void | undefined;
  setSetupSuccess: (val: boolean) => void;
}

export const PipelineSetup = ({
  onClose,
  setSetupSuccess,
}: PipelineSetupProps) => {
  const pipelineService = new PipelineService();
  const [levelSetupSuccess, setLevelSetupSuccess] = useState(false);
  const [showLevelSetup, setShowLevelSetup] = useState(false);

  const {
    data: jobTitleData,
    isLoading: jobTitleIsLoading,
    error: jobTitleError,
    refetch: jobTitleRefetch,
    isRefetching: jobTitleIsRefetching,
  } = useQuery<JobTitlesResponse, AxiosError>(["jobTitles"], () =>
    pipelineService.getJobTitles()
  );

  const {
    data: levelsData,
    isLoading: levelsIsLoading,
    refetch: levelsRefetch,
    isRefetching: levelsIsRefetching,
  } = useQuery<LevelsResponse, AxiosError>(["levels"], () =>
    pipelineService.getLevels()
  );

  useEffect(() => {
    jobTitleRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelSetupSuccess && !showLevelSetup]);

  useEffect(() => {
    levelsRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLevelSetup || !levelSetupSuccess]);

  return (
    <div className="PipelineSetup">
      {(showLevelSetup || !levelSetupSuccess) && (
        <PipelineLevelSetup
          setLevelSetupSuccess={setLevelSetupSuccess}
          setShowLevelSetup={setShowLevelSetup}
          levelsData={levelsData}
          isLoading={levelsIsLoading || levelsIsRefetching}
        />
      )}
      {levelSetupSuccess && !showLevelSetup && (
        <PipelineJobLevelMapping
          jobTitleData={jobTitleData}
          onClose={onClose}
          setSetupSuccess={setSetupSuccess}
          isLoading={jobTitleIsLoading || jobTitleIsRefetching}
          error={!!jobTitleError}
          onBack={() => setShowLevelSetup(true)}
        />
      )}
    </div>
  );
};
