import { AxiosInstance } from "axios";
import { ActionPlanResponse } from "../types/actionPlan";
import { TimelineValue } from "../utils/ActionPlan.utils";
import { getApiClient } from "../utils/api.utils";

export class ActionPlanService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  getActionPlan = async () => {
    const response = await this.client.get<ActionPlanResponse>("/action-plan/");
    return response.data;
  };

  updateActionPlan = async (data: TimelineValue[]) => {
    const response = await this.client.post<ActionPlanResponse>(
      "/action-plan/update/",
      data
    );
    return response.data;
  };
}
