import { AxiosInstance } from "axios";
import { GetDiversityRatioResponse } from "../types/diversityRatio";
import { getApiClient } from "../utils/api.utils";

export class DiversityRatioService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  getDiversityRatio = async () => {
    const response = await this.client.get<GetDiversityRatioResponse>(
      "/diversity-ratio/"
    );
    return response.data;
  };
}
