import MUICard, { CardProps as MUICardProps } from "@mui/material/Card";
import classNames from "classnames";
import "./Card.scss";

interface CardProps extends MUICardProps {
  clickable?: boolean;
  containerClassName?: string;
  disabled?: boolean;
  inactive?: boolean;
}
export const Card = ({
  className,
  clickable = false,
  containerClassName,
  disabled = false,
  inactive = false,
  onClick,
  square,
  variant,
  ...props
}: CardProps) => {
  const classes = classNames("Card", className, {
    "Card--clickable": !!onClick || clickable,
    "Card--disabled": disabled,
    "Card--inactive": inactive,
    "Card--outlined": variant === "outlined",
    "Card--square": square,
  });

  return (
    <div
      className={containerClassName}
      onClick={disabled ? undefined : (event) => onClick?.(event)}
    >
      <MUICard className={classes} {...props}>
        {props.children}
      </MUICard>
    </div>
  );
};
