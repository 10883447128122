import {
  Snackbar as MUISnackbar,
  Slide,
  SlideProps,
  SnackbarProps,
} from "@mui/material";
import "./Snackbar.scss";

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up" />;
};

export const Snackbar = ({
  open,
  onClose,
  message,
  autoHideDuration = 10000,
  ...props
}: SnackbarProps) => {
  return (
    <MUISnackbar
      className="Snackbar"
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={onClose}
      message={message}
      TransitionComponent={SlideTransition}
      autoHideDuration={autoHideDuration}
      {...props}
    />
  );
};
