import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link, Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import classnames from "classnames";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import { DiversityIllustration } from "../../styles/illustrations/DiversityIllustration";
import { HomeIllustration } from "../../styles/illustrations/HomeIllustration";
import { PayEquityIllustration } from "../../styles/illustrations/PayEquityIllustration";
import { RecruitmentIllustration } from "../../styles/illustrations/RecruitmentIllustration";
import { WorkplaceCultureIllustration } from "../../styles/illustrations/WorkplaceCultureIllustration";
import "./Breadcrumbs.scss";

export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter((x) => x !== "dashboard" && x);
  const iconSize = 32;
  const breadcrumbIcons = new Map<string, ReactNode>([
    ["home", <HomeIllustration size={iconSize} />],
    [
      "workplace-culture",
      <WorkplaceCultureIllustration iconOnly size={iconSize} />,
    ],
    ["diversity-ratio", <DiversityIllustration iconOnly size={iconSize} />],
    ["pay-equity", <PayEquityIllustration iconOnly size={iconSize} />],
    ["pipeline", <RecruitmentIllustration iconOnly size={iconSize} />],
  ]);

  const classes = classnames("Breadcrumbs", {
    "Breadcrumbs--hide": pathnames.length === 0,
  });
  return (
    <MUIBreadcrumbs className={classes} separator={<KeyboardArrowRightIcon />}>
      {pathnames.length && (
        <Link className="Breadcrumbs__item" href="/" color="inherit">
          {breadcrumbIcons.get("home")}
          <Typography className="Breadcrumbs__item__title" variant="h5">
            {t("breadcrumbs.home")}
          </Typography>
        </Link>
      )}

      {pathnames.map((name, i) => {
        const routeTo = `/${pathnames.slice(0, i + 1).join("/")}`;
        const isLast = i === pathnames.length - 1;
        return (
          <Link
            key={name}
            className="Breadcrumbs__item"
            href={routeTo}
            color="inherit"
            style={isLast ? { textDecoration: "underline" } : undefined}
          >
            {breadcrumbIcons.get(name)}
            <Typography className="Breadcrumbs__item__title" variant="h5">
              {t(`breadcrumbs.${name}`)}
            </Typography>
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};
