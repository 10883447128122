import { differenceInDays, isAfter } from "date-fns";
import { AvailableLanguages } from "../types/locale";
import {
  GetSurveyDispatchResponse,
  SurveyDispatchReminder,
  SurveyDispatchSettings,
  SurveyDispatchStatus,
} from "../types/survey";
import { getFormattedDate } from "../utils/formatters";

class SurveyDispatch {
  surveyId: string;
  id: string;
  settings: SurveyDispatchSettings | undefined;
  dueDate: Date | undefined;
  status: SurveyDispatchStatus;
  engagement: number;
  launchDate: Date | undefined;
  reminders: SurveyDispatchReminder[];
  recipients: string[];
  isJobRolesMapped: boolean;
  isPublic: boolean;

  constructor(parameters: GetSurveyDispatchResponse) {
    this.id = parameters.id;
    this.dueDate = parameters.due_date
      ? new Date(parameters.due_date)
      : undefined;
    this.engagement = parameters.participants || 0;
    this.launchDate = parameters.launch_date
      ? new Date(parameters.launch_date)
      : undefined;
    this.surveyId = parameters.survey_id;
    this.reminders = parameters.reminders?.map((reminder) => ({
      ...reminder,
      date: new Date(reminder.date),
    }));
    this.recipients = parameters.recipients;
    this.isJobRolesMapped = parameters.is_mapped;
    this.settings = parameters.settings;
    this.isPublic = parameters.is_public;
    // TODO: support "Past" survey status (not in MVP)
    if (this.isInProgress()) {
      this.status = SurveyDispatchStatus.InProgress;
    } else if (this.isReady()) {
      this.status = SurveyDispatchStatus.Ready;
    } else if (this.isActive()) {
      this.status = SurveyDispatchStatus.Active;
    } else {
      this.status = SurveyDispatchStatus.Latest;
    }
  }

  getStatusLabel = (hasError: boolean) => {
    if (hasError || !this.status) return "error";

    return this.status;
  };

  getStatusDateFormatted = (t: (str: string) => string, language: string) => {
    if (this.isReady() && this.launchDate) {
      return getFormattedDate(this.launchDate, t, language);
    } else {
      return this.dueDate && getFormattedDate(this.dueDate, t, language);
    }
  };

  getProgress = (): number => {
    if (this.launchDate && this.dueDate) {
      if (!this.isActive()) {
        return 100;
      }

      const daysBetweenLaunchAndDueDate = differenceInDays(
        this.dueDate,
        this.launchDate
      );

      const daysSinceLaunched = differenceInDays(
        new Date(Date.now()),
        this.launchDate
      );

      const percentageDone =
        (daysSinceLaunched / daysBetweenLaunchAndDueDate) * 100;

      return Math.round(percentageDone);
    }

    return 0;
  };

  getLanguage = (): AvailableLanguages => {
    if (!this.settings || !this.settings.email_language) {
      return AvailableLanguages.ENGLISH_GB;
    }

    return this.settings.email_language;
  };

  getReminder = (id: number | null) => {
    return this.reminders?.find((reminder) => reminder.id === id);
  };

  isReminderDisabled = (reminderId: number) => {
    const dispatchReminder = this.getReminder(reminderId);
    if (dispatchReminder?.date) {
      return isAfter(Date.now(), dispatchReminder.date);
    }
    return false;
  };

  // Survey has been launched and not closed
  isActive = () => {
    if (this.isInProgress()) {
      return false;
    } else if (this.launchDate && this.dueDate) {
      return (
        isAfter(this.dueDate, new Date(Date.now())) &&
        isAfter(new Date(Date.now()), this.launchDate)
      );
    }
    return false;
  };

  // Survey does not have launch date or due date or recipients
  isInProgress = () => {
    if (this.dueDate && isAfter(new Date(Date.now()), this.dueDate)) {
      return false;
    }

    return (
      (!this.launchDate && !this.dueDate) ||
      (this.recipients?.length === 0 && !this.isPublic) ||
      !this.isJobRolesMapped
    );
  };

  // Survey has not been launched but has a dispatch
  isReady = () => {
    if (this.isInProgress()) {
      return false;
    } else if (this.launchDate) {
      return isAfter(this.launchDate, new Date(Date.now()));
    }
    return false;
  };

  setLaunchDate = (launchDate: Date) => {
    this.launchDate = launchDate;
  };

  setDueDate = (dueDate: Date) => {
    this.dueDate = dueDate;
  };

  setReminders = (reminders: SurveyDispatchReminder[]) => {
    this.reminders = reminders;
  };

  setLanguage = (language: AvailableLanguages) => {
    this.settings = { email_language: language };
  };

  setRecipients = (recipients: string[]) => {
    this.recipients = recipients;
  };

  setIsJobRolesMapped = (isJobRolesMapped: boolean) => {
    this.isJobRolesMapped = isJobRolesMapped;
  };
}

export default SurveyDispatch;
