import { Skeleton } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import { grey2 } from "../../styles/theme";
import { IBenchmarking } from "../../types/workplaceCulture";
import getColorByScore from "../../utils/graphs";
import { NoDataState } from "../NoDataState/NoDataState";
import { Typography } from "../ui/Typography/Typography";
import "./Benchmarking.scss";

interface BenchmarkingProps {
  benchmarking?: IBenchmarking;
  benchmarkingError?: boolean;
  benchmarkingLoading?: boolean;
  inclusionScore?: number;
  scoreError?: boolean;
  scoreLoading?: boolean;
}

export const Benchmarking = ({
  benchmarking,
  benchmarkingError = false,
  benchmarkingLoading = true,
  inclusionScore,
  scoreError,
  scoreLoading = true,
}: BenchmarkingProps) => {
  const { t, i18n } = useTranslation("dashboardPage");
  const { authInfo } = useAuth();

  const handleBenchmarkingScore = (score: number) => {
    if (i18n.language === "is-IS") {
      const icelandicScore = score.toString().replace(".", ",");
      return icelandicScore;
    } else {
      return score;
    }
  };

  const renderScoreBubble = (
    color: string,
    showInfo: boolean,
    score?: number
  ) => (
    <div className="Benchmarking__scoreBubble" style={{ background: color }}>
      <div>
        {score && (
          <Typography
            desktop="display2"
            className="Benchmarking__scoreBubble__score"
            color={score >= 2 && score < 3 ? "primary" : "light"}
          >
            {handleBenchmarkingScore(score)}
          </Typography>
        )}
        {showInfo && !score && (
          <Typography
            desktop="label3"
            className="Benchmarking__scoreBubble__info"
            color={!score || (score >= 2 && score < 3) ? "primary" : "light"}
          >
            {t("benchmarking.comingSoon")}
          </Typography>
        )}
      </div>
    </div>
  );

  return (
    <div className="Benchmarking">
      <div className="Benchmarking__scoreSection">
        <Typography desktop="h2" className="Benchmarking__title">
          {t("benchmarking.scoreTitle", {
            companyName: authInfo?.user?.companyName,
          })}
        </Typography>
        {inclusionScore &&
          renderScoreBubble(
            getColorByScore(inclusionScore),
            false,
            inclusionScore
          )}
        {scoreLoading && <Skeleton height={50} width={"100%"} />}
        {scoreError && (
          <NoDataState info={t("benchmarking.scoreError")} light />
        )}
        {!inclusionScore && !scoreError && !scoreLoading && (
          <Typography
            tagVariant="p"
            desktop="body2"
            className="NoDataState__text__info"
            color={"secondary"}
          >
            {t("benchmarking.noInclusionScore")}
          </Typography>
        )}
      </div>
      <div className="Benchmarking__scoreSection">
        <Typography desktop="h2" className="Benchmarking__title">
          {t("benchmarking.indexTitle")}
        </Typography>
        {!benchmarkingError &&
          !benchmarkingLoading &&
          renderScoreBubble(
            benchmarking?.score ? getColorByScore(benchmarking.score) : grey2,
            true,
            benchmarking?.score
          )}
        {benchmarkingLoading && <Skeleton height={50} width={"100%"} />}
        {benchmarkingError && (
          <NoDataState info={t("benchmarking.benchmarkingError")} light />
        )}
      </div>
    </div>
  );
};
