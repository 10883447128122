export const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  return /^[\w.+]+@[a-zA-Z_]+?\.[a-zA-Z]{2,}$/.test(email);
};

export const isInList = (item: string, list: string[]) => {
  return list.includes(item);
};

export const isDate = (date: string) => {
  // eslint-disable-next-line no-useless-escape
  return /(31[\/.](0[13578]|1[02])[\/.](18|19|20)[0-9]{2})|((29|30)[\/.](01|0[3-9]|1[1-2])[\/.](18|19|20)[0-9]{2})|((0[1-9]|1[0-9]|2[0-8])[\/.](0[1-9]|1[0-2])[\/.](18|19|20)[0-9]{2})|(29[\/.](02)[\/.](((18|19|20)(04|08|[2468][048]|[13579][26]))|2000))/.test(
    date
  );
};

export const isValidEmail = (email: string, list: string[]) => {
  if (isInList(email, list)) {
    return false;
  }

  if (!isEmail(email)) {
    return false;
  }

  return true;
};

export const validateEmails = (emailList: string[], newEmails: string[]) => {
  const duplicates: string[] = [];
  const toBeAdded: string[] = [];

  newEmails.forEach((email: string) => {
    if (
      isInList(email, emailList) ||
      toBeAdded.includes(email) ||
      duplicates.includes(email)
    ) {
      duplicates.push(email);
    } else if (isValidEmail(email, emailList)) {
      toBeAdded.push(email);
    }
  });
  return { duplicates, toBeAdded };
};
