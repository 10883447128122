import MUITab from "@mui/material/Tab";
import classNames from "classnames";
import "./Tab.scss";

export interface ITabProps {
  index: number;
  label: string;
  onClick: (index: number) => void;
  className?: string;
  selected?: boolean;
  small?: boolean;
  info?: string;
  withIndicator?: boolean;
  icon?: JSX.Element;
}

export const Tab = ({
  index,
  label,
  onClick,
  className,
  selected = false,
  small = false,
  withIndicator,
  info,
  icon,
}: ITabProps) => {
  const classes = classNames("Tab", className, {
    "Tab--selected": selected,
    "Tab--small": small,
    "Tab--withIndicator": withIndicator,
  });

  return (
    <>
      <MUITab
        label={
          <div className="Tab__label">
            <span>{label}</span>
            {info && <span className="Tab__label__info">{info}</span>}
          </div>
        }
        disableRipple
        className={classes}
        id={`tab-${index}`}
        aria-controls={`tabpanel-${index}`}
        onClick={() => onClick(index)}
        value={index}
        icon={icon}
      />
    </>
  );
};
