import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useTranslation from "../hooks/useTranslation";
import { PublicRoutes } from "../utils/routes.utils";
import { LoadingScreen } from "./ui/LoadingScreen";

export const Logout = () => {
  const { t } = useTranslation();
  const { onLogout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    onLogout().finally(() => {
      navigate(PublicRoutes.LOGIN);
    });
  }, [onLogout, navigate]);

  return <LoadingScreen active text={t("loggingOutMsg")} />;
};
