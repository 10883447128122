import { FormControl, Grid, MenuItem } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { AvailableLanguages } from "../../types/locale";
import { Select } from "../ui/Select/Select";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyEmailPreview.scss";

interface SurveyEmailPreviewProps {
  survey: SurveyDispatch;
  onSurveyUpdate: () => void;
}

export const SurveyEmailPreview = ({
  survey,
  onSurveyUpdate,
}: SurveyEmailPreviewProps) => {
  const { t } = useTranslation("launchSurveyPage");
  const surveyService = new SurveyService();

  const surveyInviteLanguageMutation = useMutation({
    mutationFn: (lang: AvailableLanguages) =>
      surveyService.surveyInviteLanguage(lang),
  });

  const handleChange = (lang: AvailableLanguages) => {
    surveyInviteLanguageMutation.mutate(lang, {
      onSuccess: () => {
        survey.setLanguage(lang);
        onSurveyUpdate();
      },
    });
  };
  return (
    <>
      <Typography
        desktop="body1"
        mobile="body1"
        tagVariant="h2"
        className="SurveyEmailPreview__subtitle"
        weight="bold"
      >
        {t("previewEmail.title")}
      </Typography>
      <FormControl size="small">
        <Select
          labelId="email_language_picker_label"
          id="email_language_picker"
          value={survey.getLanguage()}
          required
          onChange={(e) => {
            handleChange(e.target.value as AvailableLanguages);
          }}
          className="SurveyEmailPreview__dropdown"
        >
          <MenuItem value={AvailableLanguages.ENGLISH_GB}>
            {t("previewEmail.englishVersion")}
          </MenuItem>
          <MenuItem value={AvailableLanguages.ICELANDIC}>
            {t("previewEmail.icelandicVersion")}
          </MenuItem>
        </Select>
      </FormControl>
      <Typography
        desktop="body1"
        mobile="body1"
        tagVariant="h2"
        className="SurveyEmailPreview__info"
      >
        {t("previewEmail.info")}
      </Typography>
      <Grid container className="SurveyEmailPreview">
        <Grid item md={12}>
          <div
            className="SurveyEmailPreview__email"
            id={
              survey.getLanguage() === AvailableLanguages.ENGLISH_GB
                ? "selected"
                : ""
            }
          >
            <p>Hi 👋</p>
            <p>
              In this email you will find a link to The Inclusion Pulse from
              Alda. By answering the survey you will help your organisation
              create a workplace where everyone thrives and feels valued!
            </p>
            <a
              href="https://alda.co/inclusion-survey-info"
              title="See more on Inclusion Pulse anonymity"
              target="_blank"
              rel="noreferrer"
            >
              The survey is 100% anonymous and your answers cannot be traced
              back to you.
            </a>
            <p>
              🔗 The Inclusion Pulse:
              <span> Click here</span>
            </p>
            <p>⏱️ Time to Complete: 5 minutes</p>
            <p>
              Thank you for your participation! Your response is invaluable 👏
            </p>

            <p>
              If you have any questions or thoughts, please contact your HR
              department.
            </p>

            <p>Best regards,</p>
            <p>Alda</p>
          </div>
        </Grid>
        <Grid item md={12}>
          <div
            className="SurveyEmailPreview__email"
            id={
              survey.getLanguage() === AvailableLanguages.ICELANDIC
                ? "selected"
                : ""
            }
          >
            <p>Góðan dag 👋</p>

            <p>
              Meðfylgjandi er hlekkur á Inngildingarpúls frá Öldu. Könnunin
              mælir upplifun þína af vinnustaðamenningu fyrirtækisins og hversu
              inngildandi hún er.
            </p>

            <a
              href="https://alda.co/inclusion-survey-info"
              title="Sjá nánar um nafnleysi í Inngildingarpúls"
              target="_blank"
              rel="noreferrer"
            >
              Könnunin er 100% nafnlaus og ópersónugreinanleg.
            </a>

            <p>
              🔗 Inngildingarpúls:
              <span> Smelltu hér</span>
            </p>
            <p>⏱️ Tími: 5 mínútur</p>

            <p>Takk fyrir að svara! Þátttaka þín skiptir miklu máli 👏</p>

            <p>
              Hafðu samband við mannauðsdeild þíns fyrirtækis ef einhverjar
              spurningar vakna.
            </p>

            <p>Bestu kveðjur,</p>
            <p>Alda</p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
