import { Skeleton } from "@mui/material";
import classnames from "classnames";
import useTranslation from "../../hooks/useTranslation";
import { PipelineResponse } from "../../types/pipeline";
import { EmptyState } from "../EmptyState/EmptyState";
import { NoDataState } from "../NoDataState/NoDataState";
import { PipelineChart } from "../PipelineChart/PipelineChart";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import "./PipelineOverview.scss";

export interface PipelineOverviewProps {
  pipelineData?: PipelineResponse;
  isLoading?: boolean;
  error?: boolean;
  isWidget?: boolean;
  noIntegration?: boolean;
}

export const PipelineOverview = ({
  pipelineData,
  isLoading,
  error,
  isWidget,
  noIntegration,
}: PipelineOverviewProps) => {
  const { t } = useTranslation("pipelinePage");
  const renderLoading = () => (
    <div className="PipelineOverview__loading">
      {!isWidget && (
        <div className="PipelineOverview__loading__recommendations">
          {[...Array(3)].map((e, i) => {
            return (
              <Card variant="outlined" key={`loading-rec-${i}`}>
                <Skeleton width={"4rem"} />
                <Skeleton width={"10rem"} />
                <Skeleton />
              </Card>
            );
          })}
        </div>
      )}
      <Card
        variant={isWidget ? undefined : "outlined"}
        className="PipelineOverview__loading__chart"
      >
        {[...Array(5)].map((e, i) => {
          return (
            <div
              className="PipelineOverview__loading__chart__bar"
              key={`loading-bar-${i}`}
            >
              <Skeleton variant="text" height={"2rem"} />
              <Skeleton variant="rounded" height={"16rem"} />
            </div>
          );
        })}
      </Card>
    </div>
  );

  const renderError = () => (
    <NoDataState title={t("error.title")} info={t("error.info")} />
  );

  const renderNoData = () => (
    <EmptyState
      title={t("noData.title")}
      info={t("noData.info")}
      href="/settings"
      actionInfo={t("noData.action")}
    />
  );

  const classes = classnames("PipelineOverview", {
    "PipelineOverview--widget": isWidget,
  });
  return (
    <Card className={classes} containerClassName="PipelineOverview--container">
      {!isWidget && !noIntegration && (
        <Typography
          tagVariant="h1"
          desktop="display2"
          className="PipelineOverview__title"
        >
          {t("title")}
        </Typography>
      )}
      {noIntegration && renderNoData()}
      {isLoading && !noIntegration && renderLoading()}
      {!isLoading && !error && (
        <PipelineChart
          pipelineFilters={pipelineData?.filters}
          small={isWidget}
        />
      )}
      {error && !noIntegration && renderError()}
    </Card>
  );
};
