import classNames from "classnames";
import allIcons, { IconProps, IconTypes } from "./";
import "./Icon.scss";

export const Icon = ({
  type,
  onClick,
  className,
  color,
  ...props
}: IconProps & { type: IconTypes }) => {
  const IconComponent = allIcons[type];

  if (!IconComponent) {
    console.warn("Trying to use an icon that does not exist");
    return <div></div>;
  }

  const classes = classNames("Icon", className, {
    "Icon--clickable": !!onClick,
    "Icon--secondary": color === "secondary",
    "Icon--info": color === "info",
    "Icon--white": color === "white",
  });

  return (
    <IconComponent
      className={classes}
      onClick={onClick}
      color={color}
      {...props}
    />
  );
};
