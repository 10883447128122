import classnames from "classnames";
import { coalColor } from "../../../styles/theme";
import { Direction, IconProps } from "./index";
import "./ArrowIcon.scss";

export const ArrowIcon = ({ direction, className }: IconProps) => {
  // Default direction is left
  const classes = classnames("ArrowIcon", className, {
    "ArrowIcon--up": direction === Direction.UP,
    "ArrowIcon--right": direction === Direction.RIGHT,
    "ArrowIcon--down": direction === Direction.DOWN,
  });

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      stroke={coalColor}
    >
      <path
        d="M11.25 6.75C7.125 8.625 4.5 13.125 4.5 13.125M4.5 13.125C4.5 13.125 6.75001 16.125 11.625 18M4.5 13.125C13.5001 12 14.625 12 19.125 12.375"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
