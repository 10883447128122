import { SurveyDemographicGroup } from "../../types/workplaceCulture";
import { graphColors } from "../../utils/graphs";

export const getDemographicChartData = (
  demographicLabel: string,
  demographicData: SurveyDemographicGroup[]
): { options: Highcharts.Options; engagement: number } => {
  let totalEngagements = 0;
  // remove groups with no engagement
  demographicData = demographicData.filter((group) => group.engagement !== 0);

  const series: Highcharts.SeriesOptionsType[] = demographicData.map(
    (group, i) => {
      totalEngagements += group.engagement || 0;
      return {
        type: "bar",
        name: group.label,
        data: [group.engagement || 0],
        color: graphColors[i],
      };
    }
  );

  const options: Highcharts.Options = {
    title: {
      text: demographicLabel,
      align: "left",
      style: { fontSize: "14px", fontWeight: "bold" },
      margin: 0,
    },
    chart: {
      type: "bar",
      height: 140,
    },
    xAxis: {
      categories: [""],
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      visible: false,
      reversedStacks: false,
      height: "20px",
    },
    legend: {
      reversed: false,
      align: "right",
      margin: 16,
      layout: "vertical",
      width: "20%",
      verticalAlign: "middle",
    },
    plotOptions: {
      series: {
        stacking: "percent",
      },
      bar: {
        pointWidth: 20,
        dataLabels: {
          enabled: true,
          align: "right",
          format: "{point.percentage:.0f}%",
          style: {
            textOutline: "0px",
            fontWeight: "400",
          },
        },
      },
    },
    series: series,
  };

  return { options, engagement: totalEngagements };
};
