import { AxiosInstance } from "axios";
import { TrainingModuleResponse } from "../types/training";
import { getApiClient } from "../utils/api.utils";

export class TrainingService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  getTrainingModules = async () => {
    const response = await this.client.get<TrainingModuleResponse[]>(
      "/training/training-modules/"
    );
    return response.data;
  };

  private async downloadFile(
    url: string,
    defaultFilename: string
  ): Promise<Blob> {
    const response = await this.client.get(url, { responseType: "blob" });

    const contentDisposition = response.headers["content-disposition"];
    let filename = defaultFilename;

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch && fileNameMatch.length === 2) {
        filename = fileNameMatch[1];
      }
    }

    const href = window.URL.createObjectURL(new Blob([response.data]));
    const anchorElement: HTMLAnchorElement = document.createElement("a");

    anchorElement.href = href;
    anchorElement.download = filename;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);

    return response.data;
  }

  downloadTouchpoint = async (touchpointId: string): Promise<Blob> => {
    return this.downloadFile(
      `/training/training-modules/${touchpointId}/download/`,
      `${touchpointId}`
    );
  };

  downloadTrainingModule = async (trainingModuleId: string): Promise<Blob> => {
    return this.downloadFile(
      `/training/training-modules/all-touchpoints/${trainingModuleId}/download/`,
      `${trainingModuleId}`
    );
  };
}
