import { GetSurveyResponse, SurveyAnswer } from "../types/survey";
import MultiSelectQuestion from "./multiSelectQuestion";
import ScaleQuestion from "./scaleQuestion";
import SingleSelectQuestion from "./singleSelectQuestion";
import SurveyChapter from "./surveyChapter";

class Survey {
  referenceId: string;
  chapters: SurveyChapter[];
  // Stores the flow of the survey in the correct order, what should be shown next/previously
  segmentOrder: (
    | SurveyChapter
    | ScaleQuestion
    | SingleSelectQuestion
    | MultiSelectQuestion
  )[];
  currentPosition: number;
  dueDate: Date;
  isPublic: boolean;

  constructor(parameters: GetSurveyResponse) {
    this.referenceId = parameters.reference_id;
    this.chapters = parameters.chapters
      .filter((chapter) => chapter.questions.length || chapter.trigger_warning) // Remove chapters without questions
      .map((chapter) => new SurveyChapter(chapter))
      .sort(
        (chapterA, chapterB) => (chapterA.order || 0) - (chapterB.order || 0)
      );

    this.segmentOrder = [];
    this.chapters.forEach((chapter) => {
      // If a chapter is hidden it's cover should not show, the questions however should
      if (!chapter.hidden) {
        this.segmentOrder.push(chapter);
      }

      chapter.questions.forEach((question) => {
        this.segmentOrder.push(question);
      });
    });
    this.currentPosition = 0;
    this.dueDate = new Date(parameters.due_date);
    this.isPublic = !!parameters.is_public;
  }

  getNextSegment = ():
    | SurveyChapter
    | ScaleQuestion
    | MultiSelectQuestion
    | SingleSelectQuestion
    | undefined => {
    if (this.currentPosition >= this.segmentOrder.length) {
      if (this.currentPosition === this.segmentOrder.length) {
        this.currentPosition = this.currentPosition + 1;
      }
      return undefined;
    }

    const question = this.segmentOrder[this.currentPosition];
    this.currentPosition = this.currentPosition + 1;

    return question;
  };

  getPreviousSegment = ():
    | SurveyChapter
    | ScaleQuestion
    | MultiSelectQuestion
    | SingleSelectQuestion
    | undefined => {
    if (this.currentPosition <= 1) {
      this.currentPosition = 0;
      return undefined;
    }

    if (this.currentPosition > this.segmentOrder.length) {
      return undefined;
    }

    const question = this.segmentOrder[this.currentPosition - 2];
    this.currentPosition = this.currentPosition - 1;

    return question;
  };

  isLastSegment = (referenceId: string | undefined) => {
    if (!referenceId) return false;

    const lastQuestion = this.segmentOrder[this.segmentOrder.length - 1];
    return lastQuestion.referenceId === referenceId;
  };

  getProgress = () => {
    return (this.currentPosition / this.segmentOrder.length) * 100;
  };

  getSegmentMidpoint = () => {
    const midpoint = Math.round(this.segmentOrder.length / 2);
    return (midpoint / this.segmentOrder.length) * 100;
  };

  getAnswers = (): SurveyAnswer[] => {
    const chapterAnswers = this.chapters.map((chapter) => {
      return chapter.questions.map((question) => {
        return {
          question: question.referenceId,
          options: question.getAnswerIds(),
        };
      });
    });
    return chapterAnswers.flat();
  };
}

export default Survey;
