import { Typography } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { ScaleRailProps } from "../../types/scale";
import "./PayEquityScale.scss";

export const PayEquityRail = ({ showLabels = true }: ScaleRailProps) => {
  const { t } = useTranslation("payEquityPage");

  return (
    <div className="PayEquityRail">
      <div className="PayEquityRail__rail"></div>
      {showLabels && (
        <div className="PayEquityRail__labels">
          <Typography color="secondary" variant="h6">
            {t("scale.labels.low")}
          </Typography>
          <Typography color="secondary" variant="h6">
            {t("scale.labels.high")}
          </Typography>
        </div>
      )}
    </div>
  );
};
