import {
  IQuestionOption,
  QuestionType,
  SurveyQuestionResponse,
} from "../types/question";
import Question from "./question";

class MultiSelectQuestion extends Question {
  answer: IQuestionOption[] = [];
  type: QuestionType;

  constructor(parameters: SurveyQuestionResponse) {
    super(parameters);
    this.type = QuestionType.MultiSelect;
  }

  setAnswer = (optionId: string) => {
    const option = this.options.find(
      (option) => option.referenceId === optionId
    );

    if (option) {
      const answerIndex = this.answerIndex(option);
      if (answerIndex !== -1) {
        this.answer.splice(answerIndex, 1);
      } else {
        if (option.negatesOthers) {
          this.answer = [option];
        } else {
          this.answer = this.answer.filter((option) => !option.negatesOthers);
          this.answer.push(option);
        }
      }
    }
  };

  answerIndex = (option: IQuestionOption): number => {
    return this.answer.findIndex(
      (answerOption) => answerOption.referenceId === option.referenceId
    );
  };

  hasAnswer = (): boolean => {
    return this.answer.length > 0;
  };

  getAnswerIds = (): string[] => {
    const answers = this.answer.map((answer) => answer.referenceId);
    return answers;
  };

  resetAnswer = () => {
    this.answer = [];
  };

  isMultiSelect = (): boolean => {
    return true;
  };
}

export default MultiSelectQuestion;
