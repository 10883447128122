interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  selectedTabIndex: number;
  label: string;
  alwaysRenderContent?: boolean;
  className?: string;
}

export const TabPanel = ({
  children,
  selectedTabIndex,
  index,
  label,
  alwaysRenderContent = false,
  className,
}: TabPanelProps) => {
  return (
    <div
      className={className}
      role="tabpanel"
      hidden={selectedTabIndex !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`${label}-${index}`}
    >
      {(alwaysRenderContent || selectedTabIndex === index) && children}
    </div>
  );
};
