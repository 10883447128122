import { coalColor } from "../../../styles/theme";
import { IconProps } from "./";

export const TouchpointIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={coalColor}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={(event) => onClick?.(event)}
    >
      <path
        d="M18.1901 4.65667C18.1901 4.65667 7.76378 4.30417 5.67851 4.65667C3.59325 5.00916 3.59308 6.41912 3.89098 7.82911C4.18887 9.23911 3.8909 15.2317 3.89114 17.3466C3.89138 19.4616 5.42999 19.4616 6.27455 19.4616C6.27455 19.4616 15.5093 19.1091 18.1904 19.4616C20.8714 19.8141 20.2751 17.6991 19.9775 14.8791C19.6798 12.0591 19.9775 6.77169 19.9775 6.77169C19.9775 5.73339 19.0347 4.65667 18.1901 4.65667Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.0001 15C10.0001 15 15.375 13.125 15.0001 12C14.6252 10.875 10.0001 9 10.0001 9C10.0001 9 9.75 9.75 9.75 12C9.75 14.25 10.0001 15 10.0001 15Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
