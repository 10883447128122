// TODO: The server should return the available languages,
// but for now we hard code them and keep them manually in sync with the server.
export enum AvailableLanguages {
  "ENGLISH_GB" = "en-gb",
  "ENGLISH_US" = "en-us",
  "ICELANDIC" = "is-is",
}
export enum AdditionalSurveyLanguages {
  "ARABIC" = "ar-xa",
  "DANISH" = "da-dk",
  "GERMAN" = "de-de",
  "HINDI" = "hi-in",
  "LATVIAN" = "lv-lv",
  "LITHUANIAN" = "lt-lt",
  "NORWEGIAN" = "no-no",
  "POLISH" = "pl-pl",
  "PORTUGUESE_PT" = "pt-pt",
  "ROMANIAN" = "ro-ro",
  "SPANISH_ES" = "es-es",
  "SWEDISH" = "sv-se",
  "TAGALOG" = "tl-ph",
  "THAI" = "th-th",
  "UKRAINIAN" = "uk-ua",
  "VIETNAMESE" = "vi-vn",
}

export type TAvailableLanguages =
  | AvailableLanguages.ENGLISH_GB
  | AvailableLanguages.ENGLISH_US
  | AvailableLanguages.ICELANDIC;
export type TAvailableSurveyLanguages =
  | TAvailableLanguages
  | AdditionalSurveyLanguages.ARABIC
  | AdditionalSurveyLanguages.DANISH
  | AdditionalSurveyLanguages.GERMAN
  | AdditionalSurveyLanguages.HINDI
  | AdditionalSurveyLanguages.LATVIAN
  | AdditionalSurveyLanguages.LITHUANIAN
  | AdditionalSurveyLanguages.NORWEGIAN
  | AdditionalSurveyLanguages.POLISH
  | AdditionalSurveyLanguages.PORTUGUESE_PT
  | AdditionalSurveyLanguages.ROMANIAN
  | AdditionalSurveyLanguages.SPANISH_ES
  | AdditionalSurveyLanguages.SWEDISH
  | AdditionalSurveyLanguages.TAGALOG
  | AdditionalSurveyLanguages.THAI
  | AdditionalSurveyLanguages.UKRAINIAN
  | AdditionalSurveyLanguages.VIETNAMESE;

export const AVAILABLE_LANGUAGES: TAvailableLanguages[] = [
  AvailableLanguages.ENGLISH_GB,
  AvailableLanguages.ENGLISH_US,
  AvailableLanguages.ICELANDIC,
];

export const AVAILABLE_SURVEY_LANGUAGES: TAvailableSurveyLanguages[] = [
  AvailableLanguages.ENGLISH_GB,
  AvailableLanguages.ICELANDIC,
  AdditionalSurveyLanguages.ARABIC,
  AdditionalSurveyLanguages.DANISH,
  AdditionalSurveyLanguages.GERMAN,
  AdditionalSurveyLanguages.HINDI,
  AdditionalSurveyLanguages.LATVIAN,
  AdditionalSurveyLanguages.LITHUANIAN,
  AdditionalSurveyLanguages.NORWEGIAN,
  AdditionalSurveyLanguages.POLISH,
  AdditionalSurveyLanguages.PORTUGUESE_PT,
  AdditionalSurveyLanguages.ROMANIAN,
  AdditionalSurveyLanguages.SPANISH_ES,
  AdditionalSurveyLanguages.SWEDISH,
  AdditionalSurveyLanguages.TAGALOG,
  AdditionalSurveyLanguages.THAI,
  AdditionalSurveyLanguages.UKRAINIAN,
  AdditionalSurveyLanguages.VIETNAMESE,
];

export type AvailableSurveyLanguages =
  | AvailableLanguages
  | AdditionalSurveyLanguages;

export const SURVEY_LANGUAGE_QUERY_KEY = "lang";
export const SURVEY_LANGUAGE_LOCAL_STORAGE_KEY = "surveyLanguage";
export const SURVEY_LANGUAGE_DEFAULT_LOCAL_STORAGE_KEY =
  "defaultSurveyLanguage";
