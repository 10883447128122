import { TooltipProps } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import { ScaleValue } from "../../../types/scale";
import { ScaleTooltip } from "./ScaleTooltip";
import "./Scale.scss";

interface ScaleTickProps<T> {
  values: T[];
  position: number;
  hideTooltip?: boolean;
  small?: boolean;
  placement?: TooltipProps["placement"];
  onClick?: (value: T) => void;
}

export const ScaleTick = <T extends ScaleValue>({
  values,
  position,
  hideTooltip = false,
  small = false,
  placement,
  onClick,
}: ScaleTickProps<T>) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    if (!isHovering) setIsHovering(true);
  };

  const handleMouseLeave = () => {
    if (isHovering) setIsHovering(false);
  };

  const classes = classNames("ScaleTick", {
    "ScaleTick--small": hideTooltip && small,
  });

  return (
    <ScaleTooltip
      values={values}
      isHoveringOverTick={isHovering}
      hide={hideTooltip}
      title={
        values.length > 1
          ? t("scale.groups", { count: values.length })
          : undefined
      }
      small={small}
      placement={placement}
      onClick={onClick}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classes}
        style={{
          left: `${position}%`,
        }}
      ></div>
    </ScaleTooltip>
  );
};
