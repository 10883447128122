import { IconProps } from ".";

export const MinusIcon = ({ className, size = 24 }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 12H17"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12.5" cy="12.5" r="11" stroke="#B1B1B1" strokeOpacity="0.3" />
    </svg>
  );
};
