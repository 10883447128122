import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";

const CustomisedTooltip = styled(({ className, ...props }: MUITooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 12,
    fontSize: "0.8rem",
    padding: "0.5rem",
  },
}));

export const Tooltip = ({ children, ...rest }: MUITooltipProps) => {
  return <CustomisedTooltip {...rest}>{children}</CustomisedTooltip>;
};
