import { QuestionType } from "../types/question";
import { SurveyChapterResponse } from "../types/survey";
import MultiSelectQuestion from "./multiSelectQuestion";
import ScaleQuestion from "./scaleQuestion";
import SingleSelectQuestion from "./singleSelectQuestion";

class SurveyChapter {
  referenceId: string;
  title: string;
  order: number;
  questions: (ScaleQuestion | SingleSelectQuestion | MultiSelectQuestion)[];
  description: string | undefined;
  triggerWarning: string | undefined;
  hidden: boolean;

  constructor(parameters: SurveyChapterResponse) {
    this.referenceId = parameters.reference_id;
    this.title = parameters.title;
    this.order = parameters.order;
    this.description = parameters.description;
    this.triggerWarning = parameters.trigger_warning;
    this.hidden = parameters.hidden;
    this.questions = parameters.questions
      .map((question) => {
        if (question.kind === QuestionType.MultiSelect) {
          return new MultiSelectQuestion(question);
        }

        if (question.kind === QuestionType.Scale) {
          return new ScaleQuestion(question);
        }

        return new SingleSelectQuestion(question);
      })
      .sort(
        (questionA, questionB) =>
          (questionA.order || 0) - (questionB.order || 0)
      );
  }
}

export default SurveyChapter;
