import { Tabs as MUITabs, TabsProps as MUITabsProps } from "@mui/material";
import classNames from "classnames";
import "./Tabs.scss";

export interface ITabsProps extends MUITabsProps {
  selectedTabIndex: number;
  label?: string;
  small?: boolean;
  withIndicator?: boolean;
}

export const Tabs = ({
  selectedTabIndex,
  className,
  label,
  children,
  variant = "scrollable",
  scrollButtons = "auto",
  allowScrollButtonsMobile = true,
  small = false,
  withIndicator = false,
  ...rest
}: ITabsProps) => {
  const classes = classNames("Tabs", className, { "Tabs--small": small });

  return (
    <MUITabs
      value={selectedTabIndex}
      variant={variant}
      scrollButtons={scrollButtons}
      aria-label={label}
      className={classes}
      TabIndicatorProps={
        withIndicator
          ? { className: "Tabs__indicator" }
          : { sx: { display: "none" } }
      }
      allowScrollButtonsMobile={allowScrollButtonsMobile}
      {...rest}
    >
      {children}
    </MUITabs>
  );
};
