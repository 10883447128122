import { FormEvent } from "react";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";

interface StepContainerType {
  submitFunction: (e: FormEvent<HTMLFormElement>) => void;
  step: number;
  children: any;
  setStep?: any;
}

export const StepContainer = ({
  submitFunction,
  step,
  children,
  setStep,
}: StepContainerType) => {
  return (
    <Card className="SignUp" containerClassName="SignUp__container" key={step}>
      <form className="SignUp__form" onSubmit={(e) => submitFunction(e)}>
        {children}
        {step === 0 && (
          <div style={{ float: "right" }}>
            <Button variant="contained" small type="submit">
              Next
            </Button>
          </div>
        )}
        {step > 0 && (
          <div className="SignUp__form__button">
            <Button
              variant="outlined"
              onClick={() => {
                setStep(step - 1);
              }}
              small
            >
              Back
            </Button>

            <Button variant="contained" small type="submit">
              Next
            </Button>
          </div>
        )}
      </form>
    </Card>
  );
};
