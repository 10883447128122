import {
  CheckOutlined as CheckOutlinedIcon,
  ContentCopyOutlined as ContentCopyOutlinedIcon,
} from "@mui/icons-material";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { SURVEY_LANGUAGE_QUERY_KEY } from "../../types/locale";
import { SURVEY_INVITE_TOKEN_KEY } from "../../utils/survey.utils";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyPublicLink.scss";

interface SurveyPublicLinkProps {
  surveyDispatch: SurveyDispatch;
}

export const SurveyPublicLink = ({ surveyDispatch }: SurveyPublicLinkProps) => {
  const { t } = useTranslation("launchSurveyPage");
  const [isCopied, setIsCopied] = useState(false);
  const pathToSurvey = `${
    process.env.REACT_APP_WEB_URL
  }/survey/start?${SURVEY_INVITE_TOKEN_KEY}=${
    surveyDispatch.id
  }&${SURVEY_LANGUAGE_QUERY_KEY}=${surveyDispatch.getLanguage()}`;

  let buttonLabel = isCopied
    ? t("shareLink.public.successMsg")
    : t("shareLink.public.copy");

  const handleCopy = () => {
    navigator.clipboard.writeText(pathToSurvey);
    setIsCopied(true);
  };

  return (
    <div className="SurveyPublicLink">
      <Typography tagVariant="h3" desktop="h3">
        {t("shareLink.public.title")}
      </Typography>
      <Typography desktop="body2">{t("shareLink.public.info")}</Typography>
      <Card className="SurveyPublicLink__link">
        <Typography desktop="body1">{pathToSurvey}</Typography>
        <Button
          variant="contained"
          color="info"
          onClick={handleCopy}
          className="SurveyPublicLink__link__button"
          small
        >
          {!isCopied && (
            <ContentCopyOutlinedIcon className="SurveyPublicLink__link__icon" />
          )}
          {isCopied && (
            <CheckOutlinedIcon className="SurveyPublicLink__link__icon" />
          )}
          {buttonLabel}
        </Button>
      </Card>
    </div>
  );
};
