import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers";
import type { DateValidationError as MuiDateValidationError } from "@mui/x-date-pickers";
import { useState } from "react";
import "./Input.scss";

export type DateValidationError = MuiDateValidationError | "requiredDate";
export interface DatePickerProps<Date> extends MuiDatePickerProps<Date> {
  required?: boolean;
  error?: string;
  onChange?: (date: Date | null) => void;
  onError?: (error: DateValidationError, value: Date | null) => void;
  dataTestId?: string;
}
export const DatePicker = ({
  error,
  required,
  onChange,
  onError,
  dataTestId,
  ...props
}: DatePickerProps<Date>) => {
  const [value, setValue] = useState(props.value);

  const handleError = (error: MuiDateValidationError, val: Date | null) => {
    if (required && !val) {
      onError?.("requiredDate", val);
    } else onError?.(error, val);
  };

  const handleDateChange = (newValue: Date | null) => {
    setValue(newValue);
    onChange?.(newValue);

    if (required && !newValue) {
      onError?.("requiredDate", newValue);
    }
  };

  return (
    <MuiDatePicker
      className="DatePicker"
      defaultValue={value}
      onError={handleError}
      onChange={handleDateChange}
      slotProps={{
        textField: {
          error: !!error,
          helperText: error ? error : "",
          inputProps: {
            "data-testid": dataTestId,
          },
        },
      }}
      {...props}
    />
  );
};
