import { Skeleton, Typography } from "@mui/material";
import classnames from "classnames";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import { DiversityRatioBreakdown as IDiversityRatioBreakdown } from "../../types/diversityRatio";
import { DiversityRatioChartFilter } from "../../utils/DiversityRatio.utils";
import { DiversityRatioBreakdown } from "../DiversityRatioBreakdown/DiversityRatioBreakdown";
import { EmptyState } from "../EmptyState/EmptyState";
import { NoDataState } from "../NoDataState/NoDataState";
import { Card } from "../ui/Card/Card";
import { PeopleChart } from "../ui/PeopleChart/PeopleChart";
import { Tab } from "../ui/Tabs/Tab";
import { TabPanel } from "../ui/Tabs/TabPanel";
import { Tabs } from "../ui/Tabs/Tabs";
import { Direction, IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./DiversityRatioOverview.scss";

interface DiversityRatioProps {
  isWidget?: boolean;
  isLoading?: boolean;
  error?: boolean;
  noIntegration?: boolean;
  selectedTabIndex: number;
  setSelectedTabIndex: (index: number) => void;
  diversityFilters?: DiversityRatioChartFilter[];
  diversityBreakdown?: IDiversityRatioBreakdown[];
  diversityBreakdownGroupColors?: { [key: string]: string };
}

export const DiversityRatioOverview = ({
  isWidget = false,
  selectedTabIndex,
  setSelectedTabIndex,
  isLoading,
  error,
  noIntegration,
  diversityFilters,
  diversityBreakdown,
  diversityBreakdownGroupColors,
}: DiversityRatioProps) => {
  const { t } = useTranslation("diversityRatioPage");
  const { authInfo } = useAuth();

  const handleChangeTab = (newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const renderTabs = () => (
    <>
      <Tabs
        selectedTabIndex={selectedTabIndex}
        label="Diversity-ratio-chart"
        className="DiversityRatioOverview__filters"
        small={isWidget}
        withIndicator
      >
        {diversityFilters?.map((filter, i) => {
          return (
            <Tab
              key={filter.label}
              selected={selectedTabIndex === i}
              label={t(`chart.filters.${filter.label}.title`)}
              index={i}
              onClick={handleChangeTab}
              small={isWidget}
              withIndicator
            />
          );
        })}
      </Tabs>
      {renderPeopleChart()}
    </>
  );

  const renderPeopleChart = () => (
    <>
      {diversityFilters?.map((filter, i) => {
        return (
          <TabPanel
            selectedTabIndex={selectedTabIndex}
            index={i}
            key={`diversityFilters-${i}`}
            label="diversity-filters-tab"
          >
            <PeopleChart
              values={filter.chartValues}
              info={t("chart.filters.info")}
              small={isWidget}
            />
            {diversityBreakdown && !isWidget && (
              <>
                <Icon
                  type={IconTypes.RedArrow}
                  size={50}
                  direction={Direction.DOWN}
                />
                <DiversityRatioBreakdown
                  diversityBreakdown={diversityBreakdown}
                  diversityBreakdownGroupColors={diversityBreakdownGroupColors}
                  selectedTabLabel={diversityFilters[selectedTabIndex].label}
                />
              </>
            )}
          </TabPanel>
        );
      })}
    </>
  );

  const renderLoading = () => (
    <div className="DiversityRatioOverview__loading">
      <Skeleton height={50} />
      <Skeleton height={200} />
    </div>
  );

  const renderError = () => (
    <NoDataState title={t("error.title")} info={t("error.info")} />
  );

  const renderNoData = () => (
    <EmptyState
      title={t("noData.title")}
      info={t("noData.info")}
      href="/settings"
      actionInfo={t("noData.action")}
    />
  );
  const classes = classnames("DiversityRatioOverview", {
    "DiversityRatioOverview--widget": isWidget,
    "DiversityRatioOverview--loading": isLoading,
  });

  const containerClasses = classnames("DiversityRatioOverview--container", {
    "DiversityRatioOverview--widget": isWidget,
  });

  return (
    <Card className={classes} containerClassName={containerClasses}>
      {!isWidget && !noIntegration && (
        <Typography className="DiversityRatioOverview__title" variant="h2">
          {t("title")}
          <Typography
            variant="handwrittenH2"
            color="error"
            sx={{ m: "0 0.5rem" }}
          >
            {authInfo.user?.companyName}
          </Typography>
        </Typography>
      )}

      {!isLoading && !error && !noIntegration && renderTabs()}
      {isLoading && !noIntegration && renderLoading()}
      {error && !isLoading && !noIntegration && renderError()}
      {noIntegration && renderNoData()}
    </Card>
  );
};
