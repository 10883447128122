import { useEffect, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import {
  DatePicker,
  DatePickerProps,
  DateValidationError,
} from "../ui/Input/DatePicker";
import { DateType } from "./SurveySettings";

interface SurveySettingsDatePickerProps<Date> extends DatePickerProps<Date> {
  value: Date | undefined;
  setValue: (date: Date) => void;
  type: DateType;
  disabled?: boolean;
  setIsChanged: (changed: boolean) => void;
  setIsError: (error: boolean) => void;
}
export const SurveySettingsDatePicker = ({
  value,
  setValue,
  type,
  disabled,
  setIsChanged,
  setIsError,
  ...props
}: SurveySettingsDatePickerProps<Date>) => {
  const { t } = useTranslation("launchSurveyPage");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (disabled) {
      setError("");
    }
  }, [disabled]);

  useEffect(() => {
    setIsError(!!error);
  }, [error, setIsError]);

  const handleError = (err: DateValidationError) => {
    if (err === "invalidDate") {
      setError(t(`edit.errors.invalidDate`) || "");
    } else if (err === "requiredDate") {
      setError(t(`edit.errors.emptyDate`) || "");
    } else if (err) {
      setError(t(`edit.errors.${type}`) || "");
    } else {
      setError("");
    }
  };

  const handleChange = (newValue: Date) => {
    setValue(newValue);
    setIsChanged(true);
  };

  const handleAccept = () => {
    setError("");
  };

  return (
    <DatePicker
      defaultValue={value}
      onChange={(newValue) => handleChange(newValue as Date)}
      onError={(newError) => handleError(newError)}
      onAccept={handleAccept}
      error={error}
      disabled={disabled}
      {...props}
    />
  );
};
