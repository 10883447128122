import { CardActionArea } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Card } from "../ui/Card/Card";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./RecommendationLinkCard.scss";

export interface RecommendationLinkCardProps {
  href: string;
  children?: ReactNode;
}
export const RecommendationLinkCard = ({
  href,
  children,
}: RecommendationLinkCardProps) => {
  return (
    <Card variant="outlined">
      <CardActionArea
        component={Link}
        to={href}
        className="RecommendationLinkCard"
        disableRipple
      >
        {children}
        <button className="RecommendationLinkCard__button">
          <Icon type={IconTypes.RedArrow} />
        </button>
      </CardActionArea>
    </Card>
  );
};
