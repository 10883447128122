import { NavLink } from "react-router-dom";
import { Logo } from "../components/ui/Logo";
import useTranslation from "../hooks/useTranslation";
import "./ErrorPage.scss";

interface ErroPageProps {
  errorCode: 404 | 500;
}

export const ErrorPage = ({ errorCode }: ErroPageProps) => {
  const { t } = useTranslation();
  return (
    <div className="ErrorPage">
      <Logo />
      <div className="ErrorPage__container">
        <h1 className="ErrorPage__container__heading">{errorCode}</h1>
        <p className="ErrorPage__container__paragraph">
          {t(`errorPage.${errorCode}.error`)}
        </p>
        {errorCode === 404 && (
          <NavLink to="/">{t(`errorPage.${errorCode}.link`)}</NavLink>
        )}
      </div>
    </div>
  );
};
