import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { isAfter } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SurveyService } from "../../api/SurveyService";
import SurveyDispatch from "../../models/surveyDispatch";
import { SurveyDispatchReminder } from "../../types/survey";
import { hasDuplicateReminders, sortReminders } from "../../utils/survey.utils";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import { SurveySettingsDatePicker } from "./SurveySettingsDatePicker";
import { SurveySettingsReminder } from "./SurveySettingsReminder";
import "./SurveySettings.scss";

export enum DateType {
  LAUNCH = "launch",
  REMINDER = "reminder",
  DUE = "due",
}
interface SurveySettingsProps {
  dispatch: SurveyDispatch;
  onSurveyUpdate?: () => void;
}

export const SurveySettings = ({
  dispatch,
  onSurveyUpdate,
}: SurveySettingsProps) => {
  const { t } = useTranslation("launchSurveyPage");
  const [launchDate, setLaunchDate] = useState(dispatch?.launchDate);
  const [reminders, setReminders] = useState<SurveyDispatchReminder[]>([]);
  const [dueDate, setDueDate] = useState(dispatch?.dueDate);
  const [error, setError] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [disableLaunchDate, setDisableLaunchDate] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const surveyService = new SurveyService();

  useEffect(() => {
    if (dispatch?.dueDate) {
      setDueDate(dispatch.dueDate);
    }
    if (dispatch?.launchDate) {
      setLaunchDate(dispatch.launchDate);
    }
    if (dispatch?.reminders) {
      setReminders(sortReminders(dispatch.reminders));
    }
  }, [dispatch]);

  useEffect(() => {
    if (dispatch.launchDate) {
      setDisableLaunchDate(isAfter(Date.now(), dispatch.launchDate));
    }
  }, [dispatch.launchDate]);

  const updateSurveyLaunchDate = () => {
    launchDate && dispatch.setLaunchDate(launchDate);
  };

  const updateSurveyReminders = () => {
    dispatch.setReminders(reminders);
  };
  const updateSurveyDueDate = () => {
    dueDate && dispatch.setDueDate(dueDate);
  };

  const rescheduleSurveyMutation = useMutation({
    mutationFn: (filteredReminders: SurveyDispatchReminder[]) => {
      return surveyService.rescheduleSurvey(
        dispatch?.id,
        launchDate as Date,
        filteredReminders,
        dueDate as Date
      );
    },
  });

  const handleSaveDates = async () => {
    setSuccessMsg("");
    setSubmitError("");
    const filteredReminders = reminders.filter((reminder) => reminder.date);

    if (hasDuplicateReminders(filteredReminders)) {
      setSubmitError(t("edit.reminders.duplicate") || "");
      return;
    }
    setReminders(filteredReminders);

    await rescheduleSurveyMutation.mutateAsync(filteredReminders, {
      onSuccess: () => {
        updateSurveyLaunchDate();
        updateSurveyReminders();
        updateSurveyDueDate();
        setSuccessMsg(t(`edit.success`) || "");
        setIsChanged(false);
        onSurveyUpdate && onSurveyUpdate();
      },
      onError: () => {
        setSubmitError(t(`edit.errors.failedSubmit`) || "");
      },
    });
  };

  return (
    <div className="SurveySettings">
      <div className="SurveySettings__content">
        <div>
          <div className="SurveySettings__content__info">
            <Grid
              container
              className="SurveySettings__content__info__dateRange"
            >
              <Grid item sm={5.5} xs={10}>
                <Typography
                  className="SurveySettings__content__info__title"
                  tagVariant="h3"
                  desktop="body1"
                  weight="bold"
                >
                  {t(`edit.launchDate`)}
                </Typography>
                <SurveySettingsDatePicker
                  className="SurveySettings__content__info__value"
                  dataTestId="launchDate"
                  value={launchDate}
                  setValue={setLaunchDate}
                  type={DateType.LAUNCH}
                  disabled={disableLaunchDate}
                  maxDate={dueDate}
                  minDate={new Date()}
                  required
                  setIsChanged={setIsChanged}
                  setIsError={setError}
                />
              </Grid>
              <Grid
                item
                sm={1}
                className="SurveySettings__content__info__dateRange__divider"
              >
                <span>—</span>
              </Grid>
              <Grid item sm={5.5} xs={10}>
                <Typography
                  className="SurveySettings__content__info__title"
                  tagVariant="h3"
                  desktop="body1"
                  weight="bold"
                >
                  {t(`edit.dueDate`)}
                </Typography>
                <SurveySettingsDatePicker
                  className="SurveySettings__content__info__value"
                  dataTestId="dueDate"
                  value={dueDate}
                  setValue={setDueDate}
                  type={DateType.DUE}
                  minDate={launchDate}
                  required
                  setIsChanged={setIsChanged}
                  setIsError={setError}
                />
              </Grid>
            </Grid>
          </div>
          <div className="SurveySettings__content__info">
            <Typography
              className="SurveySettings__content__info__title"
              tagVariant="h3"
              desktop="body1"
              weight="bold"
            >
              {t(`edit.reminders.title`)}
            </Typography>
            <SurveySettingsReminder
              dispatch={dispatch}
              reminders={reminders}
              launchDate={launchDate}
              dueDate={dueDate}
              setReminders={setReminders}
              setIsChanged={setIsChanged}
              setIsError={setError}
              setSubmitError={setSubmitError}
            />
          </div>
          <div className="SurveySettings__content__button">
            <Button
              variant="contained"
              onClick={() => handleSaveDates()}
              disabled={error || !isChanged || !launchDate || !dueDate}
              loading={rescheduleSurveyMutation.isLoading}
              data-testid="saveButton"
            >
              {t("edit.save")}
            </Button>
            {(!launchDate || !dueDate) && (
              <Typography desktop="caption">{t("edit.saveInfo")}</Typography>
            )}
            {successMsg && !isChanged && (
              <Typography desktop="caption">{successMsg}</Typography>
            )}
            {submitError && (
              <Typography tagVariant="p" desktop="caption" color="error">
                {submitError}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
