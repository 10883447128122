import classNames from "classnames";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { WorkplaceCultureIllustration } from "../../styles/illustrations/WorkplaceCultureIllustration";
import { SurveyDispatchStatus } from "../../types/survey";
import { GetSurveyScoringDemographicResponse } from "../../types/workplaceCulture";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { EmptyState } from "../EmptyState/EmptyState";
import { SurveyStatus } from "../SurveyStatus/SurveyStatus";
import { WorkplaceCultureOverview } from "../WorkplaceCultureOverview/WorkplaceCultureOverview";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";
import "./WorkplaceCultureWidget.scss";

interface WorkplaceCultureWidgetProps {
  surveyDispatch?: SurveyDispatch;
  surveyDispatchLoading?: boolean;
  surveyDispatchNoSurvey?: boolean;
  surveyDispatchError?: boolean;
  onSurveyUpdate: () => void;
  scoringHasEnoughAnswers: boolean;
  scoringData?: GetSurveyScoringDemographicResponse;
  scoringIsLoading?: boolean;
  scoringError?: boolean;
}

export const WorkplaceCultureWidget = ({
  surveyDispatch,
  surveyDispatchLoading = true,
  surveyDispatchNoSurvey = false,
  surveyDispatchError = false,
  onSurveyUpdate,
  scoringHasEnoughAnswers,
  scoringData,
  scoringIsLoading,
  scoringError,
}: WorkplaceCultureWidgetProps) => {
  const { t: tDashboard } = useTranslation("dashboardPage");
  const { t: tWorkplaceCulture } = useTranslation("workplaceCulturePage");

  const displaySurveyStatus =
    !surveyDispatchNoSurvey &&
    (surveyDispatchLoading ||
      surveyDispatchError ||
      surveyDispatch?.isActive() ||
      surveyDispatch?.isInProgress() ||
      surveyDispatch?.isReady() ||
      surveyDispatch?.status === SurveyDispatchStatus.Latest);

  const classes = classNames("WorkplaceCultureWidget", {
    "WorkplaceCultureWidget--noSurvey": !displaySurveyStatus,
  });

  return (
    <DashboardWidget
      title={tDashboard("workplaceCultureWidget.title")}
      icon={WorkplaceCultureIllustration}
      href="/workplace-culture"
    >
      {surveyDispatchNoSurvey && (
        <EmptyState
          title={tWorkplaceCulture("status.noSurvey")}
          href={ProtectedRoutes.LAUNCH}
          actionInfo={tWorkplaceCulture("status.sendSurvey")}
          isWidget
        />
      )}
      {!surveyDispatchNoSurvey && (
        <div className={classes}>
          {displaySurveyStatus && (
            <SurveyStatus
              survey={surveyDispatch}
              isLoading={surveyDispatchLoading}
              error={surveyDispatchError}
              noSurvey={surveyDispatchNoSurvey}
              isWidget
              onSurveyUpdate={onSurveyUpdate}
            />
          )}
          {!surveyDispatchNoSurvey && (
            <WorkplaceCultureOverview
              isWidget
              hasEnoughAnswers={scoringHasEnoughAnswers}
              scoringData={scoringData}
              isLoading={scoringIsLoading}
              error={scoringError}
            />
          )}
        </div>
      )}
    </DashboardWidget>
  );
};
