import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { StepContainer } from "./StepContainer";

interface LocationStepType {
  companyName: string;
  locations: string[];
  setLocations: (value: SetStateAction<string[]>) => void;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
}

export const LocationStep = ({
  companyName,
  locations,
  setLocations,
  setStep,
  step,
}: LocationStepType) => {
  const listOfLocations = ["EU", "UK", "USA", "Canada", "Other"];
  const handleLocationChange = (event: SelectChangeEvent<typeof locations>) => {
    const {
      target: { value },
    } = event;
    setLocations(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <StepContainer
      step={step}
      submitFunction={() => setStep(step + 1)}
      setStep={setStep}
    >
      <Typography variant="h1" sx={{ pb: 3 }}>
        Location
      </Typography>
      <Typography variant="body1" sx={{ pb: 3 }} style={{ fontSize: "1rem" }}>
        Where is{" "}
        <Typography
          variant="handwrittenH1"
          style={{ fontSize: "inherit" }}
          color="error"
        >
          {companyName}
        </Typography>{" "}
        located?
      </Typography>
      <FormControl size="small">
        <InputLabel id="location">Location</InputLabel>
        <Select
          labelId="location"
          id="location"
          value={locations}
          multiple
          required
          onChange={handleLocationChange}
          label="Locations"
          className="SignUp__form__dropdown"
        >
          {listOfLocations.map((location) => (
            <MenuItem value={location} key={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StepContainer>
  );
};
