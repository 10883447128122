import { Box } from "@mui/material";
import { FunctionComponent } from "react";
import useTranslation from "../../../hooks/useTranslation";
import { ScaleRailProps, ScaleValue } from "../../../types/scale";
import { getScaleValues } from "./Scale.utils";
import { ScaleAverageTooltip } from "./ScaleAverageTooltip";
import { ScaleTick } from "./ScaleTick";
import "./Scale.scss";

interface ScaleProps<T> {
  values: T[];
  average?: ScaleValue;
  min: number;
  max: number;
  RailComponent: FunctionComponent<ScaleRailProps>;
  className?: string;
  small?: boolean;
  minScoreLabel?: string;
  maxScoreLabel?: string;
  onClick?: (value: T) => void;
}

export const Scale = <T extends ScaleValue>({
  values,
  min,
  max,
  average,
  small = false,
  RailComponent,
  className,
  maxScoreLabel,
  minScoreLabel,
  onClick,
}: ScaleProps<T>) => {
  const { t } = useTranslation();
  const range = max - min;

  const { groupedValuesByScore, scoresArray } = getScaleValues(
    values,
    t,
    minScoreLabel,
    maxScoreLabel,
    small
  );

  const positions = scoresArray.map((groupScore) => {
    const percentage = ((Number(groupScore) - min) / range) * 100;
    return percentage;
  });

  const averagePosition = average && ((average.score - min) / range) * 100;

  return (
    <Box sx={{ position: "relative" }} className={className}>
      <RailComponent
        showLabels={!small}
        minValue={min}
        maxValue={max}
        small={small}
      />
      {scoresArray.map((groupScore: string, i) => {
        const scaleValues: T[] = groupedValuesByScore[groupScore];
        return (
          <ScaleTick
            key={`${scaleValues[0].label}-${i}`}
            values={scaleValues}
            position={positions[i]}
            hideTooltip={small && !(i === 0 || i === scoresArray.length - 1)}
            small={small}
            placement={i % 2 === 0 || small ? "top" : "bottom"}
            onClick={onClick}
          />
        );
      })}
      {average && average.score >= min && average.score <= max && (
        <ScaleAverageTooltip
          title={average.label}
          value={average.scoreLabel || average.score}
          tooltipColor={average.color}
          tooltipColorText={average.colorText}
        >
          <div
            className="ScaleAverageTick"
            style={{
              left: `${averagePosition}%`,
            }}
          ></div>
        </ScaleAverageTooltip>
      )}
    </Box>
  );
};
