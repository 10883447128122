import { Skeleton } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import { PipelineService } from "../../api/PipelineService";
import useTranslation from "../../hooks/useTranslation";
import { JobTitleLevel, JobTitlesResponse } from "../../types/pipeline";
import { LevelPicker } from "../LevelPicker/LevelPicker";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./PipelineJobLevelMapping.scss";

export interface PipelineJobLevelMappingProps {
  jobTitleData?: JobTitlesResponse;
  isLoading?: boolean;
  error?: boolean;
  onClose: () => void | undefined;
  onBack: () => void | undefined;
  setSetupSuccess: (val: boolean) => void;
  className?: string;
}
export const PipelineJobLevelMapping = ({
  jobTitleData,
  isLoading,
  error,
  onClose,
  onBack,
  setSetupSuccess,
}: PipelineJobLevelMappingProps) => {
  const { t } = useTranslation("pipelinePage");
  const pipelineService = new PipelineService();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState<{
    [key: string]: number;
  }>({});

  const submitJobTitleLevelsMutation = useMutation({
    mutationFn: (jobTitleLevels: JobTitleLevel[]) =>
      pipelineService.submitJobTitleLevels({
        job_title_levels: jobTitleLevels,
      }),
  });

  const handleLevelChange = (jobTitle: string, level: number | null) => {
    if (level) {
      setSelectedLevels((prevSelectedLevels) => ({
        ...prevSelectedLevels,
        [jobTitle]: level,
      }));
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const jobTitleLevels: JobTitleLevel[] = Object.keys(selectedLevels).map(
      (key) => {
        return { job_title: key, level: selectedLevels[key] };
      }
    );
    await submitJobTitleLevelsMutation.mutateAsync(jobTitleLevels, {
      onSuccess: () => {
        setIsSubmitSuccess(true);
        setSetupSuccess(true);
      },
      onError: () => {
        setIsSubmitSuccess(false);
      },
    });
  };

  return (
    <Card className="PipelineJobLevelMapping">
      {!isLoading && !isSubmitSuccess && !error && (
        <div>
          <Typography
            tagVariant="h1"
            desktop="h1"
            className="PipelineJobLevelMapping__title"
          >
            {t("setup.jobLevels.title")}
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className="PipelineJobLevelMapping__container">
              {jobTitleData?.job_title_levels?.map((jobTitleLevel, i) => {
                return (
                  <Card
                    className="PipelineJobLevelMapping__container__item"
                    key={jobTitleLevel.job_title}
                  >
                    <Typography
                      tagVariant="h5"
                      desktop="body2"
                      weight="regular"
                    >
                      {jobTitleLevel.job_title}
                    </Typography>
                    <LevelPicker
                      levels={jobTitleData?.levels}
                      onChange={(level: number | null) =>
                        handleLevelChange(jobTitleLevel.job_title, level)
                      }
                    />
                  </Card>
                );
              })}
            </div>
            <div className="PipelineJobLevelMapping__actions">
              <Button
                variant="contained"
                onClick={onBack}
                loading={submitJobTitleLevelsMutation.isLoading}
                disabled={submitJobTitleLevelsMutation.isLoading}
                color="secondary"
                aria-label={t("actions.back")}
              >
                <Icon type={IconTypes.Arrow} />
              </Button>
              <Button
                variant="contained"
                loading={submitJobTitleLevelsMutation.isLoading}
                disabled={submitJobTitleLevelsMutation.isLoading}
                type="submit"
              >
                {t("actions.save")}
              </Button>
            </div>
          </form>
        </div>
      )}
      {isSubmitSuccess && (
        <div>
          <div className="PipelineJobLevelMapping__container">
            <Typography tagVariant="h2" desktop="h3">
              {t("setup.success.title")}
            </Typography>
            <Typography tagVariant="p" desktop="body2" weight="regular">
              {t("setup.success.info")}
            </Typography>
          </div>
          <div className="PipelineJobLevelMapping__actions">
            <Button onClick={onClose} variant="contained" color="success">
              {t("actions.close")}
            </Button>
          </div>
        </div>
      )}
      {isLoading && (
        <div>
          <Typography
            tagVariant="h1"
            desktop="h1"
            className="PipelineJobLevelMapping__header"
          >
            <Skeleton variant="text" width={200} />
          </Typography>
          <div className="PipelineJobLevelMapping__container">
            {[...Array(3)].map((e, i) => {
              return (
                <Card
                  className="PipelineJobLevelMapping__container__item"
                  key={`skeleton-${i}`}
                >
                  <Skeleton width="30%" />
                  <Skeleton width="40%" />
                </Card>
              );
            })}
          </div>
        </div>
      )}
      {error && (
        <Typography tagVariant="h3" desktop="h3">
          {t("setup.error")}
        </Typography>
      )}
    </Card>
  );
};
