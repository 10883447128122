import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { StepContainer } from "./StepContainer";

const listOfCurrencies = [
  { value: "ISK", name: "Icelandic krona" },
  { value: "EUR", name: "Euro" },
  { value: "USD", name: "US Dollar" },
  { value: "GBP", name: "British pound" },
  { value: "DKK", name: "Danish krone" },
  { value: "NOK", name: "Norwegian krone" },
  { value: "SEK", name: "Swedish krona" },
];

interface PreferredCurrencyType {
  currency: string;
  setCurrency: (value: SetStateAction<string>) => void;
  setStep: Dispatch<SetStateAction<number>>;
  step: number;
}

export const PreferredCurrency = ({
  currency,
  setCurrency,
  setStep,
  step,
}: PreferredCurrencyType) => {
  const handleCurrencyChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  };
  return (
    <StepContainer
      step={step}
      submitFunction={() => setStep(step + 1)}
      setStep={setStep}
    >
      <Typography variant="h1" sx={{ pb: 3 }}>
        Currency
      </Typography>
      <Typography
        variant="body1"
        sx={{ pb: 3 }}
        style={{ display: "inline-block", fontSize: "1rem" }}
      >
        In terms of salary and other data, what is your preffered currency?{" "}
      </Typography>
      <FormControl size="small">
        <InputLabel id="currency">Preferred currency</InputLabel>

        <Select
          labelId="currency"
          id="currency"
          value={currency}
          onChange={handleCurrencyChange}
          label="Preferred currency"
          className="SignUp__form__dropdown"
        >
          {listOfCurrencies.map((currency) => (
            <MenuItem value={currency.value} key={currency.name}>
              {currency.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StepContainer>
  );
};
