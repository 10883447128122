/* eslint-disable react/jsx-no-target-blank */
import { Typography, TypographyVariant } from "../Typography/Typography";
import "./Link.scss";

interface LinkProps {
  children: React.ReactNode;
  href: string;
  openInNewTab?: boolean;
  variant?: TypographyVariant;
}
export const Link = ({
  children,
  href,
  openInNewTab,
  variant = "caption",
}: LinkProps) => {
  return (
    <Typography tagVariant="p" desktop={variant}>
      <a
        className="Link"
        href={href}
        target={openInNewTab ? "_blank" : undefined}
        rel={openInNewTab ? "noreferrer" : undefined}
      >
        {children}
      </a>
    </Typography>
  );
};
