import { coalColor } from "../../../styles/theme";
import { IconProps } from "./";

export const HamburgerIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={(event) => onClick?.(event)}
    >
      <path
        d="M4.5 12.7497C13.5001 11.6247 15 11.6247 19.5 11.9997"
        stroke={coalColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 18C13.5 18 15 18 19.5 17.25"
        stroke={coalColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 6C15.625 6.125 7.875 6 4.5 6.75"
        stroke={coalColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
