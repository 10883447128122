import { GetPayEquityResponse, PayEquityGroup } from "../../types/payEquity";
import { ScaleValue, TextColor } from "../../types/scale";
import { formatToCurrency } from "../../utils/formatters";
import { graphColors } from "../../utils/graphs";
import { ViewOption } from "./PayEquityOverview";

export interface PayFilter {
  label: string;
  scaleValues: ScaleValue[];
  medianValue: ScaleValue;
}

export const generatePayFilters = (
  data: GetPayEquityResponse,
  t: (str: string) => string,
  viewOption: ViewOption
): PayFilter[] => {
  const medianValue = getOrgMedianSalaryScaleValue(data, t);

  const filters: PayFilter[] = [];
  const allFilterValues = getPayEquityScaleValues(
    data,
    medianValue.score,
    t,
    viewOption
  );

  const overviewFilter: PayFilter = {
    label: t("scale.filters.all.title"),
    scaleValues: allFilterValues,
    medianValue: medianValue,
  };

  filters.push(overviewFilter);

  data.filters.forEach((filter) => {
    filters.push({
      label: t(`scale.filters.${filter.label}.title`),
      scaleValues: transformPayGroupToScaleValue(
        filter.groups,
        medianValue.score,
        t,
        viewOption,
        data.currency
      ),
      medianValue: medianValue,
    });
  });

  return filters;
};

export const getPayEquityScaleValues = (
  payEquityData: GetPayEquityResponse,
  orgMedianSalary: number,
  t: (str: string) => string,
  viewOption: ViewOption
): ScaleValue[] => {
  const allPayGroups: PayEquityGroup[] = payEquityData.filters.flatMap(
    (salaryFilter) => salaryFilter.groups
  );

  const relativeValues = transformPayGroupToScaleValue(
    allPayGroups,
    orgMedianSalary,
    t,
    viewOption,
    payEquityData.currency
  );

  return relativeValues;
};

export const transformPayGroupToScaleValue = (
  payGroups: PayEquityGroup[],
  orgMedianSalary: number,
  t: (str: string) => string,
  viewOption: ViewOption,
  currency: string
): ScaleValue[] => {
  const relativeValues = payGroups?.map((payGroups) => {
    const percentDifference = Math.round(
      ((payGroups.median_salary - orgMedianSalary) / orgMedianSalary) * 100
    );

    let scoreLabel = "";

    if (viewOption === ViewOption.DEVIATION) {
      const percentSign = percentDifference > 0 ? "+" : "-";

      let devianceFromMedian = Math.round(
        payGroups.median_salary - orgMedianSalary
      );

      if (devianceFromMedian < 0) {
        devianceFromMedian = devianceFromMedian * -1;
      }

      const formattedDeviance = formatToCurrency(devianceFromMedian, currency);

      scoreLabel = `${percentSign} ${formattedDeviance}`;
    } else if (viewOption === ViewOption.PERCENT) {
      const percentSign = percentDifference > 0 ? "+" : "";

      scoreLabel = `${percentSign}${percentDifference}%`;
    } else {
      scoreLabel = formatToCurrency(payGroups.median_salary, currency);
    }

    return {
      label: payGroups.label,
      score: payGroups.median_salary,
      scoreLabel: scoreLabel,
    };
  });

  return relativeValues;
};

export const getOrgMedianSalaryScaleValue = (
  payEquityData: GetPayEquityResponse,
  t: (str: string) => string
): ScaleValue => {
  const formattedMean = formatToCurrency(
    payEquityData.org_median,
    payEquityData.currency
  );

  return {
    label: t("scale.filters.median.title"),
    score: payEquityData.org_median,
    scoreLabel: formattedMean,
    color: graphColors[2],
    colorText: TextColor.Dark,
  };
};
