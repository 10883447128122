import { AxiosInstance } from "axios";
import { UserSettingsLanguageUpdateBody } from "../types/userSettings";
import { getApiClient } from "../utils/api.utils";

export class UserSettingsService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  updateLanguage = async (body: UserSettingsLanguageUpdateBody) => {
    const response = await this.client.post(
      "/user-settings/language/update",
      body
    );

    return response.data;
  };
}
