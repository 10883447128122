import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { CompanyService } from "../api/CompanyService";
import { Company } from "../components/Company/Company";
import { Integration } from "../components/Integration/Integration";
import useTranslation from "../hooks/useTranslation";
import { CompanyResponse, ICompany } from "../types/company";
import "./SettingsPage.scss";

export const SettingsPage = () => {
  const { t } = useTranslation("settingsPage");
  const companyService = new CompanyService();
  const [company, setCompany] = useState<ICompany>();

  const {
    data: companyData,
    isLoading,
    error,
  } = useQuery<CompanyResponse, AxiosError>(["company"], () =>
    companyService.getCompany()
  );

  useEffect(() => {
    if (companyData) {
      setCompany({
        name: companyData.name,
        industry: companyData.industry,
        numberOfEmployees: companyData.number_of_employees,
      });
    }
  }, [companyData]);

  return (
    <div className="SettingsPage">
      <Typography variant="h5" className="SettingsPage__title">
        {t("title.company")}
      </Typography>
      <Company company={company} isLoading={isLoading} error={!!error} />
      <Typography variant="h5" className="SettingsPage__title">
        {t("title.integrations")}
      </Typography>

      <Integration />

      <Typography color="secondary" className="SettingsPage__version">
        {process.env.REACT_APP_RELEASE}
      </Typography>
    </div>
  );
};
