import { Typography } from "@mui/material";
import { SurveyQuestionResponseBreakdown } from "../../types/workplaceCulture";
import { Tab } from "../ui/Tabs/Tab";

interface QuestionBreakdownTabProps {
  isFirstInGroup: boolean;
  groupKey?: string;
  tabClasses: string;
  questionResponse: SurveyQuestionResponseBreakdown;
  selected: boolean;
  tabIndex: number;
  handleTabChange: (index: number) => void;
}

export const QuestionBreakdownTab = ({
  isFirstInGroup,
  groupKey,
  tabClasses,
  questionResponse,
  selected,
  tabIndex,
  handleTabChange,
}: QuestionBreakdownTabProps) => {
  return (
    <div className="QuestionBreakdown__breakdown__questions__tab">
      {isFirstInGroup && groupKey && (
        <Typography
          className="QuestionBreakdown__breakdown__questions__tab__groupTitle"
          color="secondary"
          variant="h6"
        >
          {groupKey}
        </Typography>
      )}
      <div className={tabClasses}>
        <Tab
          key={questionResponse.reference_id}
          selected={selected}
          label={questionResponse.text}
          index={tabIndex}
          onClick={() => handleTabChange(tabIndex)}
        />
      </div>
    </div>
  );
};
