import { PipelineResponse } from "../../types/pipeline";

export const pipelineData: PipelineResponse = {
  filters: [
    {
      label: "age",
      levels: [
        {
          label: "General employees",
          order: 4,
          count: 11,
          groups: [
            {
              label: "unknown",
              count: 1,
            },
            {
              label: "36-45",
              count: 4,
            },
            {
              label: "26-35",
              count: 4,
            },
            {
              label: "18-25",
              count: 2,
            },
          ],
        },
        {
          label: "Directors",
          order: 2,
          count: 5,
          groups: [
            {
              label: "26-35",
              count: 2,
            },
            {
              label: "46-55",
              count: 1,
            },
            {
              label: "18-25",
              count: 1,
            },
            {
              label: "36-45",
              count: 1,
            },
          ],
        },
        {
          label: "Managers",
          order: 3,
          count: 3,
          groups: [
            {
              label: "26-35",
              count: 1,
            },
            {
              label: "36-45",
              count: 1,
            },
            {
              label: ">65",
              count: 1,
            },
          ],
        },
        {
          label: "VP",
          order: 1,
          count: 5,
          groups: [
            {
              label: "26-35",
              count: 1,
            },
            {
              label: "36-45",
              count: 3,
            },
            {
              label: "46-55",
              count: 1,
            },
          ],
        },
        {
          label: "C-level",
          order: 0,
          count: 2,
          groups: [
            {
              label: "36-45",
              count: 1,
            },
            {
              label: "46-55",
              count: 1,
            },
          ],
        },
      ],
    },
    {
      label: "ethnicity",
      levels: [
        {
          label: "General employees",
          order: 4,
          count: 11,
          groups: [
            {
              label: "White",
              count: 7,
            },
            {
              label: "Hispanic or Latino",
              count: 1,
            },
            {
              label: "Black or African American",
              count: 2,
            },
            {
              label: "Native Hawaiian or Other Pacific Islander",
              count: 1,
            },
          ],
        },
        {
          label: "Directors",
          order: 2,
          count: 5,
          groups: [
            {
              label: "Black or African American",
              count: 1,
            },
            {
              label: "White",
              count: 2,
            },
            {
              label: "Hispanic or Latino",
              count: 1,
            },
            {
              label: "Two or More Races",
              count: 1,
            },
          ],
        },
        {
          label: "Managers",
          order: 3,
          count: 3,
          groups: [
            {
              label: "American Indian or Alaska Native",
              count: 1,
            },
            {
              label: "White",
              count: 1,
            },
            {
              label: "Two or More Races",
              count: 1,
            },
          ],
        },
        {
          label: "VP",
          order: 1,
          count: 5,
          groups: [
            {
              label: "White",
              count: 5,
            },
          ],
        },
        {
          label: "C-level",
          order: 0,
          count: 2,
          groups: [
            {
              label: "Asian",
              count: 2,
            },
          ],
        },
      ],
    },
    {
      label: "gender",
      levels: [
        {
          label: "General employees",
          order: 4,
          count: 11,
          groups: [
            {
              label: "unknown",
              count: 1,
            },
            {
              label: "Male",
              count: 8,
            },
            {
              label: "Female",
              count: 2,
            },
          ],
        },
        {
          label: "Directors",
          order: 2,
          count: 5,
          groups: [
            {
              label: "Male",
              count: 1,
            },
            {
              label: "Female",
              count: 4,
            },
          ],
        },
        {
          label: "Managers",
          order: 3,
          count: 3,
          groups: [
            {
              label: "Female",
              count: 1,
            },
            {
              label: "Male",
              count: 2,
            },
          ],
        },
        {
          label: "VP",
          order: 1,
          count: 5,
          groups: [
            {
              label: "Male",
              count: 4,
            },
            {
              label: "Female",
              count: 1,
            },
          ],
        },
        {
          label: "C-level",
          order: 0,
          count: 2,
          groups: [
            {
              label: "Female",
              count: 1,
            },
            {
              label: "Male",
              count: 1,
            },
          ],
        },
      ],
    },
  ],
};
