import { IllustrationProps } from "../../types/illustration";

export const Logo = ({
  className,
  width = 127,
  height = 55,
}: IllustrationProps) => {
  return (
    <svg
      version="1.1"
      baseProfile="tiny"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 283.46 113.39"
      overflow="visible"
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path
          fill="none"
          d="M204.43,47.41c-7.11,0-11.22,6.51-11.22,14.44c0,7.4,3.29,11.29,9.28,11.29c4.64,0,7.63-2.32,9.95-6.43V56.54
		C212.43,51.38,209.96,47.41,204.43,47.41z"
        />
        <path
          fill="none"
          d="M143.09,47.41c-7.03,0-11.14,6.43-11.14,14.59c0,7.18,3.14,11.15,9.13,11.15c4.64,0,7.63-2.32,9.95-6.43V56.54
		C151.02,51.38,148.63,47.41,143.09,47.41z"
        />
        <path
          fill="none"
          d="M248.11,47.41c-7.03,0-11.15,6.43-11.15,14.59c0,7.18,3.14,11.15,9.13,11.15c4.64,0,7.63-2.32,9.95-6.43V56.54
		C256.04,51.38,253.64,47.41,248.11,47.41z"
        />
        <path
          fill="#435869"
          d="M83.92,19.96c-20.72,0-61.64,35.23-74.95,53.18l6.88,6.96c12.42-16.98,51.01-50.64,72.48-53.11
		c0.75-1.42,1.2-2.69,1.2-3.74C89.53,21.16,87.74,19.96,83.92,19.96z"
        />
        <path
          fill="#435869"
          d="M212.5,42.7c-2.1-1.5-4.79-2.32-8.38-2.32c-12.87,0-19.75,10.4-19.75,21.77c0,11.97,7.26,17.88,14.96,17.88
		c7.03,0,10.92-4.86,12.79-11h0.3v10.55h8.45V24.9h-8.38V42.7z M212.43,66.71c-2.32,4.11-5.31,6.43-9.95,6.43
		c-5.98,0-9.28-3.89-9.28-11.29c0-7.93,4.11-14.44,11.22-14.44c5.54,0,8,3.96,8,9.12V66.71z"
        />
        <path
          fill="#435869"
          d="M247.74,40.38c-11.82,0-19.6,9.65-19.6,21.84c0,11.97,7.33,17.8,14.89,17.8c7.11,0,10.92-5.01,12.72-11h0.3
		v10.55h8.45V55.57C264.49,45.84,256.71,40.38,247.74,40.38z M256.04,66.71c-2.32,4.11-5.31,6.43-9.95,6.43
		c-5.98,0-9.13-3.96-9.13-11.15c0-8.15,4.11-14.59,11.15-14.59c5.54,0,7.93,3.96,7.93,9.12V66.71z"
        />
        <rect x="168.37" y="24.9" fill="#435869" width="8.53" height="54.68" />
        <path
          fill="#435869"
          d="M142.72,40.38c-11.82,0-19.6,9.65-19.6,21.84c0,11.97,7.33,17.8,14.88,17.8c7.11,0,10.92-5.01,12.72-11h0.3
		v10.55h8.45V55.57C159.47,45.84,151.69,40.38,142.72,40.38z M151.02,66.71c-2.32,4.11-5.31,6.43-9.95,6.43
		c-5.98,0-9.13-3.96-9.13-11.15c0-8.15,4.11-14.59,11.14-14.59c5.54,0,7.93,3.96,7.93,9.12V66.71z"
        />
        <path
          fill="#435869"
          d="M68.36,73.22v0.3c0,3.44,2.17,6.51,6.28,6.51c7.41,0,22.37-10.1,39.49-24.68l-6.88-6.96
		C90.73,62.6,76.29,72.55,68.36,73.22z"
        />
        <path
          fill="#435869"
          d="M90.73,26.84c-0.75,0-1.57,0.07-2.39,0.15c-5.31,10.1-26.85,28.72-26.85,39.79c0,3.44,2.09,6.43,6.06,6.43
		h0.82c0.52-12.49,28.05-34.86,28.05-43.09C96.41,28.04,94.54,26.84,90.73,26.84z"
        />
      </g>
    </svg>
  );
};
