import { Grid, Skeleton } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { RecommendationIllustration } from "../../styles/illustrations/RecommendationIllustration";
import { GetSurveyRecommendationResponse } from "../../types/workplaceCulture";
import { NoDataState } from "../NoDataState/NoDataState";
import { RecommendationLinkCard } from "../RecommendationLinkCard/RecommendationLinkCard";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";
import { Typography } from "../ui/Typography/Typography";
import { recommendationWidgetData } from "./RecommendationWidget.data";

interface PayEquityWidgetProps {
  recommendationsData?: GetSurveyRecommendationResponse;
  recommendationsDataLoading?: boolean;
  recommendationsDataError?: boolean;
  hasEnoughAnswers?: boolean;
  preview?: boolean;
}

export const RecommendationWidget = ({
  recommendationsData,
  recommendationsDataLoading: isLoading = true,
  recommendationsDataError: error = false,
  hasEnoughAnswers = false,
  preview = false,
}: PayEquityWidgetProps) => {
  const { t: tDashboard } = useTranslation("dashboardPage");
  const { t: tWorkplaceCulture } = useTranslation("workplaceCulturePage");

  let recommendations = undefined;
  if (recommendationsData) {
    recommendations = recommendationsData;
  } else if (preview) {
    recommendations = recommendationWidgetData;
  }

  return (
    <DashboardWidget
      title={tDashboard("recommendationsWidget.title")}
      icon={RecommendationIllustration}
      preview={preview}
    >
      {!hasEnoughAnswers && !preview && (
        <NoDataState
          title={tWorkplaceCulture("recommendations.notEnoughAnswers.title")}
          info={tWorkplaceCulture("recommendations.notEnoughAnswers.info")}
        />
      )}
      {((!error && hasEnoughAnswers) || preview) && (
        <Grid container spacing={3} marginBottom={3}>
          <Grid item md={12}>
            {recommendations?.low_scores &&
              recommendations?.low_scores.length > 0 && (
                <RecommendationLinkCard href="/workplace-culture">
                  <Typography tagVariant="p" desktop="label2" weight="regular">
                    {tWorkplaceCulture(
                      "recommendations.lowScores.infoOneLiner"
                    )}
                  </Typography>
                </RecommendationLinkCard>
              )}
            {isLoading && !preview && <Skeleton height={100} />}
          </Grid>

          <Grid item md={12}>
            {recommendations?.high_scores &&
              recommendations.high_scores.length > 0 && (
                <RecommendationLinkCard href="/workplace-culture">
                  <Typography tagVariant="p" desktop="label2" weight="regular">
                    {tWorkplaceCulture(
                      "recommendations.highScores.infoOneLiner"
                    )}
                  </Typography>
                </RecommendationLinkCard>
              )}
            {isLoading && !preview && <Skeleton height={100} />}
          </Grid>
        </Grid>
      )}
      {error && !preview && (
        <NoDataState
          title={tWorkplaceCulture("recommendations.error")}
          info={tWorkplaceCulture("recommendations.errorInfo")}
        />
      )}
    </DashboardWidget>
  );
};
