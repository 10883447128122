import { coalColor, roseOrange } from "../../../styles/theme";
import { IconProps } from "./";

export const UserIcon = ({ className, onClick }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={(event) => onClick?.(event)}
    >
      <path
        d="M5.25 21C5.32895 17.5385 6.67105 13.5 11.4079 13.5C16.1447 13.5 18.2763 16.7692 18.75 19.8462"
        stroke={coalColor}
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M15 6.10386C15 3.77527 11.8966 3 10.8621 3C9.82759 3 7.5 5.06738 7.5 6.8792C7.5 9.77353 10.0862 10.4974 11.3793 10.4975C12.7586 10.5835 15 8.43245 15 6.10386Z"
        fill={roseOrange}
        stroke={coalColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
