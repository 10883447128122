import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { TrainingService } from "../../api/TrainingService";
import useTranslation from "../../hooks/useTranslation";
import TrainingTouchpoint from "../../models/trainingTouchpoint";
import TouchpointCardPlaceholder from "../../styles/illustrations/TouchpointCardPlaceholder.png";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Modal } from "../ui/Modal/Modal";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./TouchpointCard.scss";

interface TouchpointCardProps {
  touchpoint: TrainingTouchpoint;
  order: number;
}

export const TouchpointCard = ({ touchpoint, order }: TouchpointCardProps) => {
  const { t } = useTranslation("trainingPage");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const trainingService = new TrainingService();

  const { error: downloadError, refetch } = useQuery<Blob, AxiosError>(
    ["trainingDownload"],
    () => trainingService.downloadTouchpoint(touchpoint.referenceId),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    }
  );

  return (
    <div>
      <Card className="TouchpointCard">
        <Grid container columnSpacing={1}>
          <Grid item xs={12} sm={7} className="TouchpointCard__content">
            <div>
              <Typography
                tagVariant="h2"
                desktop="h3"
                className="TouchpointCard__content__title"
              >
                {touchpoint.title}
              </Typography>
              <Typography
                tagVariant="p"
                desktop="body2"
                className="TouchpointCard__content__description"
              >
                {touchpoint.description}
              </Typography>
            </div>
            <div className="TouchpointCard__content__footer">
              <div className="TouchpointCard__content__footer__download">
                <Button
                  className="TouchpointCard__content__footer__download__button"
                  onClick={() => refetch()}
                  variant="contained"
                  color="secondary"
                  small={true}
                >
                  <Icon type={IconTypes.Download} color="secondary" />
                  {t("modules.downloadTouchpoint", { order })}
                </Button>
              </div>
            </div>
            {!!downloadError && (
              <Typography color="error" desktop="caption">
                {t("modules.downloadError")}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={5} className="TouchpointCard__thumbnail">
            <div className="TouchpointCard__thumbnail__wrapper">
              {touchpoint.thumbnail ? (
                <img
                  className="TouchpointCard__thumbnail__wrapper__img"
                  src={touchpoint.thumbnail}
                  alt={t("modules.preview")}
                  onClick={() => setModalOpen(true)}
                />
              ) : (
                <img
                  className="TouchpointCard__thumbnail__wrapper__img"
                  src={TouchpointCardPlaceholder}
                  alt={t("modules.preview")}
                />
              )}
              <div className="TouchpointCard__thumbnail__wrapper__overlay">
                <Button
                  className="TouchpointCard__thumbnail__wrapper__overlay__text"
                  onClick={() => setModalOpen(true)}
                  variant="contained"
                  color="primary"
                  small={true}
                >
                  <PlayCircleOutlinedIcon />
                  {t("modules.preview")}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>
      <Modal
        className="TouchpointCard__preview__modal"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={
          <Typography tagVariant="h3" desktop="h3" weight="bold">
            {touchpoint.title}
          </Typography>
        }
      >
        <iframe
          className="TouchpointCard__preview__modal__content__iframe"
          title={touchpoint.title}
          src={`https://learning.alda.co/${t(
            `modules.links.${touchpoint.referenceId.toLowerCase()}`
          )}`}
        ></iframe>
      </Modal>
    </div>
  );
};
