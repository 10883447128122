import { addDays, isAfter, isSameDay } from "date-fns";
import { SurveyDispatchReminder } from "../types/survey";

export const SURVEY_INVITE_TOKEN_KEY = "survey_invite_token"; // defined by the backend
const LOCAL_STORAGE_TOKEN_KEY = "survey_browser_fingerprint";

export const getLocalStorageTokenKey = (inviteToken: string | null) => {
  if (!inviteToken) {
    return "";
  }

  return `${LOCAL_STORAGE_TOKEN_KEY}_${inviteToken}`;
};

export const getBrowserFingerprint = (inviteToken: string | null) => {
  const tokenKey = getLocalStorageTokenKey(inviteToken);
  return localStorage.getItem(tokenKey);
};

export const setBrowserFingerprint = (inviteToken: string | null) => {
  const tokenKey = getLocalStorageTokenKey(inviteToken);
  if (!tokenKey) {
    return;
  }

  const fingerprint = generateBrowserFingerprint();
  localStorage.setItem(tokenKey, fingerprint);
};

export const hasSocialMediaReferrer = () => {
  const referrer = document.referrer;
  if (!referrer) {
    return false;
  }

  const socialMediaDomains = [
    "twitter.com",
    "x.com",
    "facebook.com",
    "instagram.com",
    "reddit.com",
    "linkedin.com",
    "pinterest.com",
    "t.co", // Twitter's URL shortener
  ];

  // Check if the referrer URL contains any of the social media domains
  return socialMediaDomains.some((domain) => referrer.includes(domain));
};

export const generateBrowserFingerprint = () => {
  const userAgent = navigator.userAgent;
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const colorDepth = window.screen.colorDepth;
  const timeZoneOffset = new Date().getTimezoneOffset();

  // Combine all the collected information and hash it
  const fingerprintString = JSON.stringify({
    userAgent,
    screenResolution,
    colorDepth,
    timeZoneOffset,
  });

  // hash the fingerprintString to make it anonymous
  const hashedFingerprint = hash(fingerprintString);

  return hashedFingerprint.toString();
};

const hash = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const sortReminders = (reminders: SurveyDispatchReminder[]) => {
  return reminders.sort((a, b) => {
    if (a.date && b.date) {
      return a.date.getTime() - b.date.getTime();
    }
    return 0;
  });
};

export const hasDuplicateReminders = (reminders: SurveyDispatchReminder[]) => {
  const uniqueDates = new Set(
    reminders.map((reminder) => reminder.date?.toDateString())
  );
  return uniqueDates.size !== reminders.length;
};

export const getReminderMinDate = (launchDate: Date | undefined) => {
  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0);

  // use the day after the launch date if it's in the future, otherwise use today
  return (launchDate && isSameDay(launchDate, today)) ||
    (launchDate && isAfter(launchDate, today))
    ? addDays(launchDate, 1)
    : today;
};
