import { FormControl, Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Fragment, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { DiversityRatioBreakdownFilter } from "../../types/diversityRatio";
import { Button } from "../ui/Button/Button";
import { Select } from "../ui/Select/Select";
import { Typography } from "../ui/Typography/Typography";
import { BoxIcon } from "../ui/icons/BoxIcon";
import { TrashIcon } from "../ui/icons/TrashIcon";
import {
  getDiversityBreakdownChartData,
  getThreeMostPopulatedGroups,
} from "./DiversityRatioBreakdown.utils";
import "./DiversityRatioBreakdownComparison.scss";

interface DiversityRatioBreakdownComparisonProps {
  breakdownLabel: string;
  breakdownFilters: DiversityRatioBreakdownFilter[];
  diversityBreakdownGroupColors?: { [key: string]: string };
}

export const DiversityRatioBreakdownComparison = ({
  breakdownLabel,
  breakdownFilters,
  diversityBreakdownGroupColors,
}: DiversityRatioBreakdownComparisonProps) => {
  const { t } = useTranslation("diversityRatioPage");
  const sortedBreakdownFilterLabels = breakdownFilters
    .map((filter) => filter.label)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const [shownFilters, setShownFilters] = useState<
    DiversityRatioBreakdownFilter[]
  >(getThreeMostPopulatedGroups(breakdownFilters));

  const addToComparison = (event: SelectChangeEvent<unknown>) => {
    const isAlreadyInComparison = shownFilters.findIndex(
      (filter) => filter.label === event.target.value
    );

    if (isAlreadyInComparison !== -1) return;

    const newComparisonFilter = breakdownFilters.find(
      (filter) => filter.label === event.target.value
    );

    // Only add to comparison if the filter is found and sort it by count
    if (newComparisonFilter) {
      setShownFilters(
        [...shownFilters, newComparisonFilter].sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        )
      );
    }
  };

  const handleRemoveComparison = (
    filterToRemove: DiversityRatioBreakdownFilter
  ) => {
    setShownFilters(
      shownFilters.filter((sFilter) => sFilter.label !== filterToRemove.label)
    );
  };

  const renderBreakdownChart = (filter: DiversityRatioBreakdownFilter) => {
    const chartData = getDiversityBreakdownChartData(
      filter.label,
      filter.groups,
      diversityBreakdownGroupColors
    );
    return (
      <Fragment key={filter.label}>
        <Grid
          item
          xs={11}
          className="DiversityRatioBreakdownComparison__chart"
          data-testid={`diversity-ratio-comparison-chart-${breakdownLabel}`}
        >
          <Typography
            className="DiversityRatioBreakdownComparison__chart__count"
            color="secondary"
            desktop="caption"
          >
            {t("breakdown.numberEmployees", { count: filter.count })}
          </Typography>
          <HighchartsReact highcharts={Highcharts} options={chartData} />
        </Grid>
        <Grid
          item
          xs={1}
          className="DiversityRatioBreakdownComparison__chart__action"
          data-testid={`diversity-ratio-comparison-remove-${filter.label.replaceAll(
            " ",
            ""
          )}`}
        >
          <Button
            variant="contained"
            color="primary"
            icon={true}
            onClick={() => handleRemoveComparison(filter)}
            aria-label={t("breakdown.removeComparison")}
            title={t("breakdown.removeComparison")}
          >
            <TrashIcon color="white" size={16} />
          </Button>
        </Grid>
      </Fragment>
    );
  };

  return (
    <Grid container spacing={1} className="DiversityRatioBreakdownComparison">
      <Grid item md={2} sm={12} xs={12}>
        <FormControl
          size="small"
          className="DiversityRatioBreakdownComparison__selector"
        >
          <Select
            labelId={`diversity-ratio-comparison-${breakdownLabel}`}
            id={`diversity-ratio-comparison-${breakdownLabel}`}
            value={" "}
            label={t(`breakdown.${breakdownLabel}.selectLabel`)}
            onChange={addToComparison}
            data-testid={`diversity-ratio-comparison-${breakdownLabel}`}
          >
            {sortedBreakdownFilterLabels.map((label, i) => (
              <MenuItem key={`filter${i}`} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        item
        md={10}
        sm={12}
        xs={12}
        className="DiversityRatioBreakdownComparison__legend"
      >
        {diversityBreakdownGroupColors &&
          shownFilters.length > 0 &&
          Object.keys(diversityBreakdownGroupColors).map((groupLabel) => {
            return (
              <div
                className="DiversityRatioBreakdownComparison__legend__item"
                key={groupLabel}
              >
                <BoxIcon color={diversityBreakdownGroupColors[groupLabel]} />
                <Typography desktop="caption">{groupLabel}</Typography>
              </div>
            );
          })}
      </Grid>
      {shownFilters.map((sFilter) => renderBreakdownChart(sFilter))}
    </Grid>
  );
};
