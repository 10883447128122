import { IconProps } from "./";

export const MessageIcon = ({ size = 32 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6 25.4667C13.1708 26.2601 17 28.0003 21.5 25.5003C26 23.0003 28 20.4 28 15.3333C28 10.2667 24.5555 4.00591 15 2.99965C5.5 1.99923 4.33333 12.3331 4.5 14.4997C4.66667 16.6663 5.7399 18.8292 6.53333 20.4C6.53333 20.4 3.49999 26 3.99999 28C4.49999 30 11.6 25.4667 11.6 25.4667Z"
        stroke="#25262C"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0948 9.31626C17.2696 8.79236 16.9866 8.22597 16.4627 8.05118C15.9388 7.8764 15.3724 8.15941 15.1976 8.68331C14.8808 9.63286 15.0152 10.7291 15.126 11.6332L15.1472 11.8067C15.2714 12.8372 15.3715 13.8099 15.1667 14.7966C15.0545 15.3373 15.4019 15.8667 15.9427 15.9789C16.4834 16.0911 17.0128 15.7438 17.125 15.203C17.4046 13.8558 17.2547 12.5784 17.1328 11.5674C16.9994 10.4605 16.9317 9.80506 17.0948 9.31626ZM16.146 18.1668C15.3176 18.1668 14.646 18.8383 14.646 19.6668C14.646 20.4952 15.3176 21.1668 16.146 21.1668H16.1593C16.9878 21.1668 17.6593 20.4952 17.6593 19.6668C17.6593 18.8383 16.9878 18.1668 16.1593 18.1668H16.146Z"
        fill="#25262C"
        fillOpacity="0.4"
      />
    </svg>
  );
};
