import { AxiosInstance } from "axios";
import {
  ILevel,
  JobTitleLevelsSubmitBody,
  JobTitlesResponse,
  LevelsResponse,
  PipelineResponse,
} from "../types/pipeline";
import { getApiClient } from "../utils/api.utils";

export class PipelineService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  getJobTitles = async () => {
    const response = await this.client.get<JobTitlesResponse>(
      "/pipeline/job-title-level/"
    );
    return response.data;
  };

  submitJobTitleLevels = async (body: JobTitleLevelsSubmitBody) => {
    const response = await this.client.post(
      "/pipeline/job-title-level/setup/",
      body
    );

    return response.data;
  };

  getPipeline = async () => {
    const response = await this.client.get<PipelineResponse>("/pipeline/");
    return response.data;
  };

  getLevels = async () => {
    const response = await this.client.get<LevelsResponse>("/pipeline/levels/");
    return response.data;
  };

  submitLevels = async (body: { levels: ILevel[] }) => {
    const response = await this.client.post("/pipeline/levels/setup/", body);

    return response.data;
  };
}
