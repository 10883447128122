import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import classNames from "classnames";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import "./EmptyState.scss";

export interface EmptyStateProps {
  title: string;
  href: string;
  actionInfo: string;
  info?: string;
  isWidget?: boolean;
}

export const EmptyState = ({
  title,
  href,
  actionInfo,
  isWidget = false,
  info,
}: EmptyStateProps) => {
  const classes = classNames("EmptyState", { "EmptyState--widget": isWidget });
  return (
    <div className={classes}>
      <div className="EmptyState__text">
        <Typography tagVariant="h1" desktop="h1">
          {title}
        </Typography>
        {info && (
          <Typography tagVariant="h3" desktop="body1">
            {info}
          </Typography>
        )}
      </div>

      <Button className="EmptyState__actions" href={href} variant="contained">
        <Typography tagVariant="h3" desktop="h3">
          {actionInfo}
        </Typography>
        {isWidget && <KeyboardArrowRightIcon color="primary" />}
      </Button>
    </div>
  );
};
