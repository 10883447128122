import { coalColor, roseOrange } from "../../styles/theme";

interface IllustrationProps {
  className?: string;
  size?: number;
}

export const DocumentIllustration = ({
  className,
  size = 64,
}: IllustrationProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.3093 48.1276L5.81554 17.3378C4.85296 13.8485 7.17594 10.3077 10.76 9.80123L31.4783 6.87365C34.8523 6.39688 37.9457 8.82231 38.2875 12.2126L41.4718 43.7899C41.7955 46.9998 39.523 49.891 36.3275 50.3349L20.9186 52.475C17.9369 52.8891 15.1098 51.0296 14.3093 48.1276Z"
        fill={roseOrange}
      />
      <path
        d="M23.047 53.2176L21.1716 20.5315C20.9913 17.389 23.27 14.6414 26.3917 14.2374L46.2979 11.6613C49.466 11.2513 52.3998 13.3954 52.9712 16.5384L58.8606 48.9301C59.4789 52.3306 57.1038 55.5489 53.6723 55.9607L29.752 58.8311C26.3165 59.2434 23.2452 56.6721 23.047 53.2176Z"
        fill="white"
        stroke={coalColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 24L46 22"
        stroke={coalColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 36L36 34"
        stroke={coalColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 46L44 44"
        stroke={coalColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
