import { Box, Slider } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  MAX_SCALE_VALUE,
  MIN_SCALE_VALUE,
  STEP_SIZE,
} from "../../utils/WorkplaceCulture.utils";
import { Button } from "../ui/Button/Button";
import { Modal } from "../ui/Modal/Modal";
import { Typography } from "../ui/Typography/Typography";
import "./WorkplaceCultureScaleSettings.scss";

type WorkplaceCultureScaleSettingsProps = {
  isOpen: boolean;
  onClose: () => void;
  value: [number, number];
  handleChange: (newValue: number[]) => void;
  onResetZoom: () => void;
};

export const WorkplaceCultureScaleSettings = ({
  isOpen,
  onClose,
  value,
  handleChange,
  onResetZoom,
}: WorkplaceCultureScaleSettingsProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const validateNumbers = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    let minValue = value[0];
    let maxValue = value[1];

    if (activeThumb === 0) {
      minValue = Math.min(newValue[0], value[1] - STEP_SIZE);
    } else {
      maxValue = Math.max(newValue[1], value[0] + STEP_SIZE);
    }

    handleChange([minValue, maxValue]);
  };

  return (
    <Modal open={isOpen} onClose={onClose} light>
      <div className="WorkplaceCultureScaleSettings">
        <Typography desktop="h3">
          {t("scale.actions.manualZoomTitle")}
        </Typography>
        <Box className="WorkplaceCultureScaleSettings__slider">
          <Slider
            getAriaLabel={() => "Scale score"}
            value={value}
            onChange={validateNumbers}
            valueLabelDisplay="on"
            min={MIN_SCALE_VALUE}
            max={MAX_SCALE_VALUE}
            step={STEP_SIZE}
            marks
            disableSwap
          />
          <Button onClick={onResetZoom} variant="contained" small>
            {t("scale.actions.resetZoom")}
          </Button>
        </Box>
      </div>
    </Modal>
  );
};
