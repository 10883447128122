import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useTranslation from "../../hooks/useTranslation";
import { DataFilter } from "../../types/filter";
import { SurveyDemographicGroup } from "../../types/workplaceCulture";
import { NoDataState } from "../NoDataState/NoDataState";
import { Card } from "../ui/Card/Card";
import { getDemographicChartData } from "./SurveyDemographics.utils";
import "./SurveyDemographics.scss";

interface SurveyDemographicsProps {
  demographics?: DataFilter<SurveyDemographicGroup>[];
  totalEngagement?: number;
  isLoading: boolean;
  error: boolean;
}

export const SurveyDemographics = ({
  demographics,
  totalEngagement = 0,
  isLoading,
  error,
}: SurveyDemographicsProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  return (
    <div className="SurveyDemographics">
      <Typography variant="handwrittenH1" color="error">
        {t("surveyDemographics.title")}
      </Typography>
      <Typography color="secondary">
        {isLoading
          ? t("surveyDemographics.loading")
          : t("surveyDemographics.info", { count: totalEngagement })}
      </Typography>
      <Card className="SurveyDemographics__card">
        {!isLoading && !demographics && !error && (
          <span>
            <div className="SurveyDemographics__card__info">
              <NoDataState light info={t("surveyDemographics.notEnoughData")} />
            </div>
          </span>
        )}

        {!isLoading &&
          demographics?.map((demographic, i) => {
            const chartData = getDemographicChartData(
              demographic.label,
              demographic.groups
            );

            if (!chartData.engagement) {
              return (
                <span key={demographic.label}>
                  {i !== 0 && <Divider />}
                  <div className="SurveyDemographics__card--empty">
                    <Typography sx={{ fontWeight: "bold" }}>
                      {demographic.label}
                    </Typography>
                    <div className="SurveyDemographics__card__info">
                      <NoDataState
                        light
                        info={t("surveyDemographics.notEnoughData")}
                      />
                    </div>
                  </div>
                </span>
              );
            }

            return (
              <span key={demographic.label}>
                {i !== 0 && <Divider />}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartData.options}
                />
              </span>
            );
          })}
        {isLoading && (
          <Grid container>
            <Grid item xs={4}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={6}>
              <Skeleton />
            </Grid>
          </Grid>
        )}
        {!demographics && error && (
          <div className="SurveyDemographics__card--empty">
            <Typography sx={{ fontWeight: "bold" }} color="error">
              {t("surveyDemographics.error")}
            </Typography>
          </div>
        )}
      </Card>
    </div>
  );
};
