import { GetSurveyRecommendationResponse } from "../../types/workplaceCulture";

export const recommendationWidgetData: GetSurveyRecommendationResponse = {
  low_scores: [
    "No Disclosure - Félag",
    "Vil ekki svara",
    "Vil ekki svara",
    "Vil ekki svara",
    "Hinsegin fólk",
    "Vil ekki svara",
    "Vil ekki svara",
    "Fatlað fólk",
    "Vil ekki svara",
    "Vil ekki svara",
    "Taugsegin einstaklingar",
  ],
  high_scores: ["Trans fólk", "Innflytjendur"],
  benchmark: true,
};
