import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { ActionPlanService } from "../api/ActionPlanService";
import { DiversityRatioService } from "../api/DiversityRatioService";
import { IntegrationService } from "../api/IntegrationService";
import { PipelineService } from "../api/PipelineService";
import { SurveyService } from "../api/SurveyService";
import { TrainingService } from "../api/TrainingService";
import { WorkplaceCultureService } from "../api/WorkplaceCultureService";
import { ActionPlanTodoWidget } from "../components/ActionPlanTodoWidget/ActionPlanTodoWidget";
import { ActionPlanWidget } from "../components/ActionPlanWidget/ActionPlanWidget";
import { BenchmarkingWidget } from "../components/BenchmarkingWidget/BenchmarkingWidget";
import { DiversityRatioWidget } from "../components/DiversityRatioWidget/DiversityRatioWidget";
import { PipelineWidget } from "../components/PipelineWidget/PipelineWidget";
import { RecommendationWidget } from "../components/RecommendationWidget/RecommendationWidget";
import { TrainingLinkCard } from "../components/TrainingLinkCard/TrainingLinkCard";
import { TrainingModuleCard } from "../components/TrainingModuleCard/TrainingModuleCard";
import { WorkplaceCultureWidget } from "../components/WorkplaceCultureWidget/WorkplaceCultureWidget";
import { Typography } from "../components/ui/Typography/Typography";
import { useAuth } from "../hooks/useAuth";
import useTranslation from "../hooks/useTranslation";
import SurveyDispatch from "../models/surveyDispatch";
import TrainingModule from "../models/trainingModule";
import { ActionPlanResponse } from "../types/actionPlan";
import { GetDiversityRatioResponse } from "../types/diversityRatio";
import { IntegrationResponse } from "../types/integration";
import { ErrorCodes, ErrorResponse, PipelineResponse } from "../types/pipeline";
import { GetSurveyDispatchResponse } from "../types/survey";
import { TrainingModuleResponse } from "../types/training";
import {
  GetBenchmarkingResponse,
  GetSurveyRecommendationResponse,
  GetSurveyScoringDemographicResponse,
  IBenchmarking,
} from "../types/workplaceCulture";
import "./DashboardPage.scss";

export const DashboardPage = () => {
  const { t } = useTranslation("dashboardPage");
  const { authInfo } = useAuth();

  const [surveyDispatch, setSurveyDispatch] = useState<SurveyDispatch>();
  const [errorCode, setErrorCode] = useState<string>("");
  const [trainingModules, setTrainingModules] = useState<TrainingModule[]>();
  const [benchmarking, setBenchmarking] = useState<IBenchmarking>();

  const actionPlanService = new ActionPlanService();
  const surveyService = new SurveyService();
  const workplaceCultureService = new WorkplaceCultureService();
  const diversityRatioService = new DiversityRatioService();
  const pipelineService = new PipelineService();
  const integrationService = new IntegrationService();
  const trainingService = new TrainingService();

  const { error: integrationError } = useQuery<IntegrationResponse, AxiosError>(
    ["integration"],
    () => integrationService.getIntegration()
  );

  const {
    data: actionPlanData,
    isLoading: actionPlanLoading,
    error: actionPlanError,
    refetch: handleActionPlanRefetch,
  } = useQuery<ActionPlanResponse, AxiosError>(["actionPlan"], () =>
    actionPlanService.getActionPlan()
  );

  const {
    data: benchmarkingData,
    isLoading: benchmarkingLoading,
    error: benchmarkingError,
  } = useQuery<GetBenchmarkingResponse, AxiosError>(
    ["benchmarking"],
    () => workplaceCultureService.getBenchmarking(),
    {
      enabled: process.env.REACT_APP_FEATURE_BENCHMARK === "true",
    }
  );

  useEffect(() => {
    if (benchmarkingData) {
      setBenchmarking({
        score: benchmarkingData.score,
      });
    }
  }, [benchmarkingData]);

  const {
    data: surveyDispatchData,
    isLoading: surveyStatusLoading,
    error: surveyStatusError,
    refetch: handleSurveyDispatchRefetch,
  } = useQuery<GetSurveyDispatchResponse, AxiosError>(["surveyDispatch"], () =>
    surveyService.getSurveyDispatch()
  );

  const {
    data: surveyScoringData,
    isLoading: surveyScoringLoading,
    error: surveyScoringError,
  } = useQuery<GetSurveyScoringDemographicResponse, AxiosError>(
    ["surveyScoring"],
    () =>
      workplaceCultureService.getSurveyScoring(surveyDispatchData?.id || ""),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const {
    data: surveyRecommendationData,
    isLoading: surveyRecommendationLoading,
    error: surveyRecommendationError,
  } = useQuery<GetSurveyRecommendationResponse, AxiosError>(
    ["surveyRecommendation"],
    () =>
      workplaceCultureService.getRecommendations(surveyDispatchData?.id || ""),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );
  const {
    data: diversityRatioData,
    isLoading: diversityRatioLoading,
    error: diversityRatioError,
  } = useQuery<GetDiversityRatioResponse, AxiosError>(
    ["diversityRatio"],
    () => diversityRatioService.getDiversityRatio(),
    { cacheTime: 60 * 60 * 1000 } // increase cache time to 60 minutes
  );

  const {
    data: pipelineData,
    isLoading: pipelineDataLoading,
    error: pipelineDataError,
  } = useQuery<PipelineResponse, AxiosError>(["pipeline"], () =>
    pipelineService.getPipeline()
  );

  const { data: trainingModulesData, error: trainingModulesError } = useQuery<
    TrainingModuleResponse[],
    AxiosError
  >(["trainingModules"], () => trainingService.getTrainingModules(), {
    enabled: true,
  });

  useEffect(() => {
    const errorData = pipelineDataError?.response?.data as ErrorResponse;
    setErrorCode(errorData?.extra?.code);
  }, [pipelineDataError]);

  useEffect(() => {
    if (surveyDispatchData) {
      setSurveyDispatch(new SurveyDispatch(surveyDispatchData));
    }
  }, [surveyDispatchData]);

  useEffect(() => {
    if (trainingModulesData) {
      const trainingModulesFromResponse = trainingModulesData.map(
        (module) => new TrainingModule(module)
      );

      setTrainingModules(trainingModulesFromResponse);
    }
  }, [trainingModulesData]);

  useEffect(() => {
    if (trainingModulesError) {
      setTrainingModules([]);
    }
  }, [trainingModulesError]);

  return (
    <div className="DashboardPage">
      <Typography
        className="DashboardPage__title"
        tagVariant="h1"
        desktop="h1"
        data-testid="dashboard-title"
      >
        {t("title")}
        <Typography tagVariant="span" desktop="handwrittenH1" color="error">
          {authInfo.user?.companyName}?
        </Typography>
      </Typography>
      <Grid container spacing={3} marginBottom={3}>
        {process.env.REACT_APP_FEATURE_ACTION_PLAN === "true" && (
          <Grid
            item
            md={process.env.REACT_APP_FEATURE_TODO === "true" ? 8 : 12}
            className="DashboardPage__widget"
          >
            <ActionPlanWidget
              surveyDispatch={surveyDispatch}
              eventsData={actionPlanData?.events}
              error={!!actionPlanError}
              isLoading={actionPlanLoading}
              onActionPlanUpdate={handleActionPlanRefetch}
            />
          </Grid>
        )}
        {process.env.REACT_APP_FEATURE_TODO === "true" && (
          <Grid item md={4} className="DashboardPage__widget">
            <ActionPlanTodoWidget />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} className="DashboardPage__widget">
          <WorkplaceCultureWidget
            surveyDispatch={surveyDispatch}
            surveyDispatchNoSurvey={surveyStatusError?.response?.status === 404}
            surveyDispatchError={
              !!surveyStatusError && surveyStatusError.response?.status !== 404
            }
            surveyDispatchLoading={surveyStatusLoading}
            onSurveyUpdate={handleSurveyDispatchRefetch}
            scoringHasEnoughAnswers={
              surveyScoringData?.scores.total_score !== null
            }
            scoringData={surveyScoringData}
            scoringError={!!surveyScoringError}
            scoringIsLoading={surveyScoringLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="DashboardPage__widget">
          <BenchmarkingWidget
            benchmarking={benchmarking}
            benchmarkingError={!!benchmarkingError}
            benchmarkingLoading={
              process.env.REACT_APP_FEATURE_BENCHMARK === "true"
                ? benchmarkingLoading
                : false
            }
            inclusionScore={surveyScoringData?.scores.total_score}
            scoreError={!!surveyScoringError}
            scoreLoading={
              surveyDispatch ? surveyScoringLoading : surveyStatusLoading
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} className="DashboardPage__widget">
          <DiversityRatioWidget
            diversityData={diversityRatioData}
            diversityIsLoading={diversityRatioLoading}
            diversityError={!!diversityRatioError}
            noIntegration={integrationError?.response?.status === 404}
            preview={integrationError?.response?.status === 403}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="DashboardPage__widget">
          <PipelineWidget
            pipelineData={pipelineData}
            pipelineDataLoading={pipelineDataLoading}
            pipelineDataError={!!pipelineDataError}
            noIntegration={integrationError?.response?.status === 404}
            noJobLevel={errorCode === ErrorCodes.NoJobLevel}
            preview={integrationError?.response?.status === 403}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} className="DashboardPage__widget">
          <RecommendationWidget
            recommendationsData={surveyRecommendationData}
            recommendationsDataLoading={surveyRecommendationLoading}
            recommendationsDataError={!!surveyRecommendationError}
            hasEnoughAnswers={!!surveyScoringData?.scores?.total_score}
            preview={integrationError?.response?.status === 403}
          />
        </Grid>
      </Grid>
      <div className="DashboardPage__title">
        <Typography tagVariant="h2" desktop="h1">
          {t("training.title", { companyName: authInfo.user?.companyName })}
        </Typography>
        <Typography tagVariant="p" desktop="body1" color="secondary">
          {t("training.info")}
        </Typography>
      </div>
      <Grid container spacing={3}>
        {trainingModules
          ?.filter((trainingModule) => trainingModule.isReady)
          .slice(0, 4)
          .map((trainingModule, i) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={12 / 5}
                key={`${trainingModule.title}-${i}`}
              >
                <TrainingModuleCard
                  trainingModule={trainingModule}
                  isActive={trainingModule.isReady}
                />
              </Grid>
            );
          })}
        <Grid item xs={12} sm={6} md={12 / 5} style={{ zIndex: 1 }}>
          <TrainingLinkCard />
        </Grid>
      </Grid>
    </div>
  );
};
