import { GetDiversityRatioResponse } from "../../types/diversityRatio";

export const diversityRatioResponse: GetDiversityRatioResponse = {
  employee_count: 89,
  filters: [
    {
      label: "age",
      groups: [
        {
          label: "<18",
          count: 6,
        },
        {
          label: "26-35",
          count: 53,
        },
        {
          label: "36-45",
          count: 9,
        },
        {
          label: "46-55",
          count: 8,
        },
        {
          label: ">65",
          count: 8,
        },
        {
          label: "unknown",
          count: 5,
        },
      ],
      breakdowns: [
        {
          label: "location",
          filters: [
            {
              label: "London, UK",
              count: 13,
              groups: [
                {
                  label: "<18",
                  count: 1,
                },
                {
                  label: "26-35",
                  count: 9,
                },
                {
                  label: "36-45",
                  count: 3,
                },
                {
                  label: "46-55",
                  count: 0,
                },
                {
                  label: ">65",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Vancouver, Canada",
              count: 7,
              groups: [
                {
                  label: "<18",
                  count: 1,
                },
                {
                  label: "26-35",
                  count: 5,
                },
                {
                  label: "36-45",
                  count: 1,
                },
                {
                  label: "46-55",
                  count: 0,
                },
                {
                  label: ">65",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Sydney, Australia",
              count: 9,
              groups: [
                {
                  label: "<18",
                  count: 0,
                },
                {
                  label: "26-35",
                  count: 5,
                },
                {
                  label: "36-45",
                  count: 1,
                },
                {
                  label: "46-55",
                  count: 1,
                },
                {
                  label: ">65",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 1,
                },
              ],
            },
            {
              label: "Lindon, Utah",
              count: 58,
              groups: [
                {
                  label: "<18",
                  count: 6,
                },
                {
                  label: "26-35",
                  count: 18,
                },
                {
                  label: "36-45",
                  count: 19,
                },
                {
                  label: "46-55",
                  count: 6,
                },
                {
                  label: ">65",
                  count: 6,
                },
                {
                  label: "unknown",
                  count: 3,
                },
              ],
            },
            {
              label: "San Francisco, California",
              count: 138,
              groups: [
                {
                  label: "<18",
                  count: 16,
                },
                {
                  label: "26-35",
                  count: 18,
                },
                {
                  label: "36-45",
                  count: 129,
                },
                {
                  label: "46-55",
                  count: 124,
                },
                {
                  label: ">65",
                  count: 6,
                },
                {
                  label: "unknown",
                  count: 3,
                },
              ],
            },
            {
              label: "Denver, Colorado",
              count: 158,
              groups: [
                {
                  label: "<18",
                  count: 6,
                },
                {
                  label: "26-35",
                  count: 118,
                },
                {
                  label: "36-45",
                  count: 19,
                },
                {
                  label: "46-55",
                  count: 6,
                },
                {
                  label: ">65",
                  count: 6,
                },
                {
                  label: "unknown",
                  count: 3,
                },
              ],
            },
            {
              label: "Remote Worker",
              count: 1,
              groups: [
                {
                  label: "<18",
                  count: 0,
                },
                {
                  label: "26-35",
                  count: 0,
                },
                {
                  label: "36-45",
                  count: 0,
                },
                {
                  label: "46-55",
                  count: 1,
                },
                {
                  label: ">65",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
          ],
        },
        {
          label: "team",
          filters: [
            {
              label: "Finance",
              count: 4,
              groups: [
                {
                  label: "<18",
                  count: 1,
                },
                {
                  label: "26-35",
                  count: 2,
                },
                {
                  label: "36-45",
                  count: 1,
                },
                {
                  label: "46-55",
                  count: 0,
                },
                {
                  label: ">65",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Sales",
              count: 21,
              groups: [
                {
                  label: "<18",
                  count: 1,
                },
                {
                  label: "26-35",
                  count: 7,
                },
                {
                  label: "36-45",
                  count: 10,
                },
                {
                  label: "46-55",
                  count: 0,
                },
                {
                  label: ">65",
                  count: 3,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Operations",
              count: 7,
              groups: [
                {
                  label: "<18",
                  count: 0,
                },
                {
                  label: "26-35",
                  count: 2,
                },
                {
                  label: "36-45",
                  count: 2,
                },
                {
                  label: "46-55",
                  count: 2,
                },
                {
                  label: ">65",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Human Resources",
              count: 15,
              groups: [
                {
                  label: "<18",
                  count: 1,
                },
                {
                  label: "26-35",
                  count: 10,
                },
                {
                  label: "36-45",
                  count: 1,
                },
                {
                  label: "46-55",
                  count: 1,
                },
                {
                  label: ">65",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 2,
                },
              ],
            },
            {
              label: "IT",
              count: 5,
              groups: [
                {
                  label: "<18",
                  count: 0,
                },
                {
                  label: "26-35",
                  count: 2,
                },
                {
                  label: "36-45",
                  count: 1,
                },
                {
                  label: "46-55",
                  count: 2,
                },
                {
                  label: ">65",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Marketing",
              count: 6,
              groups: [
                {
                  label: "<18",
                  count: 1,
                },
                {
                  label: "26-35",
                  count: 4,
                },
                {
                  label: "36-45",
                  count: 0,
                },
                {
                  label: "46-55",
                  count: 1,
                },
                {
                  label: ">65",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Product",
              count: 17,
              groups: [
                {
                  label: "<18",
                  count: 0,
                },
                {
                  label: "26-35",
                  count: 5,
                },
                {
                  label: "36-45",
                  count: 6,
                },
                {
                  label: "46-55",
                  count: 2,
                },
                {
                  label: ">65",
                  count: 2,
                },
                {
                  label: "unknown",
                  count: 2,
                },
              ],
            },
            {
              label: "Customer Success",
              count: 13,
              groups: [
                {
                  label: "<18",
                  count: 4,
                },
                {
                  label: "26-35",
                  count: 5,
                },
                {
                  label: "36-45",
                  count: 3,
                },
                {
                  label: "46-55",
                  count: 0,
                },
                {
                  label: ">65",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "ethnicity",
      groups: [
        {
          label: "white",
          count: 35,
        },
        {
          label: "hispanic",
          count: 30,
        },
        {
          label: "black",
          count: 12,
        },
        {
          label: "asian",
          count: 3,
        },
        {
          label: "other",
          count: 7,
        },
        {
          label: "unknown",
          count: 2,
        },
      ],
      breakdowns: [
        {
          label: "location",
          filters: [
            {
              label: "London, UK",
              count: 13,
              groups: [
                {
                  label: "white",
                  count: 7,
                },
                {
                  label: "hispanic",
                  count: 2,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 2,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Remote Worker",
              count: 1,
              groups: [
                {
                  label: "white",
                  count: 0,
                },
                {
                  label: "hispanic",
                  count: 0,
                },
                {
                  label: "black",
                  count: 1,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Vancouver, Canada",
              count: 7,
              groups: [
                {
                  label: "white",
                  count: 5,
                },
                {
                  label: "hispanic",
                  count: 0,
                },
                {
                  label: "black",
                  count: 0,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Sydney, Australia",
              count: 9,
              groups: [
                {
                  label: "white",
                  count: 3,
                },
                {
                  label: "hispanic",
                  count: 2,
                },
                {
                  label: "black",
                  count: 1,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 2,
                },
              ],
            },
            {
              label: "Lindon, Utah",
              count: 58,
              groups: [
                {
                  label: "white",
                  count: 29,
                },
                {
                  label: "hispanic",
                  count: 9,
                },
                {
                  label: "black",
                  count: 8,
                },
                {
                  label: "asian",
                  count: 4,
                },
                {
                  label: "other",
                  count: 6,
                },
                {
                  label: "unknown",
                  count: 2,
                },
              ],
            },
          ],
        },
        {
          label: "team",
          filters: [
            {
              label: "Finance",
              count: 4,
              groups: [
                {
                  label: "white",
                  count: 2,
                },
                {
                  label: "hispanic",
                  count: 0,
                },
                {
                  label: "black",
                  count: 0,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Sales",
              count: 21,
              groups: [
                {
                  label: "white",
                  count: 11,
                },
                {
                  label: "hispanic",
                  count: 4,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 2,
                },
                {
                  label: "unknown",
                  count: 1,
                },
              ],
            },
            {
              label: "Operations",
              count: 7,
              groups: [
                {
                  label: "white",
                  count: 4,
                },
                {
                  label: "hispanic",
                  count: 0,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Human Resources",
              count: 15,
              groups: [
                {
                  label: "white",
                  count: 9,
                },
                {
                  label: "hispanic",
                  count: 1,
                },
                {
                  label: "black",
                  count: 0,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 2,
                },
                {
                  label: "unknown",
                  count: 2,
                },
              ],
            },
            {
              label: "IT",
              count: 5,
              groups: [
                {
                  label: "white",
                  count: 2,
                },
                {
                  label: "hispanic",
                  count: 1,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Marketing",
              count: 6,
              groups: [
                {
                  label: "white",
                  count: 1,
                },
                {
                  label: "hispanic",
                  count: 3,
                },
                {
                  label: "black",
                  count: 1,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Product",
              count: 17,
              groups: [
                {
                  label: "white",
                  count: 7,
                },
                {
                  label: "hispanic",
                  count: 3,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 3,
                },
                {
                  label: "unknown",
                  count: 1,
                },
              ],
            },
            {
              label: "Customer Success",
              count: 13,
              groups: [
                {
                  label: "white",
                  count: 8,
                },
                {
                  label: "hispanic",
                  count: 1,
                },
                {
                  label: "black",
                  count: 3,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "gender",
      groups: [
        {
          label: "female",
          count: 32,
        },
        {
          label: "male",
          count: 49,
        },
        {
          label: "non-binary",
          count: 0,
        },
        {
          label: "unknown",
          count: 8,
        },
      ],
      breakdowns: [
        {
          label: "location",
          filters: [
            {
              label: "London, UK",
              count: 13,
              groups: [
                {
                  label: "white",
                  count: 7,
                },
                {
                  label: "hispanic",
                  count: 2,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 2,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Remote Worker",
              count: 1,
              groups: [
                {
                  label: "white",
                  count: 0,
                },
                {
                  label: "hispanic",
                  count: 0,
                },
                {
                  label: "black",
                  count: 1,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Vancouver, Canada",
              count: 7,
              groups: [
                {
                  label: "white",
                  count: 5,
                },
                {
                  label: "hispanic",
                  count: 0,
                },
                {
                  label: "black",
                  count: 0,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Sydney, Australia",
              count: 9,
              groups: [
                {
                  label: "white",
                  count: 3,
                },
                {
                  label: "hispanic",
                  count: 2,
                },
                {
                  label: "black",
                  count: 1,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 2,
                },
              ],
            },
            {
              label: "Lindon, Utah",
              count: 58,
              groups: [
                {
                  label: "white",
                  count: 29,
                },
                {
                  label: "hispanic",
                  count: 9,
                },
                {
                  label: "black",
                  count: 8,
                },
                {
                  label: "asian",
                  count: 4,
                },
                {
                  label: "other",
                  count: 6,
                },
                {
                  label: "unknown",
                  count: 2,
                },
              ],
            },
          ],
        },
        {
          label: "team",
          filters: [
            {
              label: "Finance",
              count: 4,
              groups: [
                {
                  label: "white",
                  count: 2,
                },
                {
                  label: "hispanic",
                  count: 0,
                },
                {
                  label: "black",
                  count: 0,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Sales",
              count: 21,
              groups: [
                {
                  label: "white",
                  count: 11,
                },
                {
                  label: "hispanic",
                  count: 4,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 2,
                },
                {
                  label: "unknown",
                  count: 1,
                },
              ],
            },
            {
              label: "Operations",
              count: 7,
              groups: [
                {
                  label: "white",
                  count: 4,
                },
                {
                  label: "hispanic",
                  count: 0,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Human Resources",
              count: 15,
              groups: [
                {
                  label: "white",
                  count: 9,
                },
                {
                  label: "hispanic",
                  count: 1,
                },
                {
                  label: "black",
                  count: 0,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 2,
                },
                {
                  label: "unknown",
                  count: 2,
                },
              ],
            },
            {
              label: "IT",
              count: 5,
              groups: [
                {
                  label: "white",
                  count: 2,
                },
                {
                  label: "hispanic",
                  count: 1,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 0,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Marketing",
              count: 6,
              groups: [
                {
                  label: "white",
                  count: 1,
                },
                {
                  label: "hispanic",
                  count: 3,
                },
                {
                  label: "black",
                  count: 1,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
            {
              label: "Product",
              count: 17,
              groups: [
                {
                  label: "white",
                  count: 7,
                },
                {
                  label: "hispanic",
                  count: 3,
                },
                {
                  label: "black",
                  count: 2,
                },
                {
                  label: "asian",
                  count: 1,
                },
                {
                  label: "other",
                  count: 3,
                },
                {
                  label: "unknown",
                  count: 1,
                },
              ],
            },
            {
              label: "Customer Success",
              count: 13,
              groups: [
                {
                  label: "white",
                  count: 8,
                },
                {
                  label: "hispanic",
                  count: 1,
                },
                {
                  label: "black",
                  count: 3,
                },
                {
                  label: "asian",
                  count: 0,
                },
                {
                  label: "other",
                  count: 1,
                },
                {
                  label: "unknown",
                  count: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
