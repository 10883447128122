import { IconProps } from "./";

export const PersonStandingIcon = ({
  className,
  width = 28,
  height = 32,
  onClick,
  color,
  onMouseEnter,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      onMouseEnter={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
        onMouseEnter?.(event)
      }
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 11.4996C15.933 11.4996 17.5 10.3695 17.5 8.43411C17.5 6.49874 16.625 4.92902 14 4.49098C11.375 4.05294 10.5 6.05991 10.5 7.99528C10.5 9.93065 12.067 11.4996 14 11.4996ZM15.4038 13.2587C12.9894 12.5689 10.4826 14.0025 9.92261 16.4503C8.98966 20.5285 7.88004 26.0706 8.5 27.0005C9.5 28.5005 17.5003 30.0005 19.0003 28.0005C20.05 26.6009 19.1408 20.3044 18.5002 16.6499C18.2136 15.0146 17.0002 13.7148 15.4038 13.2587Z"
        fill={color}
      />
    </svg>
  );
};
