import { OutlinedInputProps, TextField } from "@mui/material";
import classNames from "classnames";
import { ChangeEventHandler, RefObject } from "react";
import "./Input.scss";

export interface IInputProps {
  className?: string;
  sx?: any;
  type?: string | undefined;
  id?: string | undefined;
  ref?: RefObject<HTMLInputElement>;
  autoComplete?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: (ev: { key: string; preventDefault: () => void }) => void;
  onPaste?: any;
  onBlur?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string | undefined;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  htmlFor?: string | undefined;
  InputProps?: Partial<OutlinedInputProps> | undefined;
  error?: boolean;
  helperText?: string;
  multiple?: boolean;
  placeholder?: string;
  multiline?: boolean;
  fullWidth?: boolean;
}

export const Input = ({ className, InputProps, ...props }: IInputProps) => {
  const classes = classNames("Input", className);

  return (
    <TextField
      className={classes}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      InputProps={InputProps}
      {...props}
    />
  );
};
