import { Divider, Skeleton } from "@mui/material";
import classnames from "classnames";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Fragment, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { QuestionType } from "../../types/question";
import {
  SurveyQuestionAnswerGroup,
  SurveyQuestionResponseBreakdown,
} from "../../types/workplaceCulture";
import { NoDataState } from "../NoDataState/NoDataState";
import { Button } from "../ui/Button/Button";
import { TabPanel } from "../ui/Tabs/TabPanel";
import { Tabs } from "../ui/Tabs/Tabs";
import { Typography } from "../ui/Typography/Typography";
import {
  doesQuestionHaveAnswers,
  getGroupedResponses,
  getQuestionResponseChartData,
  showNoData,
  updateFilters,
} from "./QuestionBreakdown.utils";
import { QuestionBreakdownTab } from "./QuestionBreakdownTab";
import "./QuestionBreakdown.scss";

interface QuestionBreakdownProps {
  chapterQuestionResponses: SurveyQuestionResponseBreakdown[];
  totalEngagement: number;
  isLoading: boolean;
  error: boolean;
}
export const QuestionBreakdown = ({
  chapterQuestionResponses,
  totalEngagement,
  isLoading = true,
  error = false,
}: QuestionBreakdownProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const groupedResponses: {
    [key: string]: SurveyQuestionResponseBreakdown[];
  } = getGroupedResponses(chapterQuestionResponses);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState<number[]>([]);

  const handleTabChange = (newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const renderTabs = () => {
    let tabCount = 0; //Needed because tabs are grouped, but we want to count them linearly
    return (
      <Tabs
        selectedTabIndex={selectedTabIndex}
        label="workplace-culture-chapter-question-breakdown"
        orientation="vertical"
        className={"QuestionBreakdown__breakdown__questions__tabs"}
        variant="standard"
        scrollButtons={false}
        allowScrollButtonsMobile={false}
      >
        {Object.keys(groupedResponses).map((groupKey) => {
          const questionResponses = groupedResponses[groupKey];

          const tabClasses = classnames(
            "QuestionBreakdown__breakdown__questions__tab",
            {
              "QuestionBreakdown__breakdown__questions__tab--grouped":
                !!groupKey,
            }
          );

          return questionResponses.map((questionResponse, i) => {
            const currentTabCount = tabCount;
            tabCount = tabCount + 1;
            return (
              <QuestionBreakdownTab
                isFirstInGroup={i === 0}
                groupKey={groupKey}
                tabClasses={tabClasses}
                questionResponse={questionResponse}
                selected={selectedTabIndex === currentTabCount}
                tabIndex={currentTabCount}
                handleTabChange={handleTabChange}
              />
            );
          });
        })}
      </Tabs>
    );
  };

  const noData = (group: SurveyQuestionAnswerGroup) => {
    return (
      <div className="QuestionBreakdown__breakdown__noData">
        <Typography
          className="QuestionBreakdown__breakdown__noData__title"
          desktop="body2"
          weight="bold"
        >
          {group.label}
        </Typography>
        <NoDataState
          className="QuestionBreakdown__breakdown__noData__info"
          info={t("surveyDemographics.notEnoughData")}
          light
        />
      </div>
    );
  };

  const renderTabContent = () => {
    let tabCount = 0;
    const filterClickHandler = (index: number) => {
      setSelectedFilters(updateFilters(selectedFilters, index));
    };
    return Object.keys(groupedResponses).map((groupKey) => {
      const questionResponses = groupedResponses[groupKey];

      return questionResponses.map((questionResponse) => {
        const renderDemographicTabs = () => {
          return questionResponse.filters.map((filter, i) => (
            <Button
              key={`${filter.label}-${i}`}
              className="QuestionBreakdown__breakdown__answers__filters__button"
              onClick={() => filterClickHandler(i)}
              color="secondary"
              small
              variant={selectedFilters.includes(i) ? "contained" : "outlined"}
            >
              {filter.label}
            </Button>
          ));
        };
        const renderBreakdownChart = () => {
          return selectedFilters.map((filterIndex) => {
            const filter = questionResponse.filters[filterIndex];
            return filter.groups?.map((group, i) => {
              const demographicHasAnswer = group.engagement !== 0;
              if (demographicHasAnswer) {
                const demographicsChartData = getQuestionResponseChartData(
                  questionResponse.kind === QuestionType.MultiSelect,
                  totalEngagement,
                  false,
                  undefined,
                  group,
                  undefined
                );
                return (
                  <Fragment key={`${filter.label}-${group.label}-${i}`}>
                    {showNoData(
                      group,
                      questionResponse.kind,
                      demographicsChartData
                    ) ? (
                      noData(group)
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={demographicsChartData}
                      />
                    )}
                  </Fragment>
                );
              } else {
                return noData(group);
              }
            });
          });
        };
        const chartData = getQuestionResponseChartData(
          questionResponse.kind === QuestionType.MultiSelect,
          totalEngagement,
          true,
          questionResponse,
          undefined,
          t("chapterPage.breakdown.answers.chartTitle")
        );

        const questionHasAnswers = doesQuestionHaveAnswers(questionResponse);

        const currentTabCount = tabCount;
        tabCount = tabCount + 1;

        return (
          <TabPanel
            selectedTabIndex={selectedTabIndex}
            index={currentTabCount}
            key={`chapter-question-breakdown-${groupKey}-${currentTabCount}`}
            label="workplace-culture-chapter-question-breakdown-tab"
          >
            <Typography
              desktop="h3"
              className="QuestionBreakdown__breakdown__answers__title"
            >
              {t("chapterPage.breakdown.answers.title")}
            </Typography>
            <div className="QuestionBreakdown__breakdown__answers__filters">
              {renderDemographicTabs()}
            </div>
            {questionHasAnswers && (
              <>
                <HighchartsReact highcharts={Highcharts} options={chartData} />
                {renderBreakdownChart()}
              </>
            )}
            {!questionHasAnswers && (
              <NoDataState
                className="QuestionBreakdown__breakdown__answers__noAnswer"
                info={t("chapterPage.breakdown.answers.noAnswers")}
                light
              />
            )}
          </TabPanel>
        );
      });
    });
  };

  return (
    <div className="QuestionBreakdown">
      <Typography desktop="handwrittenH1" color="error">
        {t("chapterPage.breakdown.title")}
      </Typography>
      <Typography
        desktop="body1"
        className="QuestionBreakdown__info"
        color="secondary"
      >
        {t("chapterPage.breakdown.info")}
      </Typography>
      {!error && (
        <div className="QuestionBreakdown__breakdown">
          <div className="QuestionBreakdown__breakdown__questions">
            <Typography desktop="h2">
              {t("chapterPage.breakdown.questions.title")}
            </Typography>
            <Divider className="QuestionBreakdown__breakdown__questions__divider" />
            {isLoading && <Skeleton sx={{ mr: 4 }} />}
            {renderTabs()}
          </div>
          <div className="QuestionBreakdown__breakdown__answers">
            {isLoading && <Skeleton sx={{ mt: 5.5, mr: 4 }} />}
            {renderTabContent()}
          </div>
        </div>
      )}
      {error && (
        <Typography desktop="h3">{t("chapterPage.breakdown.error")}</Typography>
      )}
    </div>
  );
};
