import { MappedOption, OptionMapping } from "../../types/survey";

export const isOptionsEmpty = (list?: OptionMapping[]) => {
  return list?.some(
    (role) =>
      role.options.length === 0 ||
      (role.options.length === 1 && role.options.some((option) => !option.text))
  );
};

export const isDuplicateOptions = (list?: OptionMapping[]) => {
  const optionTexts: string[] = [];
  return list?.some((role) =>
    role.options.some((option) => {
      if (option.text && optionTexts.includes(option.text)) {
        return true;
      }
      optionTexts.push(option.text);
      return false;
    })
  );
};
export const transformOptionMappings = (optionMapping: OptionMapping[]) => {
  const transformedOptions: MappedOption[] = [];
  optionMapping.forEach((role) => {
    role.options.forEach(
      (option) =>
        option.text &&
        transformedOptions.push({
          mapping: role.mapping,
          option: { text: option.text, label: option.text },
        })
    );
  });
  return transformedOptions;
};
