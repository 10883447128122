import classnames from "classnames";
import { Direction, IconProps } from "./";
import "./RedArrowIcon.scss";

export const RedArrowIcon = ({
  className,
  direction,
  size = 30,
}: IconProps) => {
  const classes = classnames("RedArrowIcon", className, {
    "RedArrowIcon--up": direction === Direction.UP,
    "RedArrowIcon--right": direction === Direction.RIGHT,
    "RedArrowIcon--down": direction === Direction.DOWN,
  });

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 35 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path
        d="M16.276 4.55373C13.3596 4.90558 10.4788 5.67592 7.70504 6.65395C5.11692 7.56717 2.0626 8.83165 0.179132 10.8727L1.27973 12.6803C3.74777 12.2225 6.18743 10.9055 8.59044 10.1375C11.1085 9.33719 13.6842 8.83286 16.2893 8.46992C20.0861 7.9404 24.2766 7.69377 28.1657 8.42697C26.1151 9.23729 24.1842 10.3056 22.8955 11.9198C22.0475 12.9614 23.3219 14.4801 24.5105 13.9254C26.0707 13.1918 27.3973 11.9741 28.9081 11.1194C30.1286 10.4341 31.4573 9.98795 32.7871 9.57306L33.0424 9.48012L33.9985 9.09653L34.742 7.15642C32.4416 4.4665 28.8673 0.752975 25.1599 0.364665L24.7012 1.18939C25.7202 2.55151 27.2638 3.51499 28.5584 4.59446L29.166 5.17229C25.0732 3.88216 20.2318 4.0813 16.276 4.55373Z"
        fill="#E37C5C"
      />
    </svg>
  );
};
