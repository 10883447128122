import classNames from "classnames";
import useTranslation from "../../hooks/useTranslation";
import ThankYouIllustration from "../../styles/illustrations/ThankYouSurvey.png";
import { SurveyInfo } from "../SurveyInfo/SurveyInfo";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyThankYou.scss";

export interface SurveyThankYouProps {
  className?: string;
}

export const SurveyThankYou = ({ className }: SurveyThankYouProps) => {
  const { t } = useTranslation("surveyPage");
  const classes = classNames("SurveyThankYou", className);

  return (
    <div className={classes}>
      <div>
        <img
          className="SurveyThankYou__img"
          src={ThankYouIllustration}
          alt=""
        />
        <Typography
          className="SurveyThankYou__title"
          tagVariant="h1"
          desktop="handwrittenH1"
          color="error"
        >
          {t("thankYou.title")}
        </Typography>
        <Typography
          tagVariant="p"
          desktop="body1"
          mobile="body1"
          weight="regular"
        >
          {t("thankYou.info")}
        </Typography>
      </div>
      <SurveyInfo />
    </div>
  );
};
