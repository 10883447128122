import { CardActionArea, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import ChecklistIllustration from "../../styles/illustrations/ChecklistIllustration.png";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Direction, IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./TrainingLinkCard.scss";

export const TrainingLinkCard = () => {
  const { t } = useTranslation("dashboardPage");
  return (
    <Card containerClassName="TrainingLinkCardContainer" clickable>
      <CardActionArea
        component={Link}
        to={"/training"}
        className="TrainingLinkCard"
      >
        <img
          src={ChecklistIllustration}
          alt=""
          className="TrainingLinkCard__illustration"
        />
        <Typography className="TrainingLinkCard__text" variant="h2">
          {t("training.explore")}
        </Typography>
        <Button icon color="secondary">
          <Icon type={IconTypes.Arrow} direction={Direction.RIGHT} />
        </Button>
      </CardActionArea>
    </Card>
  );
};
