import { TrainingAudience } from "./training";

export enum SurveyType {
  Launch = "survey-launch",
  Reminder = "survey-reminder",
  DueDate = "survey-due-date",
}
export interface ActionPlanResponse {
  events: EventsResponse[];
}

export type EventMeta = SurveyMeta | TrainingMeta;

export interface EventsResponse {
  date: string | Date;
  label: string;
  meta: EventMeta;
}

export interface SurveyMeta {
  id: string | null;
  type: SurveyType;
  survey: string;
  dispatch: string | null;
  related_id: number | null; // Id of reminder used to update the reminder events on action plan
}

export interface TrainingMeta {
  id: string;
  type: "training";
  module: string;
  audience: TrainingAudience;
}

export interface todoLink {
  [key: string]: string;
}
