import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { TimelineValue, isSurveyMeta } from "../../utils/ActionPlan.utils";
import { SurveySettings } from "../SurveySettings/SurveySettings";
import { Button } from "../ui/Button/Button";
import { DatePicker } from "../ui/Input/DatePicker";
import { Typography } from "../ui/Typography/Typography";
import "./ActionPlanTimeline.scss";

interface TimelineEventInfoProps {
  event?: TimelineValue;
  handleChangeDate: (event: TimelineValue, newDate: Date) => void;
  handleSaveEvents: () => void;
  surveyDispatch?: SurveyDispatch;
  onSurveyUpdate?: () => void;
}

export const TimelineEventInfo = ({
  event,
  handleChangeDate,
  handleSaveEvents,
  surveyDispatch,
  onSurveyUpdate,
}: TimelineEventInfoProps) => {
  const { t } = useTranslation("dashboardPage");
  const showSurveySettings =
    event && surveyDispatch && isSurveyMeta(event?.meta);

  return (
    <div className="TimelineEventInfo">
      <Typography desktop="h2" className="TimelineEventInfo__title">
        {event?.meta.type === "training"
          ? event?.label
          : t(`actionPlan.timeline.editSurveyDates`)}
      </Typography>
      <Typography desktop="body1">
        <Trans
          i18nKey={t(`actionPlan.timeline.${event?.meta?.type}.info`) || ""}
          components={{
            u: <u />,
            anchor: (
              <Link to={event?.href || ""} target="_blank" rel="noreferrer" />
            ),
          }}
        />
      </Typography>
      {showSurveySettings ? (
        <SurveySettings
          dispatch={surveyDispatch}
          onSurveyUpdate={onSurveyUpdate}
        />
      ) : (
        <div className="TimelineEventInfo__dateContainer">
          <Typography desktop="body1" weight="bold">
            {t("actionPlan.timeline.date")}
          </Typography>
          {event && (
            <DatePicker
              defaultValue={event?.date}
              onChange={(newValue) => handleChangeDate(event, newValue as Date)}
            />
          )}
        </div>
      )}

      {!showSurveySettings && (
        <div className="TimelineEventInfo__actions">
          <Button
            variant="contained"
            color="info"
            onClick={handleSaveEvents}
            small
          >
            {t("actionPlan.timeline.actions.save")}
          </Button>
        </div>
      )}
    </div>
  );
};
