import DemographyIllustration from "../styles/illustrations/Demography.png";
import HarassmentIllustration from "../styles/illustrations/Harassment.png";
import ManagementIllustration from "../styles/illustrations/Management.png";
import WorkplaceCommIllustration from "../styles/illustrations/WorkplaceCommunication.png";

const getChapterIllustration = (id: string) => {
  if (id === "S.CHA.004" || id === "CHA.005") {
    return HarassmentIllustration;
  } else if (id === "S.CHA.002") {
    return ManagementIllustration;
  } else if (id === "CHA.003") {
    return DemographyIllustration;
  } else return WorkplaceCommIllustration; // S.CHA.003 or CHA.004
};

export default getChapterIllustration;
