import { Backdrop, Typography } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { darkStoneBlue, grey1 } from "../../styles/theme";
import { LoadingIndicator } from "./LoadingIndicator/LoadingIndicator";

interface LoadingScreenProps {
  active: boolean;
  text?: string;
}

export const LoadingScreen = ({ active, text }: LoadingScreenProps) => {
  const { t } = useTranslation();

  return (
    <Backdrop
      sx={{
        color: grey1,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
      invisible={true}
      open={active}
    >
      <LoadingIndicator />
      <Typography color={darkStoneBlue}>
        {text ? text : t("loadingMsg")}
      </Typography>
    </Backdrop>
  );
};
