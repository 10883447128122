import classnames from "classnames";
import { Direction, IconProps } from "./index";
import "./NavArrow.scss";

export const NavArrow = ({ size = 24, direction, className }: IconProps) => {
  // Default direction is down
  const classes = classnames("NavArrow", className, {
    "NavArrow--up": direction === Direction.UP,
    "NavArrow--right": direction === Direction.RIGHT,
    "NavArrow--left": direction === Direction.DOWN,
  });
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="#2A323A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
