import { ScaleValue, TextColor } from "../../types/scale";
import {
  MAX_SCALE_VALUE,
  MIN_SCALE_VALUE,
} from "../../utils/WorkplaceCulture.utils";
import getColorByScore, { workplaceCultureColors } from "../../utils/graphs";
import { Scale } from "../ui/Scale/Scale";
import { WorkplaceCultureRail } from "./WorkplaceCultureRail";
import "./WorkplaceCultureScale.scss";

interface WorkplaceCultureScaleProps {
  values: ScaleValue[];
  average: ScaleValue;
  className?: string;
  small?: boolean;
  minValue?: number;
  maxValue?: number;
}

export const WorkplaceCultureScale = ({
  values,
  average,
  className,
  small = false,
  minValue = MIN_SCALE_VALUE,
  maxValue = MAX_SCALE_VALUE,
}: WorkplaceCultureScaleProps) => {
  average.color = getColorByScore(average.score);
  average.colorText =
    average.color === workplaceCultureColors[2]
      ? TextColor.Dark
      : TextColor.Light;

  return (
    <Scale
      RailComponent={WorkplaceCultureRail}
      min={minValue}
      max={maxValue}
      values={values}
      average={average}
      className={className}
      small={small}
    />
  );
};
