import { IconProps } from ".";

export const ManyPeopleIcon = ({ className, size = 14 }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    className={className}
  >
    <path
      fill="#000"
      d="M8.99 7.904a2.844 2.844 0 1 0-3.98 0 4.15 4.15 0 0 0-1.64 1.58.656.656 0 0 0 1.137.656 2.898 2.898 0 0 1 4.988 0 .657.657 0 1 0 1.137-.656 4.15 4.15 0 0 0-1.642-1.58ZM5.47 5.875a1.531 1.531 0 1 1 3.062 0 1.531 1.531 0 0 1-3.063 0Zm8.05.524a.656.656 0 0 1-.92-.13c-.455-.607-1.085-1.072-1.604-1.184a.656.656 0 0 1-.1-1.253 1.094 1.094 0 1 0-1.354-1.547.656.656 0 1 1-1.149-.635 2.406 2.406 0 1 1 4.008 2.645c.481.32.904.722 1.25 1.186a.656.656 0 0 1-.131.918ZM3.004 5.087c-.518.112-1.148.577-1.604 1.184a.657.657 0 1 1-1.05-.79A5.051 5.051 0 0 1 1.6 4.295 2.406 2.406 0 1 1 5.608 1.65a.656.656 0 1 1-1.148.635 1.094 1.094 0 1 0-1.356 1.547.656.656 0 0 1-.1 1.253v.002Z"
    />
  </svg>
);
