import useTranslation from "../../hooks/useTranslation";
import { Logo } from "../ui/Logo";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyInfo.scss";

export const SurveyInfo = () => {
  const { t } = useTranslation("surveyPage");

  return (
    <div className="SurveyInfo">
      <Typography
        desktop="body2"
        color="secondary"
        className="SurveyInfo__text"
      >
        {t("surveyInfo")}
      </Typography>
      <Logo width={105} height={66} />
    </div>
  );
};
