import { Divider, Skeleton } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState } from "react";
import { PipelineService } from "../../api/PipelineService";
import useTranslation from "../../hooks/useTranslation";
import { secondaryColor } from "../../styles/theme";
import { ILevel, LevelsResponse } from "../../types/pipeline";
import { isInList } from "../../utils/validation";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Input } from "../ui/Input/Input";
import { Tooltip } from "../ui/Tooltip/Tooltip";
import { Typography } from "../ui/Typography/Typography";
import { Direction } from "../ui/icons";
import { NavArrow } from "../ui/icons/NavArrow";
import { TrashIcon } from "../ui/icons/TrashIcon";
import "./PipelineLevelSetup.scss";

export interface PipelineLevelSetupProps {
  setLevelSetupSuccess: (val: boolean) => void;
  setShowLevelSetup: (val: boolean) => void;
  levelsData?: LevelsResponse;
  isLoading?: boolean;
}
export const PipelineLevelSetup = ({
  setLevelSetupSuccess,
  setShowLevelSetup,
  levelsData,
  isLoading,
}: PipelineLevelSetupProps) => {
  const { t } = useTranslation("pipelinePage");
  const pipelineService = new PipelineService();
  const [inputValue, setInputValue] = useState("");
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [error, setError] = useState("");

  const preDefinedLevels: ILevel[] = [
    { id: null, name: "C-level", order: 0 },
    { id: null, name: "VP", order: 1 },
    { id: null, name: "Directors", order: 2 },
    { id: null, name: "Managers", order: 3 },
    { id: null, name: "General employees", order: 4 },
  ];

  const [levels, setLevels] = useState<ILevel[]>(preDefinedLevels);

  useEffect(() => {
    if (levelsData?.levels.length) {
      setLevels(levelsData.levels);
    }
  }, [levelsData]);

  const submitLevelsMutation = useMutation({
    mutationFn: (levels: ILevel[]) =>
      pipelineService.submitLevels({
        levels: levels,
      }),
  });

  const handleDelete = (order: number) => {
    const updatedLevels = levels.filter((level) => level.order !== order);
    updatedLevels.forEach((level, index) => (level.order = index));
    setLevels([...updatedLevels]);
  };
  const handleUp = (order: number) => {
    if (order > 0) {
      const updatedLevels = [...levels];
      const currentIndex = updatedLevels.findIndex(
        (level) => level.order === order
      );
      const prevIndex = updatedLevels.findIndex(
        (level) => level.order === order - 1
      );
      updatedLevels[currentIndex].order -= 1;
      updatedLevels[prevIndex].order += 1;

      [updatedLevels[prevIndex], updatedLevels[currentIndex]] = [
        updatedLevels[currentIndex],
        updatedLevels[prevIndex],
      ];
      setLevels(updatedLevels);
    }
  };

  const handleDown = (order: number) => {
    if (order < levels.length - 1) {
      const updatedLevels = [...levels];
      const currentIndex = updatedLevels.findIndex(
        (level) => level.order === order
      );
      const nextIndex = updatedLevels.findIndex(
        (level) => level.order === order + 1
      );

      updatedLevels[currentIndex].order += 1;
      updatedLevels[nextIndex].order -= 1;

      [updatedLevels[currentIndex], updatedLevels[nextIndex]] = [
        updatedLevels[nextIndex],
        updatedLevels[currentIndex],
      ];
      setLevels(updatedLevels);
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    order: number
  ) => {
    const updatedLevels = levels.map((level) => {
      if (level.order === order) {
        return { ...level, name: event.target.value };
      }
      return level;
    });
    setLevels(updatedLevels);
  };

  const handleChangeInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(event.target.value);
    setError("");
  };

  const handleKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      handleAddLevel();
    }
  };

  const handleAddLevel = () => {
    const updatedLevels = [...levels];

    if (
      isInList(
        inputValue,
        levels.map((level) => level.name)
      )
    ) {
      setError(
        t("setup.errors.alreadyInList", {
          value: inputValue,
        }) || ""
      );
    } else {
      updatedLevels.splice(levels.length + 1, 0, {
        id: null,
        name: inputValue,
        order: levels.length,
      });
      setLevels(updatedLevels);
      setInputValue("");
    }
  };

  const handleSubmit = async () => {
    const updatedLevels = levels.filter((level) => level.name !== "");
    setLevels(updatedLevels);
    if (levels.length < 1) {
      return setError(t("setup.errors.noLevel") || "");
    }
    await submitLevelsMutation.mutateAsync(levels, {
      onSuccess: () => {
        setLevelSetupSuccess(true);
        setIsSubmitError(false);
        setShowLevelSetup(false);
      },
      onError: () => {
        setLevelSetupSuccess(false);
        setIsSubmitError(true);
      },
    });
  };

  const renderLoading = () => (
    <div className="PipelineLevelSetup__loading">
      <Typography
        tagVariant="h1"
        desktop="h1"
        className="PipelineLevelSetup__loading__title"
      >
        <Skeleton variant="text" width={200} />
      </Typography>
      <div className="PipelineLevelSetup__loading__container">
        {[...Array(3)].map((e, i) => {
          return (
            <Card
              className="PipelineLevelSetup__loading__container__item"
              key={`skeleton-${i}`}
            >
              <Skeleton width="30%" />
              <Skeleton width="40%" />
            </Card>
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      {!isLoading && (
        <div className="PipelineLevelSetup">
          <Typography
            tagVariant="h2"
            desktop="h2"
            className="PipelineLevelSetup__title"
          >
            {t("setup.levels.title")}
          </Typography>
          <div className="PipelineLevelSetup__levels">
            <div className="PipelineLevelSetup__levels__header">
              <Typography tagVariant="h2" desktop="h3">
                {t("setup.levels.header.levels")}
              </Typography>
              <Typography tagVariant="h2" desktop="h3">
                {t("setup.levels.header.order")}
              </Typography>
            </div>
            {levels.map((level, i) => {
              return (
                <div
                  className="PipelineLevelSetup__levels__item"
                  key={`level-${level.order}`}
                >
                  <div className="PipelineLevelSetup__levels__item__input">
                    <Tooltip title={t("actions.delete")} placement="top" arrow>
                      <div>
                        <Button
                          icon
                          color="secondary"
                          onClick={() => handleDelete(level.order)}
                        >
                          <TrashIcon color={secondaryColor} size={16} />
                        </Button>
                      </div>
                    </Tooltip>
                    <Input
                      value={level.name}
                      onChange={(event) => handleChange(event, level.order)}
                    />
                  </div>
                  <div className="PipelineLevelSetup__levels__item__actions">
                    <Typography
                      className="PipelineLevelSetup__levels__item__actions__order"
                      tagVariant="p"
                      desktop="body2"
                      weight="regular"
                    >
                      {level.order + 1}
                    </Typography>
                    <Button
                      icon
                      color="secondary"
                      disabled={i === levels.length - 1}
                      onClick={() => handleDown(level.order)}
                    >
                      <NavArrow />
                    </Button>
                    <Button
                      icon
                      color="secondary"
                      disabled={i === 0}
                      onClick={() => handleUp(level.order)}
                    >
                      <NavArrow direction={Direction.UP} />
                    </Button>
                  </div>
                </div>
              );
            })}
            <Divider sx={{ margin: "1rem 0" }} />
            <div className="PipelineLevelSetup__levels__item">
              <div className="PipelineLevelSetup__levels__item__input">
                <Input
                  value={inputValue}
                  onChange={handleChangeInput}
                  onKeyDown={(event) => handleKeyDown(event)}
                  required
                />
              </div>

              <Button
                variant="contained"
                color="secondary"
                small
                onClick={handleAddLevel}
              >
                {t("actions.addLevel")}
              </Button>
            </div>
            {error && <p>{error}</p>}
            <div className="PipelineLevelSetup__buttonWrapper">
              <Button
                onClick={handleSubmit}
                variant="contained"
                loading={submitLevelsMutation.isLoading}
              >
                {t("actions.next")}
              </Button>
              {isSubmitError && <p>{t("setup.error")}</p>}
            </div>
          </div>
        </div>
      )}
      {isLoading && renderLoading()}
    </>
  );
};
