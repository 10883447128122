import classnames from "classnames";
import { ChangeEvent } from "react";
import useTranslation from "../../hooks/useTranslation";
import MultiSelectQuestion from "../../models/multiSelectQuestion";
import ScaleQuestion from "../../models/scaleQuestion";
import SingleSelectQuestion from "../../models/singleSelectQuestion";
import { SurveyAnswer } from "../../types/survey";
import { InputGroup } from "../ui/Input/InputGroup";
import { Typography } from "../ui/Typography/Typography";
import "./Question.scss";

export interface QuestionProps {
  question: ScaleQuestion | SingleSelectQuestion | MultiSelectQuestion;
  onAnswerChange: (
    question: ScaleQuestion | SingleSelectQuestion | MultiSelectQuestion,
    answerId: string
  ) => void;
  surveyAnswers: SurveyAnswer[];
  isPreview?: boolean;
}

export const Question = ({
  question,
  onAnswerChange,
  surveyAnswers,
  isPreview = false,
}: QuestionProps) => {
  const { t } = useTranslation("surveyPage");

  const handleAnswerChange = (
    event: ChangeEvent<HTMLInputElement>,
    answerId: string
  ) => {
    onAnswerChange(question, answerId);
  };

  const optionsClasses = classnames("Question__options", {
    "Question__options--preview": isPreview,
  });

  return (
    <div className="Question">
      {question.preamble && (
        <Typography
          desktop="h3"
          weight="regular"
          className="Question__preamble"
        >
          {question.preamble}
        </Typography>
      )}
      <div className="Question__question">
        <div>
          <Typography tagVariant="h2" desktop="h2">
            {question.text}
          </Typography>

          {question.clarification && (
            <Typography
              desktop="h3"
              weight="regular"
              className="Question__question__clarification"
            >
              {question.clarification}
            </Typography>
          )}
        </div>
        {question.isMultiSelect() && (
          <Typography
            tagVariant="h5"
            desktop="body1"
            weight="regular"
            className="Question__question__help"
          >
            {t("question.multiSelect.helperText")}
          </Typography>
        )}
      </div>
      <div className={optionsClasses}>
        {question.isScale() && (
          <div className="Question__options__answer">
            <Typography
              tagVariant="h2"
              desktop="handwrittenH2"
              className="Question__options__answer__text"
              color="error"
            >
              {(question as ScaleQuestion).answer?.text}
            </Typography>
          </div>
        )}
        <InputGroup
          name={question.referenceId}
          options={question.options}
          answerIds={question.getAnswerIds()}
          onChange={handleAnswerChange}
          showScale={question.isScale()}
          startLabel={question.getStartLabel()}
          endLabel={question.getEndLabel()}
          type={question.isMultiSelect() ? "checkbox" : "radio"}
        />
      </div>
    </div>
  );
};
