import { VisibilityOffOutlined as VisibilityOffOutlinedIcon } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CardActionArea, CardMedia, Skeleton, Typography } from "@mui/material";
import classNames from "classnames";
import { Link } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import getChapterIllustration from "../../utils/getChapterIllustration";
import getColorByScore from "../../utils/graphs";
import { Card } from "./../ui/Card/Card";
import { Tooltip } from "./../ui/Tooltip/Tooltip";
import "./ChapterLinkCard.scss";

interface ChapterLinkCardProps {
  to?: string;
  title?: string;
  info?: string;
  score?: number | null;
  isLoading?: boolean;
  id: string;
}

export const ChapterLinkCard = ({
  to,
  title,
  info,
  score,
  id,
  isLoading = true,
}: ChapterLinkCardProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const classes = classNames("ChapterLinkCard", {
    "ChapterLinkCard--loading": isLoading,
    "ChapterLinkCard--empty": !score && !isLoading,
  });

  if (isLoading) {
    return (
      <Card className={classes}>
        <div>
          <div className="ChapterLinkCard__content__illustration">
            <Skeleton variant="rectangular" width={160} height={160} />
          </div>
          <Skeleton sx={{ mt: 2 }} />
          <Skeleton />
        </div>
        <Skeleton />
      </Card>
    );
  }

  const renderIllustration = () => {
    if (!score) {
      return (
        <div className="ChapterLinkCard__content__illustration ChapterLinkCard__content__illustration--empty">
          <VisibilityOffOutlinedIcon fontSize="large" />
          <Typography color="secondary">
            {t("surveyChapterOverview.notEnoughData")}
          </Typography>
        </div>
      );
    }

    return (
      <div className="ChapterLinkCard__content__illustration">
        <CardMedia
          className="ChapterLinkCard__content__illustration__img"
          image={getChapterIllustration(id)}
          title={`${title}-illustration`}
        />
      </div>
    );
  };

  return (
    <Card className={classes} clickable={!!score}>
      <CardActionArea component={Link} to={to || "#"} disabled={!score}>
        <div className="ChapterLinkCard__content">
          <div>
            {renderIllustration()}

            <Typography
              className="ChapterLinkCard__content__title"
              variant="h3"
            >
              {title}
            </Typography>
            <Typography
              className="ChapterLinkCard__content__info"
              color="secondary"
            >
              {info}
            </Typography>
          </div>
          <div className="ChapterLinkCard__content__footer">
            {score && (
              <>
                <Tooltip
                  title={t("surveyChapterOverview.scoreTooltipInfo")}
                  placement="top-start"
                  arrow
                >
                  <div
                    className="ChapterLinkCard__content__footer__score"
                    style={{ background: getColorByScore(score || 0) }}
                  >
                    <Typography
                      color={score >= 2 && score < 3 ? "black" : "white"}
                    >
                      {score}
                    </Typography>
                  </div>
                </Tooltip>
                <KeyboardArrowRightIcon color="secondary" />
              </>
            )}
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
};
