import {
  IQuestionOption,
  QuestionType,
  SurveyQuestionResponse,
} from "../types/question";
import Question from "./question";

class ScaleQuestion extends Question {
  answer?: IQuestionOption;
  type: QuestionType;

  constructor(parameters: SurveyQuestionResponse) {
    super(parameters);
    this.type = QuestionType.Scale;
  }

  setAnswer = (optionId: string) => {
    const option = this.options.find(
      (option) => option.referenceId === optionId
    );

    if (option) {
      if (option.referenceId === this.answer?.referenceId) {
        this.answer = undefined;
      } else {
        this.answer = option;
      }
    }
  };

  resetAnswer = () => {
    this.answer = undefined;
  };

  getStartLabel = (): string => {
    return this.options[0].text;
  };

  getEndLabel = (): string => {
    return this.options[this.options.length - 1].text;
  };

  hasAnswer = (): boolean => {
    return !!this.answer;
  };

  getAnswer = (): IQuestionOption | null => {
    return this.answer ? this.answer : null;
  };

  isScale = (): boolean => {
    return true;
  };

  getAnswerIds = (): string[] => {
    return this.answer ? [this.answer.referenceId] : [];
  };
}

export default ScaleQuestion;
