import { Switch } from "@mui/material";
import classnames from "classnames";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { PipelineFilter } from "../../types/pipeline";
import { Card } from "../ui/Card/Card";
import { Tab } from "../ui/Tabs/Tab";
import { TabPanel } from "../ui/Tabs/TabPanel";
import { Tabs } from "../ui/Tabs/Tabs";
import { Typography } from "../ui/Typography/Typography";
import { BoxIcon } from "../ui/icons/BoxIcon";
import {
  getPipelineGroupColor,
  getPipelineLevelChartData,
  sortFilters,
} from "./PipelineChart.utils";
import "./PipelineChart.scss";

export interface PipelineChartProps {
  pipelineFilters?: PipelineFilter[];
  small?: boolean;
}

export const PipelineChart = ({
  pipelineFilters,
  small,
}: PipelineChartProps) => {
  const { t } = useTranslation("pipelinePage");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isPipelineView, setIsPipelineView] = useState(false);
  const toggleViewSwitch = () =>
    setIsPipelineView((previousState) => !previousState);
  const handleChangeTab = (newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  pipelineFilters && sortFilters(pipelineFilters, t);

  const renderPipeline = (filter: PipelineFilter) => {
    const pipelineGroupColors = getPipelineGroupColor(filter);

    return (
      <>
        <div className="PipelineChart__container__chart">
          {filter.levels.map((level, i) => {
            const chartData = getPipelineLevelChartData(
              level,
              i,
              isPipelineView,
              small,
              pipelineGroupColors
            );
            return (
              <HighchartsReact
                highcharts={Highcharts}
                options={chartData}
                key={`bar-${level.label}`}
              />
            );
          })}
        </div>
        <div className="PipelineChart__container__footer">
          <div className="PipelineChart__container__footer__legend">
            {Object.keys(pipelineGroupColors).map((groupLabel) => {
              return (
                <div
                  className="PipelineChart__container__footer__legend__item"
                  key={`legend-${groupLabel}`}
                >
                  <div>
                    <BoxIcon color={pipelineGroupColors[groupLabel]} />
                  </div>
                  <Typography tagVariant="p" desktop="label2">
                    {groupLabel}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div className="PipelineChart__container__footer__actions">
            <Typography tagVariant="p" desktop="label2" weight="regular">
              {isPipelineView
                ? t("chart.stackedBarView")
                : t("chart.pipelineView")}
            </Typography>
            <Switch
              className="PipelineChart__container__chart__switch"
              onChange={toggleViewSwitch}
              color="secondary"
            />
          </div>
        </div>
      </>
    );
  };
  const classes = classnames("PipelineChart", {
    "PipelineChart--small": small,
  });
  return (
    <div className={classes}>
      <Tabs
        className="PipelineChart__container__filters"
        selectedTabIndex={selectedTabIndex}
        label="Pipeline-chart"
        small={small}
      >
        {pipelineFilters?.map((filter, i) => {
          const tabClasses = classnames(
            "PipelineChart__container__filters",
            `PipelineChart__container__filters--filter${i}`
          );
          return (
            <Tab
              key={filter.label}
              selected={selectedTabIndex === i}
              label={t(`chart.filters.${filter.label}.title`)}
              index={i}
              onClick={handleChangeTab}
              className={tabClasses}
              small
            />
          );
        })}
      </Tabs>
      <Card variant={small ? undefined : "outlined"}>
        {pipelineFilters?.map((filter, i) => (
          <TabPanel
            className="PipelineChart__container"
            key={`pipelineFilters-${i}`}
            index={i}
            selectedTabIndex={selectedTabIndex}
            label="pipeline-filter-tab"
          >
            {renderPipeline(filter)}
          </TabPanel>
        ))}
      </Card>
    </div>
  );
};
