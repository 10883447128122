export enum QuestionType {
  "SingleSelect" = "SINGLE",
  "MultiSelect" = "MULTI",
  "Scale" = "SCALED",
  "Mapped" = "MAPPED",
}
export interface SurveyQuestionResponse {
  clarification?: string;
  kind: QuestionType;
  options: SurveyOptionResponse[];
  title?: string | null;
  preamble?: string | null;
  order: number;
  text: string;
  reference_id: string;
}

export interface SurveyOptionResponse {
  clarification: string;
  reference_id: string;
  text: string;
  order: number;
  negates_others: boolean;
}

export interface IQuestionOption {
  referenceId: string;
  text: string;
  order: number;
  negatesOthers: boolean;
  clarification: string;
}
