import { Grid } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { SurveyService } from "../api/SurveyService";
import { SurveyLaunchSteps } from "../components/SurveyLaunchSteps/SurveyLaunchSteps";
import { SurveyNoticeCard } from "../components/SurveyNoticeCard/SurveyNoticeCard";
import { SurveyStatus } from "../components/SurveyStatus/SurveyStatus";
import { Button } from "../components/ui/Button/Button";
import { LoadingIndicator } from "../components/ui/LoadingIndicator/LoadingIndicator";
import { Typography } from "../components/ui/Typography/Typography";
import useTranslation from "../hooks/useTranslation";
import SurveyDispatch from "../models/surveyDispatch";
import { GetSurveyDispatchResponse } from "../types/survey";
import "./LaunchSurveyPage.scss";

export const LaunchSurveyPage = () => {
  const surveyService = new SurveyService();
  const [survey, setSurvey] = useState<SurveyDispatch>();
  const { t } = useTranslation("launchSurveyPage");
  const surveyIsActive = survey && survey.isActive();

  const {
    data: surveyDispatchData,
    isLoading: isLoadingSurveyDispatch,
    error: surveyDispatchError,
    refetch: handleSurveyDispatchRefetch,
  } = useQuery<GetSurveyDispatchResponse, AxiosError>(["surveyDispatch"], () =>
    surveyService.getSurveyDispatch()
  );

  const launchSurveyMutation = useMutation({
    mutationFn: () => surveyService.launchSurveyDispatch(),
  });

  const errorLoadingSurveyDispatch = () => {
    if (surveyDispatchError && surveyDispatchError.response?.status !== 404) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (launchSurveyMutation.data && (!survey || !survey?.isActive())) {
      setSurvey(new SurveyDispatch(launchSurveyMutation.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchSurveyMutation.data]);

  useEffect(() => {
    if (surveyDispatchData && !survey) {
      const surveyModel = new SurveyDispatch(surveyDispatchData);

      setSurvey(surveyModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatchData]);

  useEffect(() => {
    if (surveyDispatchData && survey) {
      const updatedSurvey = { ...survey };
      updatedSurvey.recipients = surveyDispatchData.recipients;
      setSurvey(updatedSurvey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatchData?.recipients]);

  const handleLaunchSurvey = () => {
    launchSurveyMutation.mutate();
  };

  const handleClose = () => {
    handleSurveyDispatchRefetch();
  };

  return (
    <div className="LaunchSurveyPage">
      <div className="LaunchSurveyPage__survey">
        <Grid container spacing={3} marginBottom={3}>
          <Grid item md={2} />
          <Grid item md={8}>
            <Typography
              desktop="h1"
              mobile="body1"
              tagVariant="h1"
              className="LaunchSurveyPage__survey__heading"
            >
              {t("title")}
            </Typography>

            {(!survey || !survey?.isActive()) && <SurveyNoticeCard />}

            {isLoadingSurveyDispatch &&
              !launchSurveyMutation.isLoading &&
              !errorLoadingSurveyDispatch() && (
                <div className="LaunchSurveyPage__survey__loading">
                  <LoadingIndicator />
                </div>
              )}
            {!survey &&
              !isLoadingSurveyDispatch &&
              !errorLoadingSurveyDispatch() && (
                <div className="LaunchSurveyPage__survey__action">
                  <Button
                    variant="contained"
                    color="info"
                    onClick={handleLaunchSurvey}
                    loading={launchSurveyMutation.isLoading}
                    disabled={launchSurveyMutation.isLoading}
                  >
                    {t("startSurveySetup")}
                  </Button>
                  {!!launchSurveyMutation.error && (
                    <Typography desktop="h3" color="error">
                      {t("surveyStartError")}
                    </Typography>
                  )}
                </div>
              )}
            {errorLoadingSurveyDispatch() && (
              <Typography
                desktop="h3"
                color="error"
                className="LaunchSurveyPage__survey__action"
              >
                {t("surveyDispatchError")}
              </Typography>
            )}
          </Grid>
        </Grid>
        {surveyIsActive && (
          <SurveyStatus
            survey={survey}
            isLoading={false}
            error={false}
            noSurvey={false}
            onSurveyUpdate={handleSurveyDispatchRefetch}
          />
        )}
        {survey && !surveyIsActive && (
          <>
            <SurveyLaunchSteps
              onClose={handleClose}
              survey={survey}
              onSurveyUpdate={handleSurveyDispatchRefetch}
            />
          </>
        )}
      </div>
    </div>
  );
};
