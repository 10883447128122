import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    debug:
      process.env.NODE_ENV === "development" &&
      process.env.REACT_APP_DEBUG_LOCALE === "true"
        ? true
        : false,
    lng: "en-GB",
    fallbackLng: "en-GB",
    defaultNS: "common",
  });

export default i18n;
