import { Grid, Typography } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import useTranslation from "../../../hooks/useTranslation";
import { ChartTooltip } from "../ChartTooltip/ChartTooltip";
import { PersonStandingIcon } from "../icons/PersonStanding";
import { PeopleChartLegend } from "./PeopleChartLegend";
import "./PeopleChart.scss";

export interface PeopleChartValue {
  label: string;
  ratio: number;
  count: number;
  color?: string;
}

interface PeopleChartProps {
  values: PeopleChartValue[];
  info?: string;
  small?: boolean;
}

export const PeopleChart = ({
  values,
  info,
  small = false,
}: PeopleChartProps) => {
  const { t } = useTranslation("diversityRatioPage");
  const classes = classNames("PeopleChart", {
    "PeopleChart--small": small,
  });

  const [valueInHover, setValueInHover] = useState<
    PeopleChartValue | undefined
  >();

  const handleHoverOver = (value: PeopleChartValue) => {
    if (value.color !== valueInHover?.color) {
      setValueInHover(value);
    }
  };

  const handleHoverLeave = () => {
    setValueInHover(undefined);
  };

  return (
    <Grid container className={classes}>
      <Grid
        item
        {...(small ? {} : { xs: 12, md: 7, lg: 8 })}
        className="PeopleChart__container"
      >
        <ChartTooltip
          chartValue={
            !!valueInHover
              ? {
                  label: valueInHover.label,
                  countString: `${valueInHover.count} ${t(
                    "chart.tooltipInfo"
                  )}`,
                  ratio: valueInHover.ratio,
                  color: valueInHover.color,
                }
              : undefined
          }
          followCursor
          placement="right"
        >
          <Grid
            container
            className="PeopleChart__container__people"
            onMouseLeave={() => handleHoverLeave()}
          >
            {values?.map((value) => {
              return [...Array(value.ratio)].map((e, i) => {
                let iconWidth = small ? 21 : 28;
                let iconHeight = small ? 22 : 32;

                const iconClasses = classNames("", {
                  "PeopleChart__container__people--active":
                    valueInHover?.color === value.color,
                });

                return (
                  <Grid
                    item
                    xs={12 / 10}
                    {...(small ? {} : { lg: 12 / 20 })}
                    key={`personIcon-${i}`}
                  >
                    <PersonStandingIcon
                      color={value.color}
                      width={iconWidth}
                      height={iconHeight}
                      onMouseEnter={() => handleHoverOver(value)}
                      className={iconClasses}
                    />
                  </Grid>
                );
              });
            })}
          </Grid>
        </ChartTooltip>
      </Grid>
      <Grid
        item
        {...(small ? {} : { xs: 12, md: 5, lg: 4 })}
        className="PeopleChart__legend"
      >
        <PeopleChartLegend
          groups={values}
          className={"PeopleChart__legend"}
          small={small}
        />
        {!small && <Typography color="secondary">{info}</Typography>}
      </Grid>
    </Grid>
  );
};
