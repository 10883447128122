import {
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
} from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import "./Select.scss";

export const Select = ({
  id,
  label,
  defaultOpen = false,
  children,
  className,
  ...rest
}: MUISelectProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const inputClasses = classNames("Select__input", {
    "Select__input--open": isOpen,
  });

  const containerClasses = classNames("Select", className);

  return (
    <span className={containerClasses}>
      <InputLabel className="Select__label" id={id}>
        {label}
      </InputLabel>
      <MUISelect
        className={inputClasses}
        id={id}
        defaultOpen={defaultOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        {...rest}
      >
        {children}
      </MUISelect>
    </span>
  );
};
