import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { StepContainer } from "./StepContainer";

// TODO: How do we map this for the backend?
const numberOfEmployees = [
  { name: "<50", value: 49 },
  { name: "50-150", value: 50 },
  { name: "150-250", value: 150 },
  { name: "250-500", value: 250 },
  { name: "500-1000", value: 500 },
  { name: "1000+", value: 1000 },
];
interface NumberOfEmployeeStepType {
  companyName: string;
  numberOfEmployee: number;
  setStep: Dispatch<SetStateAction<number>>;
  setNumberOfEmployee: Dispatch<SetStateAction<number>>;
  step: number;
}

export const NumberOfEmployeeStep = ({
  companyName,
  numberOfEmployee,
  setStep,
  setNumberOfEmployee,
  step,
}: NumberOfEmployeeStepType) => {
  const handleNumberOfEmployeeChange = (event: SelectChangeEvent) => {
    setNumberOfEmployee(event.target.value as unknown as number);
  };
  return (
    <StepContainer
      step={step}
      submitFunction={() => setStep(step + 1)}
      setStep={setStep}
    >
      <Typography variant="h1" sx={{ pb: 3 }}>
        Employees
      </Typography>
      <Typography
        variant="body1"
        sx={{ pb: 3 }}
        style={{ display: "inline-block", fontSize: "1rem" }}
      >
        How many employees do you have at{" "}
        <Typography
          variant="handwrittenH1"
          style={{ fontSize: "inherit" }}
          color="error"
        >
          {companyName}
        </Typography>
        ?
      </Typography>
      <FormControl size="small">
        <InputLabel id="number-of-employees">Number of employees</InputLabel>

        <Select
          labelId="number-of-employees"
          id="number-of-employees"
          value={numberOfEmployee.toString()}
          onChange={handleNumberOfEmployeeChange}
          required
          label="Number of employees"
          className="SignUp__form__dropdown"
        >
          {numberOfEmployees.map((range) => (
            <MenuItem value={range.value} key={range.name}>
              {range.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StepContainer>
  );
};
