import classNames from "classnames";
import DocumentIllustration from "../../styles/illustrations/DocumentIllustration.png";
import { SurveyInfo } from "../SurveyInfo/SurveyInfo";
import { Typography } from "../ui/Typography/Typography";
import "./InvalidSurvey.scss";

export interface InvalidSurveyProps {
  className?: string;
  title: string;
  info: string;
}

export const InvalidSurvey = ({
  className,
  title,
  info,
}: InvalidSurveyProps) => {
  const classes = classNames("InvalidSurvey", className);

  return (
    <div className={classes}>
      <div>
        <img
          className="InvalidSurvey__img"
          src={DocumentIllustration}
          alt="invalid survey"
        />
        <Typography
          className="InvalidSurvey__title"
          tagVariant="h1"
          desktop="display2"
          mobile="display2"
        >
          {title}
        </Typography>
        <Typography
          className="InvalidSurvey__info"
          tagVariant="p"
          desktop="body1"
          mobile="body1"
          weight="regular"
        >
          {info}
        </Typography>
      </div>
      <SurveyInfo />
    </div>
  );
};
