import useTranslation from "../../hooks/useTranslation";
import { PayFilter } from "../PayEquityOverview/PayEquityOverview.utils";
import { Scale } from "../ui/Scale/Scale";
import { PayEquityRail } from "./PayEquityRail";
import "./PayEquityScale.scss";

interface PayEquityScaleProps {
  payFilterData?: PayFilter;
  className?: string;
  small?: boolean;
}

export const PayEquityScale = ({
  payFilterData,
  className,
  small = false,
}: PayEquityScaleProps) => {
  const { t } = useTranslation("payEquityPage");

  const orgMedian = payFilterData?.medianValue;
  const values = payFilterData?.scaleValues;

  const renderScale = () => {
    if (values && orgMedian) {
      const biggestDifference = Math.max(
        ...values.map((scaleValue) =>
          Math.abs(orgMedian.score - scaleValue.score)
        )
      );

      return (
        <Scale
          RailComponent={PayEquityRail}
          min={orgMedian.score - biggestDifference - biggestDifference * 0.1} // * 0.1 for padding
          max={orgMedian.score + biggestDifference + biggestDifference * 0.1} // * 0.1 for padding
          values={values}
          average={orgMedian}
          className={className}
          small={small}
          minScoreLabel={t("scale.labels.low")}
          maxScoreLabel={t("scale.labels.high")}
        />
      );
    }
  };

  return (
    <div className="PayEquityScale">{orgMedian && values && renderScale()}</div>
  );
};
