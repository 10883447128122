import { StyledEngineProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./utils/i18n";
import "./index.scss";

const deferRender = async () => {
  if (process.env.REACT_APP_MOCK_REQUESTS === "true") {
    const { worker } = await import("./mocks/browser");
    return worker.start({
      onUnhandledRequest(request, print) {
        // Do not print warnings on unhandled requests to static content.
        if (
          request.url.includes(".json") ||
          request.url.includes(".svg") ||
          request.url.includes(".ttf") ||
          request.url.includes(".png") ||
          request.url.includes(".woff2") ||
          request.url.includes("hot-update")
        ) {
          return;
        }

        // Print the regular MSW unhandled request warning otherwise.
        print.warning();
      },
    });
  }

  return Promise.resolve();
};

deferRender().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    // Attention: Because of Strict mode components will mount twice in development mode
    // This might cause some unexpected behavior in useEffect with empty dependency arrays
    // Strict mode checks are run in development mode only; they do not impact the production build.
    // TODO make pretty fallback screen
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <React.Suspense>
          <App />
        </React.Suspense>
      </StyledEngineProvider>
    </React.StrictMode>
  );
});
