import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { StepContainer } from "./StepContainer";

const listOfRoles = [
  "HR manager",
  "DEI manager",
  "CHRO/CPO",
  "VP of People",
  "Other",
];
interface UserRoleStepType {
  companyName: string;
  setStep: Dispatch<SetStateAction<number>>;
  setUserRole: Dispatch<SetStateAction<string>>;
  step: number;
  userRole: string;
}

export const UserRoleStep = ({
  companyName,
  setStep,
  setUserRole,
  step,
  userRole,
}: UserRoleStepType) => {
  const handleUserRoleChange = (event: SelectChangeEvent) => {
    setUserRole(event.target.value);
  };
  return (
    <StepContainer
      step={step}
      submitFunction={() => setStep(step + 1)}
      setStep={setStep}
    >
      <Typography variant="h1" sx={{ pb: 3 }}>
        Your role
      </Typography>
      <Typography
        variant="body1"
        sx={{ pb: 3 }}
        style={{ display: "inline-block", fontSize: "1rem" }}
      >
        What is your role at{" "}
        <Typography
          variant="handwrittenH1"
          style={{ fontSize: "inherit" }}
          color="error"
        >
          {companyName}
        </Typography>
        ?
      </Typography>
      <FormControl size="small">
        <InputLabel id="user-role">Your role</InputLabel>

        <Select
          labelId="user-role"
          id="user-role"
          value={userRole}
          required
          onChange={handleUserRoleChange}
          label="Your role"
          className="SignUp__form__dropdown"
        >
          {listOfRoles.map((role) => (
            <MenuItem value={role} key={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StepContainer>
  );
};
