import useTranslation from "../../hooks/useTranslation";
import { ClipboardCheckIllustration } from "../../styles/illustrations/ClipboardCheckIllustration";
import { ActionPlanTodo } from "../ActionPlanTodo/ActionPlanTodo";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";

export const ActionPlanTodoWidget = () => {
  const { t } = useTranslation("dashboardPage");
  return (
    <DashboardWidget
      title={t("actionPlanTodoWidget.title")}
      icon={() => <ClipboardCheckIllustration size={24} strokeWidth={2} />}
    >
      <ActionPlanTodo />
    </DashboardWidget>
  );
};
