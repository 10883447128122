import { IQuestionOption, SurveyQuestionResponse } from "../types/question";

class Question {
  referenceId: string;
  order: number;
  preamble?: string | null;
  options: IQuestionOption[];
  text: string;
  clarification?: string;

  constructor(parameters: SurveyQuestionResponse) {
    this.order = parameters.order;
    this.referenceId = parameters.reference_id;
    this.preamble = parameters.preamble;
    this.options = parameters.options
      .map((option) => {
        return {
          referenceId: option.reference_id,
          order: option.order,
          text: option.text,
          negatesOthers: option.negates_others,
          clarification: option.clarification,
        };
      })
      .sort((optionA, optionB) => (optionA.order || 0) - (optionB.order || 0));
    this.text = parameters.text;
    this.clarification = parameters.clarification;
  }

  getNegatesOthersOptions = () => {
    return this.options.filter((option) => option.negatesOthers === true);
  };

  getNoneNegatesOthersOptions = () => {
    return this.options.filter((option) => option.negatesOthers === false);
  };

  setAnswer = (optionId: string) => {
    return;
  };

  getStartLabel = (): string | undefined => {
    return undefined;
  };

  getEndLabel = (): string | undefined => {
    return undefined;
  };

  isScale = (): boolean => {
    return false;
  };

  isSingleSelect = (): boolean => {
    return false;
  };

  isMultiSelect = (): boolean => {
    return false;
  };
}

export default Question;
