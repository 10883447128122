import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { ActionPlanIllustration } from "../../styles/illustrations/ActionPlanIllustration";
import { EventsResponse } from "../../types/actionPlan";
import { ActionPlanTimeline } from "../ActionPlanTimeline/ActionPlanTimeline";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";

interface ActionPlanWidgetProps {
  surveyDispatch?: SurveyDispatch;
  eventsData?: EventsResponse[];
  error?: boolean;
  isLoading?: boolean;
  onActionPlanUpdate?: () => void;
}
export const ActionPlanWidget = ({
  surveyDispatch,
  eventsData,
  error,
  isLoading,
  onActionPlanUpdate,
}: ActionPlanWidgetProps) => {
  const { t } = useTranslation("dashboardPage");
  return (
    <DashboardWidget
      title={t("actionPlanWidget.title")}
      icon={ActionPlanIllustration}
      className="ActionPlanWidget"
    >
      <ActionPlanTimeline
        surveyDispatch={surveyDispatch}
        eventsData={eventsData}
        error={error}
        isLoading={isLoading}
        onActionPlanUpdate={onActionPlanUpdate}
      />
    </DashboardWidget>
  );
};
