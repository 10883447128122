import EditIcon from "@mui/icons-material/Edit";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import { CustomQuestion } from "../../types/survey";
import { CustomQuestionsResponse } from "../../types/survey";
import { ScreeningQuestionForm } from "../ScreeningQuestionForm/ScreeningQuestionForm";
import { Button } from "../ui/Button/Button";
import { Modal } from "../ui/Modal/Modal";
import { Typography } from "../ui/Typography/Typography";
import { NavArrow } from "../ui/icons/NavArrow";
import "./ScreeningQuestionOverview.scss";

export const ScreeningQuestionOverview = () => {
  const { t } = useTranslation("launchSurveyPage");
  const surveyService = new SurveyService();
  const [editingQuestion, setEditingQuestion] = useState<CustomQuestion>();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleQuestionEdit = (
    e: React.MouseEvent<HTMLButtonElement>,
    questionPk: string
  ) => {
    e.stopPropagation();
    setEditingQuestion(
      questionsData?.questions.find((question) => question.pk === questionPk)
    );
    setEditModalOpen(true);
  };

  const { data: questionsData, refetch: refetchQuestions } = useQuery<
    CustomQuestionsResponse,
    AxiosError
  >(["customQuestions"], () => surveyService.getCustomQuestions());

  return (
    <div className="ScreeningQuestionOverview">
      <Typography
        tagVariant="p"
        desktop="body1"
        className="ScreeningQuestionOverview__subtitle"
      >
        {t("addQuestions.subtitle")}
      </Typography>
      {questionsData?.questions && questionsData.questions.length > 0 && (
        <Typography
          tagVariant="h3"
          desktop="h2"
          className="ScreeningQuestionOverview__title"
        >
          {t("addQuestions.addedQuestions.title")}
        </Typography>
      )}
      <div className="ScreeningQuestionOverview__questionList">
        {questionsData?.questions.map((question, index) => (
          <Accordion key={question.text}>
            <AccordionSummary
              expandIcon={
                <Button icon color="secondary">
                  <NavArrow />
                </Button>
              }
              aria-controls={`question${index}-content`}
              id={`question${index}-header`}
            >
              <div className="ScreeningQuestionOverview__questionList__header">
                <Typography desktop="body1" weight="bold">
                  {t("addQuestions.question.title") + " " + (index + 1)}:
                </Typography>
                <Typography desktop="body1">
                  <span style={{ marginRight: "0.5rem" }}></span>
                  {question.text}
                </Typography>
              </div>

              <Button
                icon
                color="secondary"
                onClick={(e) => handleQuestionEdit(e, question.pk || "")}
              >
                <EditIcon />
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <Typography desktop="body1" weight="bold">
                {t("addQuestions.addedQuestions.listOfOptions")}:
              </Typography>
              <ol>
                {question?.options?.map((option) => (
                  <li key={option.text}> {option.text}</li>
                ))}
              </ol>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <ScreeningQuestionForm
        questions={questionsData?.questions}
        refetchQuestions={refetchQuestions}
      />

      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        className="ScreeningQuestionOverview__modal"
      >
        <ScreeningQuestionForm
          questions={questionsData?.questions}
          questionData={editingQuestion}
          refetchQuestions={refetchQuestions}
        />
      </Modal>
    </div>
  );
};
