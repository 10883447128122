import { grey4 } from "../../styles/theme";
import {
  PipelineFilter,
  PipelineGroup,
  PipelineLevel,
} from "../../types/pipeline";
import { ageGroupOrder } from "../../utils/DiversityRatio.utils";
import { graphColors } from "../../utils/graphs";

export const sortFilters = (
  filters: PipelineFilter[],
  t: (str: string, options?: any) => string
) => {
  const sortLevels = (a: PipelineLevel, b: PipelineLevel) => b.order - a.order;

  filters.forEach((filter) => {
    filter.levels.sort(sortLevels);
    filter.levels.forEach((level) => {
      level.groups.sort((a, b) => a.label.localeCompare(b.label));
      level.groups.forEach((group) => {
        group.label = t(`chart.filters.${group.label}`, group.label);
      });
    });
  });
  return filters;
};

export const transformLevelDataToBarChartValue = (
  levelData: PipelineLevel,
  pipelineGroupColors?: { [key: string]: string }
): Highcharts.SeriesOptionsType[] => {
  return levelData.groups.map((group, i) => {
    return {
      type: "column",
      name: group.label,
      data: [group.count],
      color: pipelineGroupColors?.[group.label] || graphColors[i],
    };
  });
};

export const transformLevelDataToPipelineChartValue = (
  levelData: PipelineLevel,
  levelIndex: number,
  pipelineGroupColors?: { [key: string]: string },
  small?: boolean
): Highcharts.SeriesOptionsType[] => {
  let groupHeight = 0;

  const slopes = [2, 10, 5];
  if (levelIndex > 2) {
    [...Array(levelIndex - 2)].map(() => slopes.push(5));
  }

  // sum of the slopes of the previous levels
  let prevLevelsSlope =
    levelIndex === 0 ? 0 : slopes.slice(0, levelIndex).reduce((a, b) => a + b);

  let levelY1 = 5 + prevLevelsSlope;
  const levelHeight = 100 - prevLevelsSlope - levelY1;

  return levelData.groups.map((group, i) => {
    const ratio = group.count / levelData.count;

    if (i === 0) {
      groupHeight += levelHeight * ratio - 5;
    } else {
      groupHeight += levelHeight * ratio;
    }

    let groupY2 = groupHeight + levelY1;

    const y2 = levelY1 + slopes[levelIndex];
    // y3 should not be less than y2
    const y3 =
      groupY2 - slopes[levelIndex] < y2 ? y2 : groupY2 - slopes[levelIndex];

    const data = [
      {
        x: 0,
        y: levelY1,
        ratio: ratio * 100,
        dataLabels: {
          enabled: i === 0,
          x: small ? 69 : 75,
          y: -groupHeight + 10,
        },
      },
      { x: 5, y: y2 },
      {
        x: 5,
        y: y3,
        ratio: ratio * 100,
        dataLabels: { enabled: i !== 0, x: small ? -65 : -75, y: ratio * 50 },
      },
      { x: 0, y: groupY2 },
    ];
    return {
      type: "polygon",
      name: group.label,
      data: data,
      color: pipelineGroupColors?.[group.label] || graphColors[i],
      zIndex: 100 - i,
    };
  });
};
export const getPipelineLevelChartData = (
  levelData: PipelineLevel,
  levelIndex: number,
  isPipelineView: boolean,
  small?: boolean,
  pipelineGroupColors?: { [key: string]: string }
): Highcharts.Options => {
  const series: Highcharts.SeriesOptionsType[] = isPipelineView
    ? transformLevelDataToPipelineChartValue(
        levelData,
        levelIndex,
        pipelineGroupColors,
        small
      )
    : transformLevelDataToBarChartValue(levelData, pipelineGroupColors);

  const options: Highcharts.Options = {
    title: {
      text: levelData.label,
      style: {
        fontSize: "12px",
        fontWeight: "bold",
        fontFamily: "inherit",
        textTransform: "capitalize",
        color: grey4,
      },
      margin: 25,
      y: 0,
    },
    subtitle: {
      text: String(levelData.count),
      style: {
        fontSize: "12px",
        fontWeight: "regular",
        fontFamily: "inherit",
      },
      y: 30,
    },
    chart: {
      type: "column",
      borderRadius: 24,
      height: small ? 230 : 300,
      width: small ? 130 : 200,
      backgroundColor: "transparent",
    },
    xAxis: {
      categories: [""],
      lineWidth: 0,
      visible: false,
    },
    yAxis: {
      min: 0,
      visible: false,
      reversedStacks: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "percent",
        events: {
          legendItemClick: (e) => e.preventDefault(),
        },
      },
      column: {
        pointWidth: small ? 120 : 150,
        borderRadius: { radius: 24, where: "all", scope: "stack" },
        dataLabels: {
          enabled: true,
          align: "center",
          format: "{point.percentage:.0f}%",
          style: {
            textOutline: "0px",
            fontWeight: "bold",
            fontSize: "12px",
            color: "white",
          },
        },
      },
      polygon: {
        lineWidth: 20,
        enableMouseTracking: false,
        dataLabels: {
          format: "{point.ratio:.0f}%",
          style: {
            textOutline: "0px",
            fontWeight: "bold",
            fontSize: "12px",
            color: "white",
          },
        },
      },
    },
    series: series,
  };

  return options;
};

export const getPipelineGroupColor = (filter: PipelineFilter) => {
  const uniqueGroups: { [key: string]: PipelineGroup } = {};
  filter.levels.forEach((level) => {
    level.groups.forEach((group) => {
      uniqueGroups[group.label] = group;
    });
  });

  const sortedGroups = Object.values(uniqueGroups);

  if (filter.label === "age") {
    sortedGroups.sort((a, b) => {
      const aIndex =
        ageGroupOrder.indexOf(a.label) !== -1
          ? ageGroupOrder.indexOf(a.label)
          : ageGroupOrder.length;
      const bIndex =
        ageGroupOrder.indexOf(b.label) !== -1
          ? ageGroupOrder.indexOf(b.label)
          : ageGroupOrder.length;
      return aIndex - bIndex;
    });
  }
  return sortedGroups.reduce((acc: { [key: string]: string }, group, index) => {
    acc[group.label] = graphColors[index % graphColors.length];
    return acc;
  }, {});
};
