import { IconTypes } from "../components/ui/icons";

export enum TextColor {
  Dark = "dark",
  Light = "light",
}
export interface ScaleValue {
  label: string;
  score: number;
  scoreLabel?: string;
  color?: string;
  colorText?: TextColor;
  icon?: IconTypes;
}

export interface ScaleValueGroup<T> {
  [key: string]: T[];
}

export interface ScaleRailProps {
  showLabels?: boolean;
  small?: boolean;
  maxValue?: number;
  minValue?: number;
}
