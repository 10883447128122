import { EventsResponse, SurveyType } from "../../types/actionPlan";
import { TrainingAudience } from "../../types/training";

export const timelineDataResponse: EventsResponse[] = [
  {
    date: "2024-01-01",
    label: "Launch Ok Boomer",
    meta: {
      id: "1",
      type: "training",
      module: "TM.001",
      audience: TrainingAudience.Management,
    },
  },
  {
    date: "2024-01-09",
    label: "Launch Allyship",
    meta: {
      id: "2",
      type: "training",
      module: "TM.002",
      audience: TrainingAudience.All,
    },
  },
  {
    date: "2024-01-28",
    label: "Reminder 1",
    meta: {
      id: "1",
      type: SurveyType.Reminder,
      survey: "SUR.001",
      dispatch: null,
      related_id: null,
    },
  },
  {
    date: "2024-01-19",
    label: "Launch survey",
    meta: {
      id: "1",
      type: SurveyType.Launch,
      survey: "SUR.001",
      dispatch: null,
      related_id: null,
    },
  },
  {
    date: "2024-02-03",
    label: "Survey due date",
    meta: {
      id: "1",
      type: SurveyType.DueDate,
      survey: "SUR.001",
      dispatch: null,
      related_id: null,
    },
  },
  {
    date: "2024-02-13",
    label: "Launch Privilege Awareness",
    meta: {
      id: "6",
      type: "training",
      module: "TM.003",
      audience: TrainingAudience.All,
    },
  },
  {
    date: "2024-02-23",
    label: "Launch Leading Diverse Teams",
    meta: {
      id: "7",
      type: "training",
      module: "TM.004",
      audience: TrainingAudience.Management,
    },
  },
];

export const todoDataResponse = [
  {
    label: "Connect to HRIS",
    description:
      "In order to get the complete real-time DEI Health for your organisation, connect your HRIS.",
    isChecked: false,
    action: "integrations",
  },
  {
    label: "Promote Alda to employees",
    description:
      "In order to kick-off your DEI inititative, make sure your organisation is prepped and descriptionrmed. Don’t worry - Alda’s got you covered with some super simple and engaging promo material.",
    isChecked: false,
  },
  {
    label: "Setup survey",
    description:
      "The Inclusion Survey measures how inclusive your workplace truly is. In order to make the survey fit your organisation, please set up your preferences, recipients and survey timeline.",
    isChecked: false,
    action: "launch",
  },
  {
    label: "Get survey results",
    description:
      "Your survey results will be displayed in the Inclusion Index metric.",
    isChecked: false,
    action: "inclusionIndex",
  },
  {
    label: "Promote survey results",
    description:
      "Alda encourages DEI leaders to present the Inclusion Survey results and action plan to leadership and all employees.",
    isChecked: false,
  },
];
