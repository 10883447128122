import Container from "@mui/material/Container";
import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { PublicRoutes } from "../utils/routes.utils";
import { Breadcrumbs } from "./Breadcrumbs/Breadcrumbs";
import { Navigation } from "./Navigation/Navigation";
import { LoadingScreen } from "./ui/LoadingScreen";

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { loadingAuth, isLoggedIn } = useAuth();
  const location = useLocation();

  if (loadingAuth) {
    return <LoadingScreen active={loadingAuth} />;
  }

  if (!loadingAuth && !isLoggedIn()) {
    return (
      <Navigate to={PublicRoutes.LOGIN} replace state={{ from: location }} />
    );
  }

  return (
    <>
      <Navigation />
      <Container maxWidth="xl">
        <Breadcrumbs />
      </Container>
      <Container maxWidth="xl" className="Page">
        {children}
      </Container>
    </>
  );
};
