import useTranslation from "../../hooks/useTranslation";
import { ILevel } from "../../types/pipeline";
import "./LevelPicker.scss";

export interface LevelPickerProps {
  levels: ILevel[];
  onChange: (level: number | null) => void;
}
export const LevelPicker = ({ levels, onChange }: LevelPickerProps) => {
  const { t } = useTranslation("pipelinePage");

  return (
    <div className="LevelPicker">
      <select
        className="LevelPicker__select"
        onChange={(e) => {
          const selectedValue = e.target.value;
          const level = levels.find((lvl) => lvl.name === selectedValue);
          onChange(level ? level.id : null);
        }}
        required
      >
        <option value="" className="LevelPicker__option">
          {t("setup.choose")}
        </option>
        {levels.map((level) => (
          <option value={level.name} key={level.name}>
            {level.name}
          </option>
        ))}
      </select>
    </div>
  );
};
