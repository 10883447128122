import {
  TooltipProps as MUITooltipProps,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import "./ChartTooltip.scss";

interface ChartTooltipValue {
  label: string;
  countString: string;
  ratio: number;
  color?: string;
}

interface ChartTooltipProps extends Omit<MUITooltipProps, "title"> {
  chartValue?: ChartTooltipValue;
}

// We don't have access to the MUI auto generated classes in the scss files
// and we cant overwrite them so we must style here
const MuiTooltip = styled(({ className, ...props }: MUITooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    borderRadius: 12,
  },
}));

export const ChartTooltip = ({
  children,
  chartValue,
  ...rest
}: ChartTooltipProps) => {
  const renderTooltipTitle = (chartValue: ChartTooltipValue) => {
    return (
      <div className="ChartTooltip">
        <div
          className="ChartTooltip__color"
          style={{ backgroundColor: chartValue.color }}
        ></div>
        <div className="ChartTooltip__info">
          <Typography className="ChartTooltip__info__label">
            {chartValue.label}
          </Typography>
          <Typography className="ChartTooltip__info__count">
            {chartValue.countString}
          </Typography>
        </div>
        <div className="ChartTooltip__ratio">
          <Typography>{`${chartValue.ratio}%`}</Typography>
        </div>
      </div>
    );
  };
  return (
    <MuiTooltip
      title={chartValue ? renderTooltipTitle(chartValue) : ""}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};
