import { IconTypes } from "../components/ui/icons";
import { roseOrange, stoneBlue } from "../styles/theme";
import {
  EventMeta,
  EventsResponse,
  SurveyMeta,
  TrainingMeta,
  todoLink,
} from "../types/actionPlan";
import { ScaleValue, TextColor } from "../types/scale";
import { TrainingAudience } from "../types/training";
import { getFormattedDate } from "./formatters";
import { graphColors } from "./graphs";

export const isTrainingMeta = (meta: EventMeta): meta is TrainingMeta => {
  return meta.type === "training";
};

export const isSurveyMeta = (meta: EventMeta): meta is SurveyMeta => {
  return (
    meta.type === "survey-launch" ||
    meta.type === "survey-reminder" ||
    meta.type === "survey-due-date"
  );
};

export interface TimelineValue extends ScaleValue {
  date: Date;
  meta: EventMeta;
  href: string;
}

export const getTimelineValues = (
  data: EventsResponse[],
  t: (str: string) => string,
  language: string
) => {
  const sortedData = data.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });

  return sortedData.map((item, i) => {
    const [color, textColor] = getEventColor(item.meta);
    const date = item.date ? new Date(item.date) : new Date(0);
    const formattedDate = item.date
      ? getFormattedDate(date, t, language)
      : t("actionPlan.timeline.notSetUp");
    return {
      label: item.label,
      date: date,
      meta: item.meta,
      href: item.meta?.type === "training" ? "/training" : "/launch",
      scoreLabel: formattedDate,
      score: i,
      color: color,
      colorText: textColor,
      icon: getEventIcon(item.meta),
    };
  });
};

const getEventColor = (meta: EventMeta): [string, TextColor] => {
  if (isTrainingMeta(meta)) {
    return meta.audience === TrainingAudience.Management
      ? [stoneBlue, TextColor.Light]
      : [graphColors[2], TextColor.Dark];
  }
  return [roseOrange, TextColor.Light];
};

const getEventIcon = (meta: EventMeta) => {
  if (isTrainingMeta(meta)) {
    return meta.audience === TrainingAudience.Management
      ? IconTypes.ChatPerson
      : IconTypes.ManyPeople;
  }
  return IconTypes.Shuttle;
};

export const updateScores = (values: TimelineValue[]) => {
  const sortedValues = values.sort((a, b) => {
    return a.date.getTime() - b.date.getTime();
  });

  return sortedValues.map((value, index) => ({
    ...value,
    score: index,
  }));
};

export const updateEventDate = (
  val: TimelineValue,
  newDate: Date,
  t: (str: string) => string,
  language: string
) => {
  return {
    ...val,
    date: newDate,
    scoreLabel: getFormattedDate(newDate, t, language),
  };
};

export const todoLinkMappings: todoLink = {
  training: "/training",
  launch: "/launch",
  integrations: "/settings",
  inclusionIndex: "/workplace-culture",
};
