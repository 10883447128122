import { Chip as MUIChip, ChipProps as MUIChipProps } from "@mui/material";
import classNames from "classnames";
import "./Chip.scss";

export const Chip = ({
  className,
  onDelete = undefined,
  ...rest
}: MUIChipProps) => {
  const classes = classNames("Chip", className);
  return <MUIChip className={classes} {...rest} />;
};
