import {
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { ReactElement } from "react";
import { coalColor } from "../../../styles/theme";

export interface ScaleAverageTooltipProps {
  title: string;
  value: number | string;
  tooltipColor?: string;
  tooltipColorText?: "dark" | "light";
  children: ReactElement;
}

// We don't have access to the MUI auto generated classes in the scss files
// and we cant overwrite them so we must style here
const MuiToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
    height: "130px",
    top: "56px",
    "&:before": {
      height: "100%",
      width: "2px",
      transform: "none",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: "20px",
    padding: "0.5rem 1rem",
    minWidth: "20px",
    minHeight: "20px",
    textAlign: "center",
  },
  [`&.MuiTooltip-popper[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
    {
      marginTop: "90px",
      ".ScaleAverageTooltip__label": {
        top: "unset",
        bottom: "-24px",
      },
    },
  [`&.MuiTooltip-popper[data-popper-placement*="bottom"] .${tooltipClasses.arrow}`]:
    {
      top: "-67px",
      height: "74px",
    },
  [`&.MuiTooltip-popper[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
    {
      marginBottom: "7rem",
    },
}));

export const ScaleAverageTooltip = ({
  title,
  value,
  children,
  tooltipColor,
  tooltipColorText = "light",
}: ScaleAverageTooltipProps) => {
  const tooltipData = (
    <div className="ScaleAverageTooltip__label">
      <Typography variant="h6">{title}</Typography>
      <Typography sx={{ fontWeight: 600 }}>{value}</Typography>
    </div>
  );

  return (
    <MuiToolTip
      open
      placement="top"
      title={tooltipData}
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          background: tooltipColor,
          borderColor: tooltipColor,
          color: tooltipColorText === "dark" ? coalColor : "fff",
        },
      }}
      className="ScaleAverageTooltip"
      PopperProps={{
        modifiers: [
          {
            name: "flip",
            enabled: false,
          },
        ],
      }}
    >
      {children}
    </MuiToolTip>
  );
};
