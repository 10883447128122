import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { IntegrationService } from "../api/IntegrationService";
import { PayEquityService } from "../api/PayEquityService";
import { PayEquityOverview } from "../components/PayEquityOverview/PayEquityOverview";
import { IntegrationResponse } from "../types/integration";
import { GetPayEquityResponse } from "../types/payEquity";

export const PayEquityPage = () => {
  const payEquityService = new PayEquityService();
  const integrationService = new IntegrationService();
  const {
    data: payEquityData,
    isLoading,
    error,
  } = useQuery<GetPayEquityResponse, AxiosError>(
    ["payEquity"],
    () => payEquityService.getPayEquity(),
    { cacheTime: 60 * 60 * 1000 } // increase cache time to 60 minutes
  );

  const { error: integrationError } = useQuery<IntegrationResponse, AxiosError>(
    ["integration"],
    () => integrationService.getIntegration()
  );

  return (
    <div className="PayEquityPage">
      <PayEquityOverview
        payEquityData={payEquityData}
        error={!!error}
        isLoading={isLoading}
        noIntegration={integrationError?.response?.status === 404}
      />
    </div>
  );
};
