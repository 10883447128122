import { Box, FormControl, MenuItem, SelectChangeEvent } from "@mui/material";
import { useLanguages } from "../../hooks/useLanguages";
import useTranslation from "../../hooks/useTranslation";
import {
  AVAILABLE_SURVEY_LANGUAGES,
  AvailableSurveyLanguages,
} from "../../types/locale";
import i18n from "../../utils/i18n";
import { Select } from "../ui/Select/Select";

export interface SurveyLanguagePickerProps {
  className?: string;
}

export const SurveyLanguagePicker = ({
  className,
}: SurveyLanguagePickerProps) => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguages();

  const handleLanguageChange = (e: SelectChangeEvent<unknown>) => {
    const lang = e.target.value as AvailableSurveyLanguages;
    changeLanguage(lang);
    // We have to reload the window to update the translations
    window.location.reload();
  };

  return (
    <Box>
      <FormControl size="small">
        <Select
          labelId="language-selector"
          id="language-selector"
          value={i18n.language}
          onChange={handleLanguageChange}
          className={className}
        >
          {AVAILABLE_SURVEY_LANGUAGES.map((lang) => (
            <MenuItem value={lang} key={lang}>
              {t(`navigation.languages.${lang}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
