import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import { secondaryColor } from "../../styles/theme";
import { CustomQuestion, ICustomQuestionOption } from "../../types/survey";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Input } from "../ui/Input/Input";
import { Typography } from "../ui/Typography/Typography";
import { TrashIcon } from "../ui/icons/TrashIcon";
import "./ScreeningQuestionForm.scss";

export interface ScreeningQuestionFormProps {
  questions?: CustomQuestion[];
  questionData?: CustomQuestion;
  refetchQuestions?: () => void;
}
export const ScreeningQuestionForm = ({
  questions,
  questionData,
  refetchQuestions,
}: ScreeningQuestionFormProps) => {
  const { t } = useTranslation("launchSurveyPage");
  const surveyService = new SurveyService();
  const [question, setQuestion] = useState<string>(questionData?.text || "");
  const [label, setLabel] = useState<string>(questionData?.label || "");
  const [options, setOptions] = useState<ICustomQuestionOption[]>(
    questionData?.options || []
  );
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const addQuestionMutation = useMutation({
    mutationFn: () =>
      surveyService.addCustomQuestion({
        text: question,
        label: label,
        options: options,
      }),
  });

  const isDuplicateOptions = () => {
    const optionTexts: string[] = [];
    return options.some((option) => {
      if (optionTexts.includes(option.text)) {
        return true;
      }
      optionTexts.push(option.text);
      return false;
    });
  };

  const isValidOptions = () => {
    if (isDuplicateOptions()) {
      setErrorMsg(t("addQuestions.errors.duplicate") || "");
      return false;
    } else if (options.length === 0) {
      setErrorMsg(t("addQuestions.errors.noOptions") || "");
      return false;
    }
    return true;
  };

  const updateQuestionMutation = useMutation({
    mutationFn: () =>
      surveyService.updateCustomQuestion(questionData?.pk || "", {
        text: question,
        label: label,
        options: options,
      }),
  });

  const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    setSuccessMsg("");
    setQuestion(e.target.value);
  };

  const handleLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    setSuccessMsg("");
    setLabel(e.target.value);
  };

  const handleOptionLabelChange = (newValue: string, index: number) => {
    setErrorMsg("");
    setSuccessMsg("");
    const newOptions = [...options];
    newOptions[index].label = newValue;
    setOptions(newOptions);
  };

  const handleOptionChange = (newValue: string, index: number) => {
    setErrorMsg("");
    setSuccessMsg("");
    const newOptions = [...options];
    newOptions[index].text = newValue;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    const newOptions = [...options];
    newOptions.push({ pk: null, text: "", label: "" });
    setOptions(newOptions);
  };

  const handleKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      handleAddOption();
    }
  };

  const handleDeleteOption = (optionIndex: number) => {
    const newOptions = [...options];
    newOptions.splice(optionIndex, 1);
    setOptions(newOptions);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidOptions()) {
      questionData ? handleUpdateQuestion(e) : handleAddQuestion(e);
    }
  };
  const handleAddQuestion = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await addQuestionMutation.mutateAsync(undefined, {
      onSuccess: () => {
        setQuestion("");
        setLabel("");
        setOptions([]);
        refetchQuestions?.();
        (e.target as HTMLFormElement).reset();
        setSuccessMsg(t("addQuestions.success") || "");
      },
      onError: () => {
        setErrorMsg(t("addQuestions.errors.submit") || "");
      },
    });
  };

  const handleUpdateQuestion = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await updateQuestionMutation.mutateAsync(undefined, {
      onSuccess: () => {
        refetchQuestions?.();
        setSuccessMsg(t("addQuestions.updateSuccess") || "");
      },
      onError: () => {
        setErrorMsg(t("addQuestions.errors.submit") || "");
      },
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="ScreeningQuestionForm">
        <div className="ScreeningQuestionForm__item">
          <Grid container spacing={1} marginBottom={6}>
            <Grid item md={9}>
              <Input
                id="question"
                value={question}
                onChange={handleQuestionChange}
                className="ScreeningQuestionForm__item__question"
                placeholder={t("addQuestions.question.placeholder")}
                multiline
                fullWidth
                label={
                  t("addQuestions.question.title") +
                  " " +
                  (questionData ? "" : questions && questions.length + 1)
                }
                required
              />
            </Grid>
            <Grid item md={3}>
              <Input
                id="question-label"
                value={label}
                onChange={handleLabelChange}
                onKeyDown={handleKeyDown}
                fullWidth
                placeholder={t("addQuestions.questionLabel.placeholder")}
                label={
                  t("addQuestions.questionLabel.title") +
                  " " +
                  (questionData ? "" : questions && questions.length + 1)
                }
                required
              />
            </Grid>
          </Grid>
          <div className="ScreeningQuestionForm__options">
            <div>
              {options.map((option, index) => (
                <div key={`option-label-${index}`}>
                  <Grid container spacing={1} marginBottom={3}>
                    <Grid item md={8}>
                      <Input
                        id={`option-label-${index}`}
                        value={option.text}
                        onChange={(e) =>
                          handleOptionChange(e.target.value, index)
                        }
                        fullWidth
                        label={
                          t("addQuestions.options.title") + " " + (index + 1)
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      className="ScreeningQuestionForm__item__withButton"
                    >
                      <Input
                        id={`optionLabel-${index}`}
                        value={option.label}
                        onChange={(e) =>
                          handleOptionLabelChange(e.target.value, index)
                        }
                        onKeyDown={handleKeyDown}
                        required
                        label={
                          t("addQuestions.label.title") + " " + (index + 1)
                        }
                      />
                      <Button
                        icon
                        color="secondary"
                        aria-label="Delete this option"
                        onClick={() => handleDeleteOption(index)}
                      >
                        <TrashIcon color={secondaryColor} size={16} />
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </div>
          </div>
          <Card
            className="ScreeningQuestionForm__options__action"
            inactive
            onClick={handleAddOption}
            aria-label={t("actions.addOption")}
          >
            + {t("addQuestions.options.add")}
          </Card>
        </div>

        <div className="ScreeningQuestionForm__button">
          <Button variant="contained" type="submit">
            {questionData
              ? t("actions.updateQuestion")
              : t("actions.addQuestion")}
          </Button>
          {successMsg && (
            <Typography desktop="caption">{successMsg}</Typography>
          )}
          {errorMsg && (
            <Typography desktop="caption" color="error">
              {errorMsg}
            </Typography>
          )}
        </div>
      </form>
    </div>
  );
};
