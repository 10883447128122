import classnames from "classnames";
import { coalColor } from "../../../styles/theme";
import { Direction, IconProps } from "./";
import "./ThumbIcon.scss";

export const ThumbIcon = ({ className, direction }: IconProps) => {
  // Default direction is down
  const classes = classnames("ThumbIcon", className, {
    "ThumbIcon--up": direction === Direction.UP,
    "ThumbIcon--right": direction === Direction.RIGHT,
    "ThumbIcon--left": direction === Direction.LEFT,
  });

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path
        d="M16.5302 27.7031C19.7974 27.7031 20.0303 20.2031 20.5303 15.2031C20.5303 16.7029 21.8037 19.2031 23.5303 19.2031C25.257 19.2031 27 17.5 26.5302 11.7034C25.9213 4.19057 23.5303 5.20312 21.5302 9.20338C20.0302 6.20312 15.1969 4.70338 12.0302 4.20338C10.3635 3.87005 5.93021 4.70338 5.53021 6.70338C5.13021 8.70338 8.03021 8.87005 9.03021 9.20338C7.86354 9.03672 4.43021 9.30338 4.03021 11.7034C3.63021 14.1034 8.29169 14.2905 9.53021 14.7034C8.5304 14.7031 5.30645 15.3229 5.0304 16.7031C4.3676 20.0171 10.0303 19 13.0302 19C16.1925 19 11.7845 27.7031 16.5302 27.7031Z"
        stroke={coalColor}
        strokeWidth="2"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
