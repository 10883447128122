import { IllustrationProps } from "../../types/illustration";

export const ActionPlanIllustration = ({
  className,
  size = 36,
}: IllustrationProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 154 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1620_9425)">
        <path
          d="M126.739 1.32568C125.179 0.25519 123.085 0.0963706 120.843 0.880694C119.541 1.3568 118.286 1.95156 117.092 2.65742L114.238 4.28449C108.938 7.30364 103.458 10.4251 98.1289 13.6044C95.4018 15.2267 92.7942 17.0417 90.3263 19.0356C89.2198 20.056 87.7673 20.619 86.2623 20.6109H86.2236C76.3411 20.563 66.2939 20.5682 56.5799 20.5734L46.4834 20.5774V20.796L36.3206 20.983C26.7901 21.158 17.259 21.3377 7.72729 21.5218C3.97826 21.5966 1.87734 23.2769 1.11533 26.8113C0.573428 29.1497 0.262338 31.5353 0.186589 33.9344C0.0671356 40.7216 0.120973 47.6913 0.172167 53.8399C0.205641 58.0365 1.95291 60.0575 6.01367 60.6002C9.26699 61.0706 12.5491 61.313 15.8363 61.3255C21.0266 61.2697 26.2997 61.1003 31.3988 60.9375C34.0714 60.8516 36.7433 60.7663 39.4159 60.6961L43.4964 60.5884C58.0526 60.2044 73.1054 59.8066 87.9104 59.4896C89.4757 59.4206 91.0378 59.686 92.4929 60.2681C97.844 62.6525 103.253 65.1546 108.485 67.5741C111.403 68.9235 114.323 70.269 117.245 71.6106C118.403 72.1245 119.604 72.5327 120.835 72.832C121.512 73.0191 122.21 73.1208 122.912 73.1339C124.782 73.1339 125.943 72.1777 126.719 70.1148C127.537 67.8452 128.002 65.4623 128.097 63.0509L128.145 62.1597C128.697 51.6359 129.268 40.7531 129.511 30.0371C129.691 22.0875 129.434 14.1229 129.183 6.42079C129.116 4.16692 128.249 2.36269 126.739 1.32568ZM121.095 10.8248L118.811 61.0951L96.4126 51.8938L94.6707 25.2019L121.095 10.8248ZM11.3377 53.576L7.70307 53.3313C6.65949 48.3799 6.75204 33.7402 7.85994 28.6267L12.5461 28.414L11.3377 53.576ZM86.9541 26.3814V51.2409L21.7433 53.5596C21.0567 48.4856 20.6657 43.376 20.5725 38.2565C20.4497 35.0405 20.3237 31.7213 20.0474 28.3792C30.915 27.5916 41.8917 27.5261 52.5126 27.4604C63.7924 27.3915 75.4485 27.3219 86.9515 26.3846L86.9541 26.3814Z"
          fill="#435869"
        />
        <path
          d="M148.502 28.9619C144.723 29.2986 140.991 29.9531 138.162 30.4854C137.329 30.606 136.569 31.0285 136.027 31.6726C135.485 32.3166 135.198 33.1374 135.221 33.9791C135.234 35.7512 136.371 37.0092 138.264 37.3525C139.539 37.5572 140.825 37.6905 142.114 37.7517C142.728 37.7924 143.369 37.8351 144.045 37.8935L144.117 37.9001L144.19 37.8895C144.662 37.8239 145.168 37.7739 145.695 37.7208C147.099 37.6186 148.491 37.3917 149.855 37.0428C152.183 36.3766 153.41 34.4298 152.909 32.1976C152.74 31.1909 152.185 30.2899 151.362 29.6858C150.539 29.0816 149.513 28.822 148.502 28.9619Z"
          fill="#E2EA5B"
        />
        <path
          d="M135.965 13.8998C136.136 13.9781 136.302 14.0656 136.462 14.1623L136.702 14.3185L136.979 14.2496C137.312 14.1669 137.612 14.1092 137.885 14.0527C138.416 13.9795 138.932 13.8261 139.415 13.5973L139.974 13.2986C142.802 11.7851 145.725 10.2211 148.516 8.5251C149.641 7.80424 150.582 6.83142 151.265 5.68375C151.594 5.20395 151.785 4.643 151.818 4.06197C151.85 3.48094 151.722 2.90214 151.447 2.38892C151.142 1.91893 150.712 1.54336 150.205 1.3041C149.698 1.06484 149.135 0.971358 148.577 1.03426C147.144 1.14318 145.755 1.58331 144.52 2.32001C142.381 3.5769 140.303 4.9992 138.294 6.37358L136.837 7.36463C136.005 7.83292 135.288 8.48034 134.736 9.26013C134.457 9.75206 134.28 10.2954 134.216 10.8575C134.152 11.4197 134.202 11.9891 134.362 12.5314C134.704 13.1739 135.277 13.663 135.965 13.8998Z"
          fill="#E2EA5B"
        />
        <path
          d="M146.744 56.7889C144.518 55.6074 142.186 54.5574 139.931 53.546L138.852 53.0603C138.007 52.6399 137.03 52.571 136.134 52.8686C135.827 52.9871 135.548 53.1669 135.313 53.3971C135.078 53.6273 134.893 53.9032 134.768 54.2076C134.328 55.2335 134.157 57.2694 134.996 58.2684C137.681 61.4615 141.292 63.5965 146.36 64.988L146.626 65.0607L146.865 64.921C147.072 64.8002 147.334 64.6828 147.612 64.5581C148.376 64.2161 149.242 63.8281 149.618 63.0648C150.129 62.0291 149.873 60.4296 149.356 59.3854C148.762 58.2797 147.853 57.3758 146.744 56.7889Z"
          fill="#E2EA5B"
        />
        <path
          d="M54.1564 49.0126C55.3378 49.0448 56.5337 49.0368 57.6902 49.0302L58.5952 49.0251L58.6196 49.3105L59.2811 49.245C59.9946 49.1754 60.7086 49.1137 61.4234 49.052C63.1213 48.905 64.8777 48.7533 66.5993 48.4954C66.9944 48.4624 67.379 48.3485 67.7282 48.1604C68.0774 47.9724 68.3846 47.7144 68.6294 47.4025C68.8748 47.0907 69.0534 46.7317 69.1538 46.3478C69.2542 45.964 69.2739 45.5636 69.2128 45.1717C69.1879 44.7759 69.0835 44.3892 68.905 44.035C68.7272 43.6807 68.4784 43.3663 68.1758 43.1105C67.8726 42.8548 67.5208 42.6629 67.1414 42.5468C66.7621 42.4307 66.3637 42.3927 65.9692 42.4348C62.3174 42.6317 57.9941 42.9132 53.6118 43.4777C52.9785 43.6586 52.4057 44.0069 51.9538 44.486C51.5019 44.9652 51.1876 45.5573 51.0441 46.2001C50.9986 46.5193 51.0215 46.8447 51.1114 47.1545C51.2012 47.4642 51.3559 47.7512 51.5652 47.9965C51.9042 48.3348 52.3093 48.5995 52.7551 48.7743C53.201 48.9492 53.6779 49.0303 54.1564 49.0126Z"
          fill="#435869"
        />
        <path
          d="M75.8072 47.9278H75.8151C76.9715 47.9278 79.377 47.9245 79.7386 45.2539C79.7938 44.8961 79.7708 44.5306 79.6697 44.1829C79.5693 43.8351 79.3941 43.5134 79.1565 43.2403C78.7233 42.8104 78.2048 42.4763 77.6338 42.2597C77.0634 42.043 76.4537 41.9487 75.8439 41.9827C74.2805 41.9631 73.1182 42.3069 72.4152 43.0125C72.1724 43.2599 71.9834 43.5547 71.8593 43.8784C71.7346 44.2021 71.6782 44.5478 71.6932 44.8941C71.7287 46.7378 73.3426 47.9278 75.8072 47.9278Z"
          fill="#435869"
        />
      </g>
      <defs>
        <clipPath id="clip0_1620_9425">
          <rect width="153.583" height="73.5098" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
