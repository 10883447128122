import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { RefreshAuthResponse } from "../providers/AuthProvider";
import {
  SURVEY_LANGUAGE_DEFAULT_LOCAL_STORAGE_KEY,
  SURVEY_LANGUAGE_LOCAL_STORAGE_KEY,
} from "../types/locale";
import { IUser } from "../types/user";

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "";
export const ACCESS_TOKEN_KEY = "accessToken";
export const USER = "user";
export const API_URL = `${process.env.REACT_APP_API_HOST_URL}/api`;
let authTokenRequest:
  | Promise<AxiosResponse<RefreshAuthResponse, any>>
  | undefined;

export const axiosDefaultClient = axios.create({
  baseURL: API_URL || "",
});

const addLanguageConfig = (config: InternalAxiosRequestConfig<any>) => {
  if (!config.headers) {
    config.headers = {} as AxiosHeaders;
  }

  const userStorage = localStorage.getItem(USER);
  if (userStorage) {
    const user: IUser = JSON.parse(userStorage);
    config.headers["Accept-Language"] = user.language;
  } else {
    let surveyLanguage = localStorage.getItem(
      SURVEY_LANGUAGE_LOCAL_STORAGE_KEY
    );

    if (!surveyLanguage) {
      surveyLanguage = localStorage.getItem(
        SURVEY_LANGUAGE_DEFAULT_LOCAL_STORAGE_KEY
      );
    }

    config.headers["Accept-Language"] = surveyLanguage;
  }

  return config;
};

export const handleRefreshToken = async (): Promise<
  AxiosResponse<RefreshAuthResponse>
> => {
  if (!authTokenRequest) {
    const request = axiosDefaultClient.post<RefreshAuthResponse>(
      "/auth/refresh/",
      new URLSearchParams({
        client_id: CLIENT_ID,
      }),
      {
        withCredentials: true,
      }
    );

    authTokenRequest = request;

    return new Promise<AxiosResponse<RefreshAuthResponse>>(
      (resolve, reject) => {
        request
          .then((res: AxiosResponse<RefreshAuthResponse>) => {
            if (res.data.access) {
              localStorage.setItem(ACCESS_TOKEN_KEY, res.data.access);
              authTokenRequest = undefined;
              resolve(res);
            }
          })
          .catch((err: AxiosError) => {
            // error in refreshing token results in logging out
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(USER);
            console.error("Error refreshing token", err, request);
            authTokenRequest = undefined;
            reject(err);
          });
      }
    );
  }

  return authTokenRequest;
};

export const getApiClient = (useAuth = true): AxiosInstance => {
  if (useAuth) {
    // Request interceptor for API calls
    axiosDefaultClient.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (!config.headers) {
          config.headers = {} as AxiosHeaders;
        }

        // we need to make sure the authorization header is not set for the
        // survey start endpoint because it will return a 401 if the browser
        // isn't logged in. this is because the survey start endpoint is
        // supposed to be public. This is a hack and should be fixed in the
        // future.

        // setting the authorization header to null will result in the server
        // returning a 401 even though the endpoint is public. if we skip
        // setting the authorization header when it is null, the server will
        // still return a 401 if the browser is not authorised for the action.
        // TODO: this code should still be up for review and refactoring when
        // we have time.
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return addLanguageConfig(config);
      },
      (error: AxiosError) => {
        Promise.reject(error);
      }
    );

    // Response interceptor for API calls
    axiosDefaultClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError) => {
        const originalRequest = error.config || ({} as AxiosRequestConfig<any>);

        if (
          error.response?.status === 401 &&
          error.config?.url !== "/auth/refresh/"
        ) {
          // request was not authorised, let's try to refresh the token
          await handleRefreshToken();

          const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

          if (accessToken) {
            if (!originalRequest?.headers) {
              originalRequest.headers = {};
            }

            originalRequest.headers.Authorization = "Bearer " + accessToken;

            //Hack as the headers have the wrong type from axiosError
            originalRequest.headers = (
              originalRequest.headers as unknown as AxiosHeaders
            ).toJSON();

            return axiosDefaultClient(originalRequest);
          }
        }

        // if retrying still returned with an unauthorized we should log the user out
        if (
          error.response?.status === 401 &&
          error.config?.url === "/auth/refresh/"
        ) {
          window.location.assign("/logout");
        } else {
          return Promise.reject(error);
        }
      }
    );
  } else {
    axiosDefaultClient.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        return addLanguageConfig(config);
      },
      (error: AxiosError) => {
        Promise.reject(error);
      }
    );
  }

  return axiosDefaultClient;
};
