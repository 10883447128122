import { SignUpSteps } from "../components/SignUpSteps/SignUpSteps";
import { Logo } from "../components/ui/Logo";
import "./SignUpPage.scss";

export const SignUpPage = () => (
  <div className="SignUpPage">
    <div className="SignUpPage__header">
      <Logo />
    </div>
    <SignUpSteps />
  </div>
);
