import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { IntegrationService } from "../../api/IntegrationService";
import useTranslation from "../../hooks/useTranslation";
import { AddKjarniIntegrationRequest } from "../../types/integration";
import { Button } from "../ui/Button/Button";
import { Input } from "../ui/Input/Input";
import { Modal } from "../ui/Modal/Modal";
import { Typography } from "../ui/Typography/Typography";
import "./IntegrationSetup.scss";

export interface IntegrationSetupProps {
  title: string;
  open: boolean;
  onClose: () => void | undefined;
  refetchIntegration: () => void;
}
export const KjarniSetup = ({
  title,
  open,
  onClose,
  refetchIntegration,
}: IntegrationSetupProps) => {
  const { t } = useTranslation("settingsPage");
  const [domain, setDomain] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const integrationService = new IntegrationService();

  const addKjarniIntegrationMutation = useMutation({
    mutationFn: (data: AddKjarniIntegrationRequest) =>
      integrationService.addKjarniIntegration(data),
  });

  const handleSubmit = async () => {
    const data = {
      domain: domain,
      username: username,
      password: password,
    };
    await addKjarniIntegrationMutation.mutateAsync(data, {
      onSuccess: () => {
        setSubmitSuccess(true);
        setSubmitError(false);
        refetchIntegration();
      },
      onError: () => {
        setSubmitError(true);
        setSubmitSuccess(false);
      },
    });
  };
  return (
    <Modal
      className="IntegrationSetup"
      open={open}
      onClose={onClose}
      title={
        <Typography tagVariant="h3" desktop="h3">
          {submitSuccess ? t("integration.setup.kjarni.success.title") : title}
        </Typography>
      }
    >
      {!submitSuccess && (
        <form className="IntegrationSetup__form">
          <Input
            type="text"
            id="kjarni-domain"
            value={domain}
            label={t("integration.setup.kjarni.domain")}
            onChange={(e) => setDomain(e.target.value)}
            required
          />
          <Input
            type="text"
            id="kjarni-username"
            value={username}
            label={t("integration.setup.username")}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <Input
            type={showPassword ? "text" : "password"}
            id="kjarni-password"
            data-testid="kjarni-password"
            value={password}
            label={t("integration.setup.password")}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {submitError && (
            <Typography tagVariant="p" desktop="caption">
              {t("integration.setup.error")}
            </Typography>
          )}
          <Button color="info" variant="contained" onClick={handleSubmit}>
            {t("buttonAction.submit")}
          </Button>
        </form>
      )}
      {submitSuccess && (
        <div className="IntegrationSetup__form">
          <div>
            <Typography tagVariant="p" desktop="body2" weight="regular">
              {t("integration.setup.kjarni.success.info")}
            </Typography>
          </div>

          <Button onClick={onClose} variant="contained" color="success">
            {t("buttonAction.close")}
          </Button>
        </div>
      )}
    </Modal>
  );
};
