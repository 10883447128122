import { IconProps } from ".";

export const BoxIcon = ({
  size = 16,
  color = "black",
  className,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="16" height="16" rx="4" fill={color} />
    </svg>
  );
};
