import { IconProps } from ".";
import { coalColor } from "../../../styles/theme";

export const ChatPersonIcon = ({
  className,
  color = coalColor,
  size = 14,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    className={className}
  >
    <path
      fill={color}
      d="M5 13.667A.667.667 0 0 1 4.333 13v-2H1.667A1.334 1.334 0 0 1 .333 9.667v-8A1.333 1.333 0 0 1 1.667.333h10.666a1.333 1.333 0 0 1 1.334 1.334v8A1.334 1.334 0 0 1 12.333 11H8.267L5.8 13.473a.68.68 0 0 1-.467.194H5Zm.667-4v2.053L7.72 9.667h4.613v-8H1.667v8h4Zm4-1.334H4.333v-.666c0-.887 1.78-1.334 2.667-1.334.887 0 2.667.447 2.667 1.334v.666ZM7 3a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 7 3Z"
    />
  </svg>
);
