import {
  Divider,
  Menu as MUIMenu,
  MenuProps as MUIMenuProps,
  MenuItem,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconTypes } from "../icons";
import { Icon } from "../icons/Icon";
import "./Menu.scss";

export interface IMenuItem {
  id?: string;
  onClick?: MouseEventHandler;
  text: string | ReactNode;
  icon?: ReactNode;
  selected?: boolean;
  path?: string;
}

interface MenuProps extends MUIMenuProps {
  headerItems?: IMenuItem[];
  itemHeader?: string;
  menuItems: IMenuItem[];
  skipDividers?: boolean;
  light?: boolean;
}

export const Menu = ({
  open,
  onClose,
  anchorEl,
  id,
  className,
  menuItems,
  headerItems,
  anchorOrigin = { horizontal: "right", vertical: "bottom" },
  itemHeader,
  skipDividers = false,
  transformOrigin,
  light,
  ...props
}: MenuProps) => {
  const classes = classNames("Menu", className, {
    "Menu--arrowLeft":
      anchorOrigin?.horizontal === "left" ||
      transformOrigin?.horizontal === "left",
    "Menu--arrowRight":
      anchorOrigin?.horizontal === "right" ||
      transformOrigin?.horizontal === "right",
    "Menu--light": light,
  });

  const renderItem = (item: IMenuItem, classes: string, key: string) => {
    return (
      <MenuItem
        className={classes}
        onClick={item.onClick}
        disableRipple={true}
        key={key}
        component={Link}
        to={item.path ? item.path : "#"}
      >
        {item.icon ? (
          <span className="Menu__item__icon">{item.icon}</span>
        ) : (
          <></>
        )}
        {item.text instanceof String ? (
          <Typography
            textAlign="center"
            sx={item.selected ? { fontWeight: 700 } : undefined}
          >
            {item.text}
          </Typography>
        ) : (
          item.text
        )}
        <Icon className="Menu__item__arrow" type={IconTypes.RedArrow} />
      </MenuItem>
    );
  };

  return (
    <MUIMenu
      className={classes}
      anchorEl={anchorEl}
      id={id}
      keepMounted
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      PaperProps={{
        elevation: 0,
      }}
      transformOrigin={transformOrigin}
      {...props}
    >
      {headerItems?.map((headerItem, i) => {
        const headerItemClasses = classNames("Menu__item", {
          "Menu__item--notClickable": !headerItem.onClick,
          "Menu__item--clickable": headerItem.onClick,
          "Menu__item--selected": headerItem.selected,
          "Menu__item--onlyChild": menuItems.length === 1,
        });
        return renderItem(headerItem, headerItemClasses, `headerItem-${i}`);
      })}

      {!skipDividers && headerItems?.length && <Divider />}

      {itemHeader && (
        <Typography variant="h5" color="secondary" className="Menu__header">
          {itemHeader}
        </Typography>
      )}

      {menuItems.map((menuItem, i) => {
        const menuItemClasses = classNames("Menu__item", {
          "Menu__item--notClickable": !menuItem.onClick,
          "Menu__item--clickable": menuItem.onClick,
          "Menu__item--selected": menuItem.selected,
          "Menu__item--onlyChild": menuItems.length === 1,
        });
        return renderItem(menuItem, menuItemClasses, `menuItem-${i}`);
      })}
    </MUIMenu>
  );
};
