import {
  DiversityRatioBreakdownFilter,
  DiversityRatioGroup,
} from "../../types/diversityRatio";
import { graphColors } from "../../utils/graphs";

export const transformDiversityBreakdownToChartValue = (
  breakdownData: DiversityRatioGroup[],
  diversityBreakdownGroupColors?: { [key: string]: string }
): Highcharts.SeriesOptionsType[] => {
  return breakdownData
    .filter((group) =>
      diversityBreakdownGroupColors
        ? Object.keys(diversityBreakdownGroupColors).includes(group.label)
        : true
    )
    .map((group, i) => {
      return {
        type: "bar",
        name: group.label,
        data: [group.count],
        color: diversityBreakdownGroupColors?.[group.label] || graphColors[i],
      };
    });
};

export const getDiversityBreakdownChartData = (
  breakdownLabel: string,
  breakdownData: DiversityRatioGroup[],
  diversityBreakdownGroupColors?: { [key: string]: string }
): Highcharts.Options => {
  const series: Highcharts.SeriesOptionsType[] =
    transformDiversityBreakdownToChartValue(
      breakdownData,
      diversityBreakdownGroupColors
    );

  const options: Highcharts.Options = {
    title: {
      text: breakdownLabel,
      align: "left",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "inherit",
        textTransform: "uppercase",
      },
      margin: 0,
      y: 10,
    },
    chart: {
      type: "bar",
      height: 90,
      backgroundColor: "transparent",
    },
    xAxis: {
      categories: [""],
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      visible: false,
      reversedStacks: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "percent",
        events: {
          legendItemClick: (e) => e.preventDefault(),
        },
      },
      bar: {
        borderRadius: { radius: 8, where: "all", scope: "stack" },
        pointWidth: 32,
        dataLabels: {
          enabled: true,
          align: "right",
          format: "{point.percentage:.0f}%",
          style: {
            textOutline: "0px",
            fontWeight: "400",
          },
        },
      },
    },
    series: series,
  };

  return options;
};

// Take in breakdownFilters and return the three most populous groups in alphabetical order
export const getThreeMostPopulatedGroups = (
  breakdownFilters: DiversityRatioBreakdownFilter[]
): DiversityRatioBreakdownFilter[] => {
  return breakdownFilters
    .sort((a, b) => b.count - a.count)
    .slice(0, 3)
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
};
