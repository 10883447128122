import { Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import classNames from "classnames";

interface CircularProgressBarProps {
  className?: string;
  progress: number;
}

export const CircularProgressBar = ({
  progress,
  className,
}: CircularProgressBarProps) => {
  const classes = classNames("CircularProgressBar", className);
  return (
    <Box sx={{ position: "relative" }} className={classes}>
      <CircularProgress
        variant="determinate"
        color="secondary"
        sx={{
          color: (theme) => theme.palette.grey[300],
        }}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        color="error"
        sx={{
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        value={progress}
      />
    </Box>
  );
};
