import { Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { TrainingService } from "../api/TrainingService";
import { TrainingModuleCard } from "../components/TrainingModuleCard/TrainingModuleCard";
import useTranslation from "../hooks/useTranslation";
import TrainingModule from "../models/trainingModule";
import { TrainingAudience, TrainingModuleResponse } from "../types/training";
import "./TrainingPage.scss";

const loadingTrainingModules: TrainingModuleResponse = {
  reference_id: "",
  title: "",
  description: "",
  duration: "",
  thumbnail: "",
  tags: [""],
  audience: TrainingAudience.All,
  touchpoints: [
    {
      title: "",
      description: "",
      order: 1,
      duration: "",
      reference_id: "",
      thumbnail: "",
    },
  ],
  languages: [""],
  is_ready: false,
  toolbox: "",
};

export const loadingTrainingModulesMock = [
  loadingTrainingModules,
  loadingTrainingModules,
].map((module) => new TrainingModule(module));

export const TrainingPage = () => {
  const { t } = useTranslation("trainingPage");
  const trainingService = new TrainingService();
  const [trainingModules, setTrainingModules] = useState<TrainingModule[]>(
    loadingTrainingModulesMock
  );

  const { data: trainingModulesData, error: trainingModulesError } = useQuery<
    TrainingModuleResponse[],
    AxiosError
  >(["trainingModules"], () => trainingService.getTrainingModules(), {
    enabled: true,
  });

  useEffect(() => {
    if (trainingModulesData) {
      const trainingModulesFromResponse = trainingModulesData.map(
        (module) => new TrainingModule(module)
      );

      setTrainingModules(trainingModulesFromResponse);
    }
  }, [trainingModulesData]);

  useEffect(() => {
    if (trainingModulesError) {
      setTrainingModules([]);
    }
  }, [trainingModulesError]);

  const activeCourses = trainingModules.filter((obj) => obj.isReady === true);
  const inActiveCourses = trainingModules.filter((obj) => obj.isReady !== true);

  return (
    <div className="TrainingPage">
      {!!trainingModulesError && (
        <Typography variant="h2">{t("modules.error")}</Typography>
      )}

      <Grid container spacing={3} className="TrainingPage__container">
        {activeCourses?.map((trainingModule, i) => (
          <Grid item xs={12} sm={6} md={3} key={`${trainingModule.title}-${i}`}>
            <TrainingModuleCard
              trainingModule={trainingModule}
              isActive={true}
            />
          </Grid>
        ))}
        {inActiveCourses?.map((trainingModule, i) => (
          <Grid item xs={12} sm={6} md={3} key={`${trainingModule.title}-${i}`}>
            <TrainingModuleCard
              trainingModule={trainingModule}
              isActive={false}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
