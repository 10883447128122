export enum PublicRoutes {
  LOGIN = "/login",
  LOGOUT = "/logout",
  DEMO_LOGIN = "/demo",
  SIGNUP = "/signup",
}

export enum SurveyRoutes {
  PREVIEW_SURVEY = "/survey/preview",
  START_SURVEY = "/survey/start",
}

export enum ProtectedRoutes {
  DASHBOARD = "/dashboard",
  WORKPLACE_CULTURE = "/workplace-culture",
  DIVERSITY_RATIO = "/diversity-ratio",
  LAUNCH = "/launch",
  PAY_EQUITY = "/pay-equity",
  PIPELINE = "/pipeline",
  SETTINGS = "/settings",
  TRAINING = "/training",
}

export const isPublicRoute = (route: string): boolean => {
  return (
    Object.values(PublicRoutes).some((publicRoute) =>
      route.startsWith(publicRoute)
    ) || isSurveyRoute(route)
  );
};

export const isSurveyRoute = (route: string): boolean => {
  return Object.values(SurveyRoutes).some((surveyRoute) =>
    route.startsWith(surveyRoute)
  );
};
