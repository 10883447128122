import LanguageIcon from "@mui/icons-material/Language";
import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import classnames from "classnames";
import { TrainingService } from "../../api/TrainingService";
import useTranslation from "../../hooks/useTranslation";
import TrainingModule from "../../models/trainingModule";
import { TouchpointCard } from "../TouchpointCard/TouchpointCard";
import { Button } from "../ui/Button/Button";
import { Chip } from "../ui/Chip/Chip";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./TrainingModuleOverview.scss";

interface TrainingModuleOverviewProps {
  trainingModule: TrainingModule;
}

export const TrainingModuleOverview = ({
  trainingModule,
}: TrainingModuleOverviewProps) => {
  const { i18n, t } = useTranslation("trainingPage");
  const trainingModuleId = trainingModule.referenceId;
  const trainingService = new TrainingService();

  const { error: downloadError, refetch: refetchTrainingModule } = useQuery<
    Blob,
    AxiosError
  >(
    ["trainingDownload"],
    () => trainingService.downloadTrainingModule(trainingModuleId),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    }
  );

  const capitalClasses = classnames("TrainingModuleOverview__content__title", {
    "TrainingModuleOverview__content__title--capitalize":
      i18n.language !== "is-IS",
  });

  return (
    <div className="TrainingModuleOverview">
      <div className="TrainingModuleOverview__thumbnail">
        <img
          className="TrainingModuleOverview__thumbnail__img"
          src={trainingModule.thumbnail}
          alt="training module thumbnail"
        />
      </div>

      <div className="TrainingModuleOverview__content">
        <Grid container>
          <Grid item sm={12} md={12}>
            <Typography tagVariant="h1" desktop="h1" className={capitalClasses}>
              {trainingModule.title}
            </Typography>

            <Grid item sm={12} md={12}>
              <Typography desktop="body2" color="secondary">
                {trainingModule.description}
              </Typography>
              <div className="TrainingModuleOverview__content__tags">
                {trainingModule.tags.map((tag) => (
                  <Chip
                    key={tag}
                    size="small"
                    label={tag}
                    variant="outlined"
                    color="secondary"
                  />
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className="TrainingModuleOverview__content__info">
              <Icon type={IconTypes.Audience} color="secondary" />
              <Typography desktop="body2" color="secondary">
                {t(`modules.audience.${trainingModule.audience.toLowerCase()}`)}
              </Typography>
            </div>
            <div className="TrainingModuleOverview__content__info">
              <Icon type={IconTypes.Touchpoint} color="secondary" />
              <Typography desktop="body2" color="secondary">
                {t(`modules.touchpoints.info`, {
                  count: trainingModule.touchPoints.length,
                  duration: trainingModule.duration,
                })}
              </Typography>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className="TrainingModuleOverview__content__languages">
              <LanguageIcon className="TrainingModuleOverview__content__languages__icon" />
              <div className="TrainingModuleOverview__content__languages__list">
                {trainingModule.languages.map((language, index) => (
                  <Typography
                    desktop="body2"
                    color="secondary"
                    className="TrainingModuleOverview__content__languages__text"
                  >
                    {index !== 0 && "  • "}
                    {language}
                  </Typography>
                ))}
              </div>
            </div>
          </Grid>

          {trainingModule.touchPoints.map((touchpoint) => (
            <Grid item sm={12} key={touchpoint.order}>
              <TouchpointCard
                touchpoint={touchpoint}
                order={touchpoint.order}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="TrainingModuleOverview__footer">
        <div className="TrainingModuleOverview__footer__actions">
          <div>
            {trainingModule.toolbox && (
              <a
                href={trainingModule.toolbox}
                className="TrainingModuleOverview__footer__actions__link"
              >
                <Button
                  className="TrainingModuleOverview__footer__actions__download"
                  variant="contained"
                  color="secondary"
                  small={true}
                >
                  <Icon type={IconTypes.Download} color="secondary" />
                  {t("modules.downloadToolbox")}
                </Button>
              </a>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              className="TrainingModuleOverview__footer__actions__download"
              onClick={() => refetchTrainingModule()}
              variant="contained"
              color="info"
              small={true}
            >
              <Icon type={IconTypes.Download} color="secondary" />
              {t("modules.downloadAll")}
            </Button>
            {!!downloadError && (
              <Typography color="error" desktop="caption">
                {t("modules.downloadError")}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
