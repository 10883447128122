export const Microsoft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46">
      <path fill="#f25022" d="M13 11h9v9h-9z" />
      <path fill="#00a4ef" d="M13 21h9v9h-9z" />
      <path fill="#7fba00" d="M23 11h9v9h-9z" />
      <path fill="#ffb900" d="M23 21h9v9h-9z" />
    </svg>
  );
};
