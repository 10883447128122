import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { DiversityIllustration } from "../../styles/illustrations/DiversityIllustration";
import { GetDiversityRatioResponse } from "../../types/diversityRatio";
import {
  DiversityRatioChartFilter,
  generateDiversityFilters,
} from "../../utils/DiversityRatio.utils";
import { DiversityRatioOverview } from "../DiversityRatioOverview/DiversityRatioOverview";
import { diversityRatioResponse } from "../DiversityRatioOverview/DiversityRatioOverview.data";
import { EmptyState } from "../EmptyState/EmptyState";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";

interface DiversityRatioWidgetProps {
  diversityData?: GetDiversityRatioResponse;
  diversityIsLoading?: boolean;
  diversityError?: boolean;
  noIntegration?: boolean;
  preview?: boolean;
}

export const DiversityRatioWidget = ({
  diversityData,
  diversityIsLoading = false,
  diversityError = false,
  noIntegration = false,
  preview = false,
}: DiversityRatioWidgetProps) => {
  const { t: tDashboard } = useTranslation("dashboardPage");
  const { t: tDiversityRatio } = useTranslation("diversityRatioPage");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const useRealData = !preview && !diversityError && diversityData;

  let diversityFilters: DiversityRatioChartFilter[] | undefined = undefined;

  if (useRealData) {
    diversityFilters = generateDiversityFilters(diversityData);
  } else if (preview) {
    diversityFilters = generateDiversityFilters(diversityRatioResponse);
  }

  return (
    <DashboardWidget
      title={tDashboard("diversityRatioWidget.title")}
      icon={DiversityIllustration}
      href="/diversity-ratio"
      preview={preview}
    >
      {noIntegration ? (
        <EmptyState
          title={tDiversityRatio("noData.title")}
          href="/settings"
          actionInfo={tDiversityRatio("noData.action")}
          isWidget
        />
      ) : (
        <DiversityRatioOverview
          isWidget
          diversityFilters={diversityFilters}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          isLoading={diversityIsLoading}
          error={diversityError && !preview}
        />
      )}
    </DashboardWidget>
  );
};
