import classnames from "classnames";
import { ChangeEvent } from "react";
import "./Input.scss";

export interface ICheckboxProps {
  className?: string;
  checked: boolean;
  label: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const Checkbox = ({
  checked,
  label,
  className,
  value,
  onChange,
}: ICheckboxProps) => {
  const classes = classnames("InputOption", className, {
    "InputOption--selected": checked,
  });

  return (
    <div className={classes}>
      <input
        className="InputOption__input"
        type="checkbox"
        id={value}
        checked={checked}
        value={value}
        onChange={(event) => onChange(event, value)}
      />
      <label className="InputOption__label" htmlFor={value}>
        {label}
      </label>
    </div>
  );
};
