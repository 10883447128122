import { Typography } from "@mui/material";
import { ScaleRailProps } from "../../types/scale";
import { createSegmentPieces } from "../../utils/WorkplaceCulture.utils";
import { Direction, IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./WorkplaceCultureScale.scss";

export const WorkplaceCultureRail = ({
  showLabels = false,
  small = false,
  maxValue = 5,
  minValue = 0,
}: ScaleRailProps) => {
  const segmentPieces = createSegmentPieces(minValue, maxValue, small);

  return (
    <div className="WorkplaceCultureRail">
      {showLabels ? (
        <Icon className="WorkplaceCultureRail__icon" type={IconTypes.Thumb} />
      ) : (
        <div></div>
      )}
      {segmentPieces.map((segmentPiece, i) => {
        const segmentNumber = segmentPiece === 0 ? 1 : Math.ceil(segmentPiece);
        return (
          <div
            className="WorkplaceCultureRail__segment"
            key={`workplaceCulturerail-${segmentNumber}-${i}`}
          >
            <div
              className={`WorkplaceCultureRail__segment__color WorkplaceCultureRail__segment__color--${segmentNumber}`}
              data-testid={`WorkplaceCultureRail__segment__color--${segmentNumber}`}
            ></div>
            {showLabels &&
              i + 1 !== segmentPieces.length &&
              segmentPiece % 1 === 0 && (
                <Typography
                  className="WorkplaceCultureRail__segment__value"
                  color="secondary"
                  variant="h6"
                >
                  {segmentPiece}
                </Typography>
              )}
          </div>
        );
      })}
      {showLabels ? (
        <Icon
          className="WorkplaceCultureRail__icon WorkplaceCultureRail__icon--up"
          type={IconTypes.Thumb}
          direction={Direction.UP}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};
