import {
  DiversityRatioBreakdown,
  DiversityRatioFilter,
  DiversityRatioGroup,
  GetDiversityRatioResponse,
} from "../types/diversityRatio";
import { DataFilter } from "../types/filter";
import { graphColors } from "./graphs";

export interface DiversityRatioChartFilter {
  label: string;
  chartValues: ChartValue[];
}

interface ChartValue {
  label: string;
  count: number;
  ratio: number;
  color: string;
}

export const ageGroupOrder = [
  "<18",
  "18-25",
  "26-35",
  "36-45",
  "46-55",
  "56-65",
  ">65",
  "unknown",
];

export const generateDiversityFilters = (
  data: GetDiversityRatioResponse
): DiversityRatioChartFilter[] => {
  const filters: DiversityRatioChartFilter[] = [];
  data.filters.forEach((filter) => {
    filters.push({
      label: filter.label,
      chartValues: transformDiversityGroupsToChartValue(
        filter,
        data.employee_count
      ),
    });
  });
  return filters;
};

export const generateDiversityBreakdown = (
  selectedFilter: DiversityRatioFilter
): DiversityRatioBreakdown[] | undefined => {
  let groupOrder = selectedFilter.groups.map((group) => group.label);
  if (selectedFilter.label !== "age") {
    groupOrder = selectedFilter.groups
      .sort((groupA, groupB) => groupB.count - groupA.count)
      .map((group) => group.label);
  }

  const sortedBreakdowns = selectedFilter.breakdowns.map((breakdown) => {
    const sortedFilter = breakdown.filters.map((filter) => {
      const sortedGroup = filter.groups.sort((a, b) => {
        const aIndex = groupOrder.indexOf(a.label);
        const bIndex = groupOrder.indexOf(b.label);
        return aIndex - bIndex;
      });

      return { ...filter, groups: sortedGroup };
    });

    return { ...breakdown, filters: sortedFilter };
  });

  return sortedBreakdowns;
};

export const transformDiversityGroupsToChartValue = (
  diversityRatio: DataFilter<DiversityRatioGroup>,
  totalEmployees: number
) => {
  const colors = getDiversityRatioGroupColor(diversityRatio);

  const ratios = diversityRatio.groups.map((group, i) => {
    return {
      label: group.label,
      ratio: Math.round((100 * group.count) / totalEmployees),
      count: group.count,
      color: colors[group.label],
    };
  });

  const sumOfRatios =
    ratios?.reduce((sum, element) => sum + element.ratio, 0) || 0;

  let sortedRatios = (ratios || []).sort((a, b) => b.ratio - a.ratio);

  if (sumOfRatios && sumOfRatios < 100) {
    // add 1 to smallest ratio only if it is not 0
    sortedRatios[
      sortedRatios?.filter((group) => !!group.ratio).length - 1
    ].ratio += 100 - sumOfRatios;
  } else if (sumOfRatios > 100) {
    sortedRatios[0].ratio -= sumOfRatios - 100;
  }

  // sort the age groups in ascending order
  if (diversityRatio.label === "age") {
    sortedRatios = (ratios || []).sort((a, b) => {
      const aIndex = ageGroupOrder.indexOf(a.label);
      const bIndex = ageGroupOrder.indexOf(b.label);
      return aIndex - bIndex;
    });
  }

  const chartValues = sortedRatios.map((value, i) => ({
    label: value.label,
    count: value.count,
    ratio: value.ratio,
    color: value.color,
  }));

  return chartValues;
};

export const getDiversityRatioGroupColor = (
  diversityRatio: DataFilter<DiversityRatioGroup>
) => {
  let sortedGroups = [...diversityRatio.groups];

  if (diversityRatio.label === "age") {
    sortedGroups = diversityRatio.groups.sort((a, b) => {
      const aIndex = ageGroupOrder.indexOf(a.label);
      const bIndex = ageGroupOrder.indexOf(b.label);
      return aIndex - bIndex;
    });
  } else if (diversityRatio.label !== "age") {
    sortedGroups =
      diversityRatio.groups.sort((a, b) => b.count - a.count) || [];
  }

  return sortedGroups.reduce((acc: { [key: string]: string }, group, index) => {
    acc[group.label] = graphColors[index];
    return acc;
  }, {});
};
