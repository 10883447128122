import { Box, FormControl, MenuItem, SelectChangeEvent } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { UserSettingsService } from "../../api/UserSettingsService";
import { useLanguages } from "../../hooks/useLanguages";
import useTranslation from "../../hooks/useTranslation";
import { AVAILABLE_LANGUAGES, AvailableLanguages } from "../../types/locale";
import i18n from "../../utils/i18n";
import { Select } from "../ui/Select/Select";

export interface LanguagePickerProps {
  className?: string;
  onLoginPage?: boolean;
}

// TODO: remove onLoginPage hack
export const LanguagePicker = ({
  className,
  onLoginPage = false,
}: LanguagePickerProps) => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguages();
  const userSettingsService = new UserSettingsService();

  const updateLanguageMutation = useMutation({
    mutationFn: (language: AvailableLanguages) => {
      return userSettingsService.updateLanguage({
        language: language,
      });
    },
    onSuccess: (_data, variables) => {
      changeLanguage(variables);
      // changing a language is not a frequent action, so we do a full
      // page reload to make sure all translations are updated (including those
      // that come from the API).
      window.location.reload();
    },
  });
  const handleLanguageChange = (e: SelectChangeEvent<unknown>) => {
    const lang = e.target.value as AvailableLanguages;
    if (onLoginPage) {
      // TODO: remove this, on login page there is no user hence no preferred
      // language available from the API so we only change the language on the
      // client side.
      i18n.changeLanguage(lang);
    } else {
      updateLanguageMutation.mutate(lang);
    }
  };

  return process.env.REACT_APP_FEATURE_LANGUGAGE_PICKER ? (
    <Box>
      <FormControl size="small">
        <Select
          labelId="language-selector"
          id="language-selector"
          value={i18n.language?.toLowerCase()}
          onChange={handleLanguageChange}
          className={className}
        >
          {AVAILABLE_LANGUAGES.map((lang) => (
            <MenuItem value={lang} key={lang}>
              {t(`navigation.languages.${lang}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  ) : (
    <span />
  );
};
