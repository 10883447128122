import classnames from "classnames";
import MultiSelectQuestion from "../../models/multiSelectQuestion";
import ScaleQuestion from "../../models/scaleQuestion";
import SingleSelectQuestion from "../../models/singleSelectQuestion";
import SurveyChapter from "../../models/surveyChapter";
import { SurveyAnswer } from "../../types/survey";
import getChapterIllustration from "../../utils/getChapterIllustration";
import { Question } from "../Question/Question";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import "./SurveySegment.scss";

export interface SurveySegmentProps {
  className?: string;
  chapter: SurveyChapter | undefined;
  question:
    | ScaleQuestion
    | SingleSelectQuestion
    | MultiSelectQuestion
    | undefined;
  onAnswerChange: (
    question: ScaleQuestion | SingleSelectQuestion | MultiSelectQuestion,
    answerId: string
  ) => void;
  surveyAnswers: SurveyAnswer[];
  isPreview?: boolean;
}

export const SurveySegment = ({
  chapter,
  question,
  className,
  onAnswerChange,
  surveyAnswers,
  isPreview = false,
}: SurveySegmentProps) => {
  const classes = classnames("SurveySegment", className);

  const renderChapter = () => {
    return (
      <>
        <div className="SurveySegment__illustration">
          <img
            src={getChapterIllustration(`${chapter?.referenceId}`)}
            alt={`${chapter?.title}-illustration`}
            className="SurveySegment__illustration__img"
          />
        </div>

        {!chapter?.triggerWarning && (
          <Typography
            className="SurveySegment__title"
            tagVariant="h2"
            desktop="h2"
            mobile="h2"
            weight="bold"
          >
            {chapter?.title}
          </Typography>
        )}
        {chapter?.triggerWarning && (
          <Card className="SurveySegment__card">
            <Typography
              className="SurveySegment__card__title"
              tagVariant="h2"
              desktop="h2"
              mobile="h2"
              weight="bold"
            >
              {chapter?.title}
            </Typography>
            <Typography
              className="SurveySegment__card__text"
              tagVariant="h2"
              desktop="h3"
              mobile="h3"
              weight="regular"
            >
              {chapter?.triggerWarning}
            </Typography>
          </Card>
        )}
        {chapter?.description && (
          <Typography
            className="SurveySegment__card__text"
            tagVariant="h2"
            desktop="h3"
            mobile="h3"
            weight="regular"
          >
            {chapter?.description}
          </Typography>
        )}
      </>
    );
  };

  return (
    <div className={classes}>
      {chapter && renderChapter()}
      {question && (
        <Question
          question={question}
          onAnswerChange={onAnswerChange}
          surveyAnswers={surveyAnswers}
        />
      )}
    </div>
  );
};
