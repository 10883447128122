import { QuestionType } from "../../types/question";
import {
  SurveyQuestionAnswer,
  SurveyQuestionAnswerFilter,
  SurveyQuestionAnswerGroup,
  SurveyQuestionResponseBreakdown,
} from "../../types/workplaceCulture";
import { graphColors } from "../../utils/graphs";

const twoColors = [graphColors[1], graphColors[3]];

export const getQuestionResponseChartData = (
  isSingleDataPoint: boolean,
  totalEngagement: number,
  showLegend: boolean,
  questionResponse?: SurveyQuestionResponseBreakdown,
  questionResponseGroup?: SurveyQuestionAnswerGroup,
  chartTitle?: string | undefined
): Highcharts.Options => {
  const series: Highcharts.SeriesBarOptions[] | undefined =
    questionResponseGroup
      ? transformQuestionResponseToChartValue(
          questionResponse,
          questionResponseGroup
        )
      : transformQuestionResponseToChartValue(questionResponse);
  let chartHeight = 160;
  let titleY = 75;
  if ((series?.length ?? 0) > 4) {
    chartHeight = 180;
    titleY = 100;
  }
  const options: Highcharts.Options = {
    title: {
      text: questionResponseGroup ? questionResponseGroup.label : chartTitle,
      align: "left",
      style: {
        fontSize: "14px",
        fontFamily: "inherit",
        fontWeight: "bold",
      },
      margin: 0,
      y: showLegend ? titleY : 10,
    },
    chart: {
      type: "bar",
      height: showLegend ? chartHeight : 90,
      spacingBottom: showLegend ? -15 : 15,
    },
    xAxis: {
      categories: [""],
      lineWidth: 0,
    },
    yAxis: {
      min: 0,
      visible: false,
      reversedStacks: false,
      height: "20px",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "percent",
      },
      bar: {
        pointWidth: 20,
        dataLabels: {
          enabled: true,
          align: "right",
          format: isSingleDataPoint
            ? formatting(series, questionResponseGroup, totalEngagement)
            : "{point.percentage:.0f}%",
          style: {
            textOutline: "0px",
            fontWeight: "400",
          },
        },
      },
    },
    series: series,
  };

  if (showLegend) {
    options.legend = {
      enabled: true,
      reversed: false,
      align: "left",
      layout: "horizontal",
      verticalAlign: "top",
      symbolRadius: 0,
      padding: 0,
      itemMarginBottom: 10,
      alignColumns: false,
      maxHeight: 55,
    };
  }
  return options;
};

export const formatting = (
  series: Highcharts.SeriesBarOptions[] | undefined,
  questionResponseGroup: SurveyQuestionAnswerGroup | undefined,
  total_engagement: number
): string => {
  let numberofAnswers = total_engagement;
  if (questionResponseGroup) {
    numberofAnswers = questionResponseGroup.engagement;
  }
  const firstdatapoint = series ? series[0] : undefined;
  if (firstdatapoint?.data && numberofAnswers !== 0) {
    const count: number = Number(firstdatapoint?.data[0]);
    return ((count / numberofAnswers) * 100).toFixed(0) + "%";
  }
  return "0%";
};

export const transformQuestionResponseToChartValue = (
  questionResponse?: SurveyQuestionResponseBreakdown,
  questionResponseGroup?: SurveyQuestionAnswerGroup
): Highcharts.SeriesBarOptions[] | undefined => {
  let chartColors = graphColors;
  let options: SurveyQuestionAnswer[] | undefined;
  if (questionResponse) {
    for (const filter of questionResponse?.filters) {
      if (filter.groups?.length !== 0 && !filter.is_custom) {
        options = filter.groups?.[0].options;
        break;
      }
    }
  }

  if (questionResponseGroup) {
    options = questionResponseGroup.options;
  }

  if (options) {
    if (options?.length === 2) {
      chartColors = twoColors;
    }
  }
  return options?.map((answer, i) => {
    return {
      type: "bar",
      name: answer.text,
      data: [
        questionResponseGroup
          ? answer.count
          : getAnswerTotalCount(questionResponse, answer.reference_id),
      ],
      color: chartColors[i],
    };
  });
};

export const getGroupedResponses = (
  chapterQuestionResponses: SurveyQuestionResponseBreakdown[]
): {
  [key: string]: SurveyQuestionResponseBreakdown[];
} => {
  return chapterQuestionResponses.reduce(
    (
      acc: { [key: string]: SurveyQuestionResponseBreakdown[] },
      questionResponse
    ) => {
      const isMultiSelectQuestion =
        questionResponse.kind === QuestionType.MultiSelect;
      const key = isMultiSelectQuestion
        ? questionResponse.text
        : questionResponse.preamble || "";

      if (!acc[key]) {
        acc[key] = [];
      }

      if (isMultiSelectQuestion) {
        // We want to show each option of a MultiSelectQuestion as a separate question
        // So we map the answers to a question format so we can display them in the tabs
        let options: SurveyQuestionAnswer[] | undefined;
        for (const filter of questionResponse.filters) {
          if (filter.groups?.length !== 0) {
            options = filter.groups?.[0].options;
            break;
          }
        }

        const multiAnswerQuestions:
          | SurveyQuestionResponseBreakdown[]
          | undefined = options?.map((answer) => {
          const getOptionFilters = (
            filters: SurveyQuestionAnswerFilter[],
            answerReferenceId: string
          ): SurveyQuestionAnswerFilter[] => {
            return filters.map((filter) => {
              const newGroups = filter.groups?.map((group) => {
                const newOptions = group.options.filter(
                  (option) => option.reference_id === answerReferenceId
                );

                return {
                  label: group.label,
                  engagement: group.engagement,
                  options: newOptions,
                };
              });
              return {
                label: filter.label,
                groups: newGroups,
                is_custom: filter.is_custom,
              };
            });
          };

          const answerMappedToQuestion: SurveyQuestionResponseBreakdown = {
            text: answer.text,
            reference_id: answer.reference_id,
            kind: questionResponse.kind,
            filters: getOptionFilters(
              questionResponse.filters,
              answer.reference_id
            ),
          };

          return answerMappedToQuestion;
        });

        multiAnswerQuestions?.forEach((question) => {
          if (
            question.reference_id !== "OPT.0064" &&
            question.reference_id !== "OPT.0000"
          ) {
            multiAnswerQuestions.forEach((ques) => {
              if (
                ques.reference_id === "OPT.0064" ||
                ques.reference_id === "OPT.0000"
              ) {
                question.filters.forEach((filter, i) =>
                  filter.groups?.forEach((group, j) => {
                    const option = ques.filters[i].groups?.[j].options[0];
                    option && group.options.push(option);
                  })
                );
              }
            });
            acc[key].push(question);
          }
        });
      } else {
        acc[key].push(questionResponse);
      }

      return acc;
    },
    {}
  );
};

export const doesQuestionHaveAnswers = (
  questionResponse?: SurveyQuestionResponseBreakdown
) => {
  if (questionResponse) {
    for (const filter of questionResponse.filters) {
      if (Array.isArray(filter.groups)) {
        for (const group of filter.groups) {
          if (group.engagement > 0) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const updateFilters = (selectedFilters: number[], index: number) => {
  if (selectedFilters.includes(index)) {
    return [...selectedFilters.filter((filterIndex) => filterIndex !== index)];
  } else {
    return [...selectedFilters, index].sort();
  }
};

export const getAnswerTotalCount = (
  questionResponse?: SurveyQuestionResponseBreakdown,
  answerId?: string
) => {
  let totalCount = 0;

  if (questionResponse) {
    for (const filter of questionResponse.filters) {
      if (!filter.groups || filter.groups.length === 0 || filter.is_custom) {
        continue;
      }

      for (const group of filter.groups) {
        for (const option of group.options) {
          if (option.reference_id === answerId) {
            totalCount += option.count;
          }
        }
      }
      break;
    }
  }
  return totalCount;
};

export const showNoData = (
  group: SurveyQuestionAnswerGroup,
  kind: QuestionType,
  chartData: Highcharts.Options
) => {
  const options = chartData.series?.[0];
  let answers;
  if (options !== undefined) {
    // If the chart has data, get the first answer
    answers = (options as Highcharts.SeriesBarOptions).data?.[0];
  }
  if (group.options.length === 0) {
    // If the group has no options show no data
    return true;
  } else if (group.options.length !== 0) {
    if (kind === QuestionType.MultiSelect && (answers ?? 0) === 0) {
      // If the question is a multi select and the answer count is 0 show no data
      return true;
    }
  }
  return false;
};
