import useTranslation from "../../hooks/useTranslation";
import { BenchmarkingIllustration } from "../../styles/illustrations/BenchmarkingIllustration";
import { IBenchmarking } from "../../types/workplaceCulture";
import { Benchmarking } from "../Benchmarking/Benchmarking";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";

interface BenchmarkingWidgetProps {
  benchmarking?: IBenchmarking;
  benchmarkingError?: boolean;
  benchmarkingLoading?: boolean;
  inclusionScore?: number;
  scoreError?: boolean;
  scoreLoading?: boolean;
}
export const BenchmarkingWidget = ({
  benchmarking,
  benchmarkingError = false,
  benchmarkingLoading = true,
  inclusionScore,
  scoreError,
  scoreLoading = true,
}: BenchmarkingWidgetProps) => {
  const { t } = useTranslation("dashboardPage");
  return (
    <DashboardWidget
      title={t("benchmarkingWidget.title")}
      icon={BenchmarkingIllustration}
      className="BenchmarkingWidget"
    >
      <Benchmarking
        benchmarking={benchmarking}
        inclusionScore={inclusionScore}
        scoreError={scoreError}
        scoreLoading={scoreLoading}
        benchmarkingLoading={benchmarkingLoading}
        benchmarkingError={benchmarkingError}
      />
    </DashboardWidget>
  );
};
