import MUIModal from "@mui/material/Modal";
import classNames from "classnames";
import { ReactNode } from "react";
import { useLanguages } from "../../../hooks/useLanguages";
import { Button } from "../Button/Button";
import { IconTypes } from "../icons";
import { Icon } from "../icons/Icon";
import "./Modal.scss";

export interface IModalProps {
  containerClassName?: string;
  className?: string;
  open: boolean;
  children: ReactNode;
  onClose: (
    event: {},
    reason?: "backdropClick" | "escapeKeyDown"
  ) => void | undefined;
  backgroundImage?: string;
  light?: boolean;
  title?: ReactNode;
}

export const Modal = ({
  open,
  onClose,
  backgroundImage,
  light,
  title,
  children,
  containerClassName,
  className,
}: IModalProps) => {
  const { isPrimaryFontSupported } = useLanguages();

  const classes = classNames("Modal", className, {
    "Modal--transparent": backgroundImage,
  });

  const containerClasses = classNames("Modal__container", {
    "Modal__container--light": light,
    "Modal__container--backupFont": !isPrimaryFontSupported(),
  });

  const containerBodyClasses = classNames(
    "Modal__container__body",
    containerClassName
  );

  return (
    <MUIModal
      className={classes}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-alda"
      aria-describedby="modal-alda-info"
    >
      <div>
        {backgroundImage && (
          <img className="Modal__background" src={backgroundImage} alt="" />
        )}
        <div className={containerClasses}>
          {light ? (
            <Button
              className="Modal__container__action"
              color="secondary"
              icon
              onClick={onClose}
            >
              <Icon type={IconTypes.Close} />
            </Button>
          ) : (
            <div className="Modal__container__header">
              <div className="Modal__container__header__title">{title}</div>
              <Button
                color="secondary"
                variant="outlined"
                icon
                onClick={onClose}
              >
                <Icon type={IconTypes.Close} />
              </Button>
            </div>
          )}
          <div className={containerBodyClasses}>{children}</div>
        </div>
      </div>
    </MUIModal>
  );
};
