import { Skeleton } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ActionPlanService } from "../../api/ActionPlanService";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { EventsResponse, SurveyType } from "../../types/actionPlan";
import {
  TimelineValue,
  getTimelineValues,
  isSurveyMeta,
  updateEventDate,
  updateScores,
} from "../../utils/ActionPlan.utils";
import { NoDataState } from "../NoDataState/NoDataState";
import { Modal } from "../ui/Modal/Modal";
import { Scale } from "../ui/Scale/Scale";
import { Snackbar } from "../ui/Snackbar/Snackbar";
import { ActionPlanRail } from "./ActionPlanRail";
import { TimelineEventInfo } from "./TimelineEventInfo";
import "./ActionPlanTimeline.scss";

interface ActionPlanTimelineProps {
  className?: string;
  surveyDispatch?: SurveyDispatch;
  eventsData?: EventsResponse[];
  error?: boolean;
  isLoading?: boolean;
  onActionPlanUpdate?: () => void;
}
export const ActionPlanTimeline = ({
  className,
  surveyDispatch,
  eventsData,
  error,
  isLoading,
  onActionPlanUpdate,
}: ActionPlanTimelineProps) => {
  const { t, i18n } = useTranslation("dashboardPage");
  const [values, setValues] = useState<TimelineValue[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<TimelineValue>();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const actionPlanService = new ActionPlanService();

  useEffect(() => {
    if (eventsData) {
      const timelineValues = getTimelineValues(eventsData, t, i18n.language);

      setValues(timelineValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData]);

  const getDispatchDate = (type: SurveyType, id: number | null) => {
    if (surveyDispatch) {
      switch (type) {
        case SurveyType.Launch:
          return surveyDispatch.launchDate;
        case SurveyType.DueDate:
          return surveyDispatch.dueDate;
        case SurveyType.Reminder:
          return surveyDispatch.getReminder(id)?.date;
        default:
          return null;
      }
    }
  };

  const onSurveyUpdate = () => {
    if (surveyDispatch) {
      const newValues = values.map((value) => {
        const dispatchDate =
          isSurveyMeta(value.meta) &&
          getDispatchDate(value.meta.type, value.meta.related_id);
        if (dispatchDate) {
          return updateEventDate(value, dispatchDate, t, i18n.language);
        }

        return value;
      });

      setValues(updateScores(newValues));
      setModalOpen(false);
      setSnackBarOpen(true);
      onActionPlanUpdate && onActionPlanUpdate();
    }
  };

  const renderLoading = () => (
    <Skeleton variant="rounded" width={"100%"} height={100} />
  );

  const renderNoData = () => (
    <NoDataState
      title={t("actionPlan.error.title")}
      info={t("actionPlan.error.info")}
    />
  );
  const updateTimelineMutation = useMutation({
    mutationFn: (events: TimelineValue[]) =>
      actionPlanService.updateActionPlan(events),
  });
  const handleClick = (value: TimelineValue) => {
    setModalOpen(true);
    setSelectedEvent(value);
  };

  const handleChangeDate = (value: TimelineValue, newDate: Date) => {
    const newValues = values.map((val) => {
      if (val.label === value.label) {
        val = updateEventDate(val, newDate, t, i18n.language);
      }
      return val;
    });
    setValues(updateScores(newValues));
  };

  const handleSave = async () => {
    values &&
      (await updateTimelineMutation.mutateAsync(values, {
        onSuccess: () => {
          setErrorMsg("");
          setModalOpen(false);
          setSnackBarOpen(true);
        },
        onError: () => {
          setErrorMsg(t("actionPlan.timeline.errors.save") || "");
          setSnackBarOpen(true);
        },
      }));
  };
  return (
    <div className="ActionPlanTimeline">
      {isLoading && renderLoading()}
      {error && renderNoData()}
      {!error && !isLoading && (
        <Scale<TimelineValue>
          className={className}
          RailComponent={() => <ActionPlanRail />}
          values={values}
          min={-1}
          max={values.length}
          onClick={(value: TimelineValue) => handleClick(value)}
        />
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        light
        className="ActionPlanTimeline__modal"
      >
        <div>
          <TimelineEventInfo
            event={selectedEvent}
            handleChangeDate={handleChangeDate}
            handleSaveEvents={handleSave}
            surveyDispatch={surveyDispatch}
            onSurveyUpdate={onSurveyUpdate}
          />
        </div>
      </Modal>

      <Snackbar
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
        message={errorMsg ? errorMsg : t("actionPlan.timeline.submitSuccess")}
        autoHideDuration={null}
      />
    </div>
  );
};
