import { AxiosInstance } from "axios";
import {
  AddH3IntegrationRequest,
  AddKjarniIntegrationRequest,
  IntegrationResponse,
} from "../types/integration";
import { getApiClient } from "../utils/api.utils";

export class IntegrationService {
  client: AxiosInstance;

  constructor() {
    this.client = getApiClient();
  }

  getLinkToken = async () => {
    // linkToken is a temporary token to initialize end user's link session
    const response = await this.client.get<{ link_token: string }>(
      "/hr/integrations/merge/link-token/"
    );
    return response.data;
  };

  getIntegration = async () => {
    const response = await this.client.get<IntegrationResponse>(
      "/hr/integrations/detail/"
    );
    return response.data;
  };

  addMergeIntegration = async (publicToken: string) => {
    // publicToken is temporary token received from merge after end user finishes link session
    // will be used to retrieve a permanent accountToken
    const response = await this.client.post(`/hr/integrations/merge/setup/`, {
      public_token: publicToken,
    });

    return response.data;
  };

  getSyncDate = async () => {
    const response = await this.client.get<{ sync_date: string }>(
      "/hr/integrations/sync-date/"
    );
    return response.data;
  };

  addKjarniIntegration = async (data: AddKjarniIntegrationRequest) => {
    const response = await this.client.post(`/hr/integrations/kjarni/setup/`, {
      domain: data.domain,
      username: data.username,
      password: data.password,
    });
    return response.data;
  };

  addH3Integration = async (data: AddH3IntegrationRequest) => {
    const response = await this.client.post(`/hr/integrations/h3/setup/`, {
      company: data.company,
      username: data.username,
      password: data.password,
      hostname: data.hostname,
    });
    return response.data;
  };
}
