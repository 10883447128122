import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { DiversityRatioBreakdown as IDiversityRatioBreakdown } from "../../types/diversityRatio";
import { Paper } from "../ui/Paper/Paper";
import { Tab } from "../ui/Tabs/Tab";
import { TabPanel } from "../ui/Tabs/TabPanel";
import { Tabs } from "../ui/Tabs/Tabs";
import { Typography } from "../ui/Typography/Typography";
import { DiversityRatioBreakdownComparison } from "./DiversityRatioBreakdownComparison";
import "./DiversityRatioBreakdown.scss";

interface DiversityRatioProps {
  diversityBreakdown: IDiversityRatioBreakdown[];
  diversityBreakdownGroupColors?: { [key: string]: string };
  selectedTabLabel: string;
}

export const DiversityRatioBreakdown = ({
  diversityBreakdown,
  diversityBreakdownGroupColors,
  selectedTabLabel,
}: DiversityRatioProps) => {
  const { t } = useTranslation("diversityRatioPage");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChangeTab = (newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Paper className="DiversityRatioBreakdown">
      <Typography tagVariant="h2" desktop="h2">
        {t("breakdown.title", {
          label: t(`chart.filters.${selectedTabLabel}.title`),
        })}
      </Typography>
      <Typography tagVariant="p" desktop="caption">
        {t("breakdown.description", { filter: selectedTabLabel })}
      </Typography>
      <Tabs
        selectedTabIndex={selectedTabIndex}
        label="Diversity-ratio-breakdown"
        className="DiversityRatioBreakdown__filters"
        centered={true}
        variant="standard"
      >
        {diversityBreakdown.map((breakdown, i) => {
          return (
            <Tab
              key={breakdown.label}
              selected={selectedTabIndex === i}
              label={t(`breakdown.${breakdown.label}.tab`)}
              index={i}
              onClick={handleChangeTab}
            />
          );
        })}
      </Tabs>
      {diversityBreakdown.map((breakdown, i) => (
        <TabPanel
          selectedTabIndex={selectedTabIndex}
          index={i}
          key={`diversityBreakdownFilters-${i}`}
          label="diversity-breakdown-tab"
          alwaysRenderContent
        >
          <DiversityRatioBreakdownComparison
            breakdownFilters={breakdown.filters}
            breakdownLabel={breakdown.label}
            diversityBreakdownGroupColors={diversityBreakdownGroupColors}
          />
        </TabPanel>
      ))}
    </Paper>
  );
};
