import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { isEmail, isInList } from "../../utils/validation";
import { Button } from "../ui/Button/Button";
import { Input } from "../ui/Input/Input";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyEmailInput.scss";

export interface SurveyEmailInputProps {
  emailList: string[];
  setEmailList: (emails: string[]) => void;
  handleAddEmails: (emails: string[]) => void;
  setError: (error: string) => void;
  submitSuccess: string;
  setSubmitSuccess: (error: string) => void;
}
export const SurveyEmailInput = ({
  emailList,
  setEmailList,
  handleAddEmails,
  setError,
  submitSuccess,
  setSubmitSuccess,
}: SurveyEmailInputProps) => {
  const { t } = useTranslation("launchSurveyPage");
  const [inputValue, setInputValue] = useState<string>("");
  const [info, setInfo] = useState<string>("");

  const isValidEmail = (email: string, list: string[]) => {
    if (isInList(email, list)) {
      setError(
        t("shareLink.errors.alreadyInList", {
          value: email,
        }) || ""
      );
      return false;
    }

    if (!isEmail(email)) {
      setError(
        t("shareLink.errors.notValidEmail", {
          value: email,
        }) || ""
      );
      return false;
    }

    return true;
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(ev.target.value);
    setError("");
    setInfo("");
  };

  const handlePasteAndValidateEmails = (ev: {
    preventDefault: () => void;
    clipboardData: { getData: (arg0: string) => any };
  }) => {
    ev.preventDefault();

    const paste = ev.clipboardData.getData("text");
    // eslint-disable-next-line no-useless-escape
    const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    emails && handleAddEmails(emails);
  };

  const handleKeyDown = (ev: { key: string; preventDefault: () => void }) => {
    if (["Enter", "Tab", ","].includes(ev.key)) {
      ev.preventDefault();

      handleAdd();
    }
  };

  const handleAdd = () => {
    const value = inputValue.trim();

    if (value) {
      if (isValidEmail(value, emailList)) {
        setInputValue("");
        setEmailList([...emailList, value]);
        setInfo(t("shareLink.added", { value: value }) || "");
        setSubmitSuccess("");
      }
    }
  };

  return (
    <div className="SurveyEmailInput">
      <div className="SurveyEmailInput__inputWrapper">
        <Input
          value={inputValue}
          onChange={handleChange}
          onPaste={handlePasteAndValidateEmails}
          onKeyDown={handleKeyDown}
        />
        <Button variant="contained" color="secondary" small onClick={handleAdd}>
          {t("shareLink.add")}
        </Button>
      </div>
      {!submitSuccess && info && (
        <Typography desktop="caption">{info}</Typography>
      )}
    </div>
  );
};
