import { Skeleton, Typography } from "@mui/material";
import classnames from "classnames";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import TrainingModule from "../../models/trainingModule";
import { TrainingAudience } from "../../types/training";
import { TrainingModuleOverview } from "../TrainingModuleOverview/TrainingModuleOverview";
import { Card } from "../ui/Card/Card";
import { Modal } from "../ui/Modal/Modal";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./TrainingModuleCard.scss";

interface TrainingModuleProps {
  trainingModule: TrainingModule;
  isLoading?: boolean;
  isActive?: boolean;
}

export const TrainingModuleCard = ({
  trainingModule,
  isLoading = false,
  isActive = false,
}: TrainingModuleProps) => {
  const { i18n, t } = useTranslation("trainingPage");
  const [isModuleOpen, setIsModuleOpen] = useState(false);

  const buttonClasses = classnames(
    "TrainingModuleCard__innerCard__content__button",
    {
      "TrainingModuleCard__innerCard__content__button--disabled": !isActive,
    }
  );

  const cardClasses = classnames("TrainingModuleCard__innerCard", {
    "TrainingModuleCard__innerCard--disabled": !isActive,
  });

  const capitalClasses = classnames(
    "TrainingModuleCard__innerCard__content__title",
    {
      "TrainingModuleCard__innerCard__content__title--capitalize":
        i18n.language !== "is-IS",
    }
  );

  const renderLoading = () => {
    return (
      <>
        <div className="TrainingModuleCard__innerCard__header">
          <div className="TrainingModuleCard__innerCard__header__pill"></div>
        </div>
        <div className="TrainingModuleCard__innerCard__content">
          <Skeleton
            width={"100%"}
            height={"100%"}
            style={{ borderRadius: "16px" }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="TrainingModuleCard">
      <Card containerClassName={cardClasses}>
        {!isLoading && (
          <>
            <div className="TrainingModuleCard__innerCard__header">
              <img
                className="TrainingModuleCard__innerCard__header__img"
                src={trainingModule.thumbnail}
                alt="training module thumbnail"
              />
              <div className="TrainingModuleCard__innerCard__header__pill">
                <Icon
                  type={
                    trainingModule.audience === TrainingAudience.All
                      ? IconTypes.ManyPeople
                      : IconTypes.ChatPerson
                  }
                />
                <Typography>
                  {t(
                    `modules.audience.${trainingModule.audience.toLowerCase()}`
                  )}
                </Typography>
              </div>
            </div>
            <div className="TrainingModuleCard__innerCard__content">
              <div>
                <Typography variant="h3" className={capitalClasses}>
                  {trainingModule.title}
                </Typography>
                <div className="TrainingModuleCard__innerCard__content__tag">
                  <Icon type={IconTypes.GraduationHat} />
                  <Typography>Gender Pronouns</Typography>
                </div>
              </div>
              <Typography className="TrainingModuleCard__innerCard__content__description">
                {trainingModule.description}
              </Typography>

              <button
                className={buttonClasses}
                onClick={() => isActive && setIsModuleOpen(true)}
                disabled={!isActive}
                title={
                  isActive
                    ? `Open course ${trainingModule.title}`
                    : "Coming soon"
                }
              >
                <Icon type={IconTypes.RedArrow} />
              </button>
            </div>
          </>
        )}
        {isLoading && renderLoading()}
      </Card>

      <Modal
        open={isModuleOpen}
        onClose={() => setIsModuleOpen(false)}
        light
        className="TrainingModuleCard__modal"
      >
        <TrainingModuleOverview trainingModule={trainingModule} />
      </Modal>
    </div>
  );
};
